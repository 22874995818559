import {connect} from 'react-redux';
import { postDisputeTransactions, resetDisputeTransactions } from '../../redux/actions/cardsActions';

import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';

import ReactNumberFormat from 'react-number-format';

import Dialog from '../../components/Dialog';
import TransactionTile from '../../components/TransactionTile/TransactionTile';
import Select from '../../components/Select/Select';

import DialogWindow from '../../components/DialogWindow/DialogWindow';

import './DisputedCharges.css';

function encodeRequest(value) {
  const result = {...value};
  if (result.first || result.last) {
    result.cardholderName = result.first;
    if (result.last) {
      result.cardholderName += " " + result.last;
    }    
    delete result.first;
    delete result.last;    
  }
  if (result.transactions) {
    result.descriptionOfTransactions = [...result.transactions].map((item) => {
      const result = {...item};
      if (result.formCurrency !== 'Other') result.currency = result.formCurrency;
      if (result.formTypeOfDispute !== 'Other') result.typeOfDispute = result.formTypeOfDispute;
      delete result.formCurrency;
      delete result.formTypeOfDispute;
      return result;
    });
    delete result.transactions;
  }
  return result;
}

const currencies = [
  'AED', 'USD', 'EUR', 'CHF', 'GBP', 'Other'
];

const types = [
  'Unrecognised charge', 
  'Double transaction',
  'Exchange rate', 
  'Other'
];

export function DisputedCharges({loading, submitted, onSubmit, onReset}) {
  const [formState, setFormState] = useState({
    transactions: [],
  });
  const [currentTransaction, setCurrentTransaction] = useState({});
  const [addingTransaction, setAddingTransaction] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setCurrentTransaction({});
    setAddingTransaction(true);        
  }, [setAddingTransaction, setCurrentTransaction]);
  const handleChange = useCallback((e) => {
    const {name, value} = e.currentTarget;
    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  }, [setFormState]);
  //
  const handleSaveTransaction = useCallback((e) => {
    e.preventDefault();
    setAddingTransaction(false);
    setCurrentTransaction((tran) => {
      const transaction = {...tran};      
      setFormState((state) => {        
        if (transaction.index === undefined) {
          const result = {
            ...state,
          };
          result.transactions = [...(state.transactions || []), transaction];
          return result;
        }
        else {
          const result = {
            ...state,
          };
          result.transactions = [...(state.transactions || [])]
          result.transactions[tran.index] = transaction;
          return result;
        }
        return state;
      });
      return {value: {}};
    });
  }, [setFormState, setCurrentTransaction, setAddingTransaction]);

  const handleTransactionChange = useCallback((e) => {
    const {name, value} = e.currentTarget;
    setCurrentTransaction((state) => ({
      ...state,          
      [name]: value,      
    }));
  }, [setCurrentTransaction]);
  //

  const handleTransactionEdit = useCallback((index) => {
    setFormState((state) => {
      setCurrentTransaction({
        ...state.transactions[index],
        index,
      });
      setAddingTransaction(true);
      return state;
    });
  }, [setFormState, setAddingTransaction, setCurrentTransaction])
  //
  const handleTransactionDelete= useCallback((index) => {
    setFormState(state => {
      const result = {...state};
      result.transactions = [...result.transactions];
      result.transactions.splice(index, 1);
      return result;
    });
  }, [setFormState]);
  const handleFinalSubmit = useCallback((e) => {
    e.preventDefault();
    onSubmit && setFormState((state) => {
      onSubmit(state);
      return state;
    });    
  }, [onSubmit, setFormState]);

  useEffect(() => {
    if (submitted) setConfirmationVisible(true);
  }, [submitted, setConfirmationVisible]);

  const handleCloseConfirmation = useCallback(() => {
    console.log('close');
    setConfirmationVisible(false);
    setFormState({});
    onReset && onReset();
  }, [onReset, setConfirmationVisible, setFormState]);
  return (    
    <div className='disputed-charges'>
      <div className='disputed-charges__header'>      
        <form className='disputed-charges__form' onSubmit={handleSubmit}>
          <h1 className='disputed-charges__title'>Declaration of Disputed Charges</h1>
          <div className="disputed-charges__controls">
            <label className="disputed-charges__control">
              First Name
              <input className="disputed-charges__input" autoFocus name="first" required value={formState['first'] || ''} onChange={handleChange} />
            </label>
            <label className="disputed-charges__control">
              Last Name
              <input className="disputed-charges__input" name="last" required value={formState['last'] || ''} onChange={handleChange} />
            </label>
            <label className="disputed-charges__control">
              Full 16 digit card number
              <ReactNumberFormat 
                className="disputed-charges__input" 
                name="cardNumber" 
                required 
                value={formState['cardNumber'] || ''} 
                onChange={handleChange} 
                format="#### #### #### ####"
              />
            </label>            
          </div>
          <button type="submit" className="disputed-charges__form-action">
            Add Transaction Description 
          </button>
        </form>      
      </div>
      <Dialog open={addingTransaction} openSetter={setAddingTransaction}>
        <DialogWindow className="disputed-charges__dialog" caption="Add Description" openSetter={setAddingTransaction} color="accent">
          <form onSubmit={handleSaveTransaction}>
            <label className='disputed-charges__control'>
              Date
              <input className="disputed-charges__input" required name="date" value={currentTransaction?.date || ''} onChange={handleTransactionChange} type="date" />
            </label>
            <label className='disputed-charges__control'>
              Amount
              <input className="disputed-charges__input" required name="amount" value={currentTransaction?.amount || ''} onChange={handleTransactionChange} type="number" />
            </label>
            <Select caption="Currency" required name="formCurrency" value={currentTransaction?.formCurrency || ''} onChange={handleTransactionChange}>
              <option value="" disabled>Select currency</option>
              {currencies.map((item, i) => (
                <option value={item} key={i}>{item}</option>
              ))}                            
            </Select>
            {currentTransaction?.formCurrency === 'Other' ? <label className='disputed-charges__control'>
              Enter the currency              
              <input className="disputed-charges__input" required name="currency" value={currentTransaction?.currency || ''} onChange={handleTransactionChange} />
            </label> : null}
            <label className='disputed-charges__control'>
              Merchant
              <input className="disputed-charges__input" required name="merchant" value={currentTransaction?.merchant || ''} onChange={handleTransactionChange} />
            </label>
            <Select caption="Type of Dispute" required name="formTypeOfDispute" value={currentTransaction?.formTypeOfDispute || ''} onChange={handleTransactionChange}>
              <option value="" disabled>Select type of dispute</option>
              {types.map((item, i) => (
                <option value={item} key={i}>{item}</option>
              ))}                            
            </Select>
            {currentTransaction?.formTypeOfDispute === 'Other' ? <label className='disputed-charges__control'>
              Enter the Type of Dispute
              <input className="disputed-charges__input" required name="typeOfDispute" value={currentTransaction?.typeOfDispute || ''} onChange={handleTransactionChange} />
            </label> : null}
            <label className='disputed-charges__control'>
              Nature of Dispute
              <textarea className="disputed-charges__input" required name="natureOfDispute" value={currentTransaction?.natureOfDispute || ''} onChange={handleTransactionChange} />
            </label>
            <button className="disputed-charges__form-action">Save</button>
          </form>
        </DialogWindow>
      </Dialog>
      <div className={classNames('disputed-charges__content', formState.transactions?.length >= 3 ? 'disputed-charges__content_small' : null)}>
        {formState.transactions ? formState.transactions.map((item, i) => (
          <TransactionTile 
            className="disputed-charges__transaction" 
            date={item.date}
            amount={item.amount}
            currency={item.formCurrency === 'Other' ? item.currency : item.formCurrency}
            merchant={item.merchant}
            typeOfDispute={item.formTypeOfDispute === 'Other' ? item.typeOfDispute : item.formTypeOfDispute}
            natureOfDispute={item.natureOfDispute}
            onEdit={() => {handleTransactionEdit(i)}}
            onDelete={() => handleTransactionDelete(i)}
            key={i}
          />
        )) : null}
      </div>
      {formState.transactions && formState.transactions.length > 0 ? <form className="disputed-charges__submit-form" onSubmit={handleFinalSubmit}>
        {/*<label className="disputed-charges__checkbox"><input name="had_before" type="checkbox" />I have had previous dealings with the merchant before.</label>
        <div className="disputed-charges__submit-controls">          
          <label className="disputed-charges__control">
            Neither I, nor anyone authorised by me, entered into the transaction detailed above. However, a debit of 
            <input className="disputed-charges__input" name="debitOf" value={formState['debitOf'] || ''} onChange={handleChange} />
          </label>
          <label className="disputed-charges__control">
            applied to my account on
            <input className="disputed-charges__input" type="date" name="appliedAt" value={formState['appliedAt'] || ''} onChange={handleChange} format="##/##/##" />
          </label>
        </div>
        <label className="disputed-charges__checkbox"><input name="accept" required type="checkbox" />I hereby authorise Insignia Cards Limited and their solicitors to deal with all future corresponence in relation to this matter on my behalf.</label> */}
        <br/><br/><br/> 
        <button type="submit" className="disputed-charges__form-action">Submit</button>
        </form> : null}
      <Dialog open={confirmationVisible} openSetter={setConfirmationVisible}>
        <DialogWindow onClose={handleCloseConfirmation} color="accent">
          <br/>
          Your declaration has been successfully submitted
          <br/>
          <br/>
          <br/>
          <button className="disputed-charges__form-action disputed-charges__form-action_row" onClick={handleCloseConfirmation}>Close</button>
        </DialogWindow>
      </Dialog>
    </div>
  );
};

export default connect(
  (state) => ({
    loading: state.cards.postingDispute,
    submitted: state.cards.disputePosted,
  }),
  (dispatch) => ({
    onSubmit: (value) => dispatch(postDisputeTransactions(encodeRequest(value))),
    onReset: () => dispatch(resetDisputeTransactions()),
  })
)(DisputedCharges);