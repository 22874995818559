import {
  GET_LETTERS,
  POST_LETTERS,
  GET_LATTER_CONTENT,
  GET_LATTER_DOWNLOAD,
  DELETE_LETTER,
  LETTER_TO_ARCHIVE,
  RESET_READED_LETTER,
  GET_LATTER_PRINT,
  GET_LETTERS_COUNT,
  SET_LETTERS_COUNT
} from '../constants'

const initState = {}

export default function AuthReducer(state = initState, action) {
  switch (action.type) {

    case `${GET_LETTERS}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        letters: null
      }
    }
    case `${GET_LETTERS}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        letters: action.payload.data
      }
    }
    case `${GET_LETTERS}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        letters: null,
        lettersError: action.payload
      }
    }

    case `${POST_LETTERS}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        postSuccess: null,
        postError: null,
      }
    }
    case `${POST_LETTERS}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        postSuccess: true,
        postError: null
      }
    }
    case `${POST_LETTERS}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        postSuccess: null,
        postError: action.payload?.data || true
      }
    }

    case `${GET_LATTER_CONTENT}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        letterRead: null,
        letterReadError: null
      }
    }
    case `${GET_LATTER_CONTENT}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        letterRead: action.payload.data,
      }
    }
    case `${GET_LATTER_CONTENT}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        letterReadError: action.payload?.data
      }
    }

    case `${GET_LATTER_DOWNLOAD}_REQUEST`: {
      return {
        ...state,
        letterDownload: null,
        letterDownloadError: null,
        isFetching: true,
      }
    }
    case `${GET_LATTER_DOWNLOAD}_SUCCESS`: {
      const { data, response } = action.payload
      return {
        ...state,
        letterDownload: data,
        letterDownloadError: response?.data || null,
        isFetching: false,
      }
    }
    case `${GET_LATTER_DOWNLOAD}_FAILED`: {
      const { response } = action.payload
      return {
        ...state,
        letterDownload: null,
        letterDownloadError: response?.data,
        isFetching: false,
      }
    }

    case `${GET_LATTER_PRINT}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        letterPrint: null,
        letterPrintError: null,

      }
    }
    case `${GET_LATTER_PRINT}_SUCCESS`: {
      const { data, response } = action.payload
      return {
        ...state,
        isFetching: false,
        letterPrint: data,
        letterPrintError: response?.data || null,

      }
    }
    case `${GET_LATTER_PRINT}_FAILED`: {
      const { response } = action.payload
      return {
        ...state,
        isFetching: false,
        letterPrint: null,
        letterPrintError: response?.data,

      }
    }

    case `${DELETE_LETTER}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        lettersDeleteSuccess: null
      }
    }
    case `${DELETE_LETTER}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        lettersDeleteSuccess: true
      }
    }
    case `${DELETE_LETTER}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        lettersDeleteSuccess: null
      }
    }

    case `${LETTER_TO_ARCHIVE}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        letterToArchiveSuccess: null
      }
    }
    case `${LETTER_TO_ARCHIVE}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        letterToArchiveSuccess: true
      }
    }
    case `${LETTER_TO_ARCHIVE}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        letterToArchiveSuccess: null
      }
    }

    case `${RESET_READED_LETTER}`: {
      return {
        ...state,
        letterRead: null,
        letterReadError: null,
        letterToArchiveSuccess: null,
        lettersDeleteSuccess: null,
        letterDownload: null,
        letterDownloadError: null,
        letterPrint: null,
        letterPrintError: null,
        postSuccess: null,
        isFetching: false
      }
    }

    case `${GET_LETTERS_COUNT}`: {
      return {
        ...state
      }
    }

    case `${SET_LETTERS_COUNT}`: {
      return {
        ...state,
        lettersCount: action.payload
      }
    }

    default:
      return state
  }
}