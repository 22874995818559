import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import close from '../../assets/images/contact/close.svg';

import './UploadFile.css';
import AttachIcon from '../AttachIcon';

export default function UploadFile({className, value: files, onChange: setFiles}) {
  const refInput = useRef(null);
  const handleChange = useCallback(e => {
    const files = [...(e.currentTarget.files || [])];    
    if (files && files.length) {
      setFiles((current) => [...(current || []), ...files]);
      if (refInput.current) refInput.current.value = '';
    }
  }, [setFiles, refInput]);
  const handleRemove = useCallback(index => {
    setFiles(files => {
      const result = [...(files || [])];
      result.splice(index, 1);
      return result;
    });
  }, [setFiles]);
  return (
    <div className={className}>
      <ul className="upload-file__selection">
        {files ? files.map((item, i) => (
          <li className="upload-file__item" key={i}>
            {item.name}
            <span className="upload-file__remove" onClick={() => handleRemove(i)}>
              <img src={ close } />
            </span>
          </li>
        )) : null}
      </ul>
      <div className="upload-file__control">
        <input className="upload-file__field" type="file" onChange={handleChange} ref={refInput} />
        <AttachIcon />        
      </div>
    </div>
  )
}

UploadFile.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};
