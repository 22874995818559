import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getContentTextCardTCAction, getContentTextSiteTCAction } from '../../redux/actions/contentActions';
import { screenHOC } from '../HOC/screenHOC'
import './info.scss';

const ConditionsPolicy = ({dispatch, cardsTC}) => {
  useEffect(() => {
    dispatch(
      getContentTextCardTCAction()
    )
  })
  return (
    <div className="privacy">
      <div className="privacy-content">
        <div className="privacy-line-title">
          <div className="privacy-line"></div>
          <h2 className="section-title section-title__cards">Cards T&Cs</h2>
        </div>
        <div className="privacy-text" >
          <div dangerouslySetInnerHTML={{__html: cardsTC}} className="privacy-text-content" />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  cardsTC: state.content.cardsTC
})

export default connect(mapStateToProps)(screenHOC(ConditionsPolicy))