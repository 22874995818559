import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';

import {
  GET_CONTENT_FEED,
  GET_CONTENT_TEXT_CONTACT,
  GET_CONTENT_TEXT_TARIFF,
  GET_CONTENT_TEXT_SITETC,
  GET_CONTENT_TEXT_CARDSTC,
  GET_CONTENT_TEXT_COOKIES,
  GET_CONTENT_TEXT_PRIVACY,
  GET_CONTENT_SERVICES,
} from '../constants';

import {
  getFeed,
  getContact,
  getTariff,
  getSiteTC,
  getCardTC,
  getCookies,
  getPrivacy,
  getServices,
} from '../../api'

function* getContentFeedSaga({payload}) {
  try {
    const response = yield call(getFeed, payload);
    yield put({ type: `${GET_CONTENT_FEED}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_FEED}_FAILED`, payload: error.response });
  }
}
function* getContentServicesSaga({payload}) {
  try {
    const response = yield call(getServices, payload);
    yield put({ type: `${GET_CONTENT_SERVICES}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_SERVICES}_FAILED`, payload: error.response });
  }
}
function* getContentTextContactSaga() {
  try {
    const response = yield call(getContact);
    yield put({ type: `${GET_CONTENT_TEXT_CONTACT}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_TEXT_CONTACT}_FAILED`, payload: error.response });
  }
}
function* getContentTextTariffSaga() {
  try {
    const response = yield call(getTariff);
    yield put({ type: `${GET_CONTENT_TEXT_TARIFF}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_TEXT_TARIFF}_FAILED`, payload: error.response });
  }
}
function* getContentTextSiteTCSaga() {
  try {
    const response = yield call(getSiteTC);
    yield put({ type: `${GET_CONTENT_TEXT_SITETC}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_TEXT_SITETC}_FAILED`, payload: error.response });
  }
}
function* getContentTextCardTCSaga() {
  try {
    const response = yield call(getCardTC);
    yield put({ type: `${GET_CONTENT_TEXT_CARDSTC}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_TEXT_CARDSTC}_FAILED`, payload: error.response });
  }
}
function* getContentTextCookiesSaga() {
  try {
    const response = yield call(getCookies);
    yield put({ type: `${GET_CONTENT_TEXT_COOKIES}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_TEXT_COOKIES}_FAILED`, payload: error.response });
  }
}
function* getContentTextPrivacySaga() {
  try {
    const response = yield call(getPrivacy);
    yield put({ type: `${GET_CONTENT_TEXT_PRIVACY}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CONTENT_TEXT_PRIVACY}_FAILED`, payload: error.response });
  }
}

export function* contentActionWatcher() {
  yield takeLatest(`${GET_CONTENT_FEED}_REQUEST`, getContentFeedSaga)
  yield takeLatest(`${GET_CONTENT_SERVICES}_REQUEST`, getContentServicesSaga)
  yield takeLatest(`${GET_CONTENT_TEXT_CONTACT}_REQUEST`, getContentTextContactSaga)
  yield takeLatest(`${GET_CONTENT_TEXT_TARIFF}_REQUEST`, getContentTextTariffSaga)
  yield takeLatest(`${GET_CONTENT_TEXT_SITETC}_REQUEST`, getContentTextSiteTCSaga)
  yield takeLatest(`${GET_CONTENT_TEXT_CARDSTC}_REQUEST`, getContentTextCardTCSaga)
  yield takeLatest(`${GET_CONTENT_TEXT_COOKIES}_REQUEST`, getContentTextCookiesSaga)
  yield takeLatest(`${GET_CONTENT_TEXT_PRIVACY}_REQUEST`, getContentTextPrivacySaga)

}
