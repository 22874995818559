import auth from './auth'
import cards from './cardsReducer'
import content from './contentReducer'
import letters from './lettersReducer'
import profile from './profileReducer'
import stuff from './startUpReducer'

export default {
  auth,
  cards,
  content,
  letters,
  profile,
  stuff
}
