import {
  GET_CONTENT_FEED,
  GET_CONTENT_TEXT_CONTACT,
  GET_CONTENT_TEXT_TARIFF,
  GET_CONTENT_TEXT_SITETC,
  GET_CONTENT_TEXT_CARDSTC,
  GET_CONTENT_TEXT_COOKIES,
  GET_CONTENT_TEXT_PRIVACY,
  GET_CONTENT_SERVICES,
} from '../constants'

const initState = {}

export default function contentReducer(state = initState, action) {
  
  
  switch (action.type) {
    
    case `${GET_CONTENT_FEED}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_FEED}_SUCCESS`: {
      return {
        ...state,
        feed: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_FEED}_FAILED`: {
      return {
        ...state,
        feed: null,
        isFetching: false
      }
    }
    
    case `${GET_CONTENT_SERVICES}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_SERVICES}_SUCCESS`: {
      return {
        ...state,
        services: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_SERVICES}_FAILED`: {
      return {
        ...state,
        services: null,
        isFetching: false
      }
    }
    
    case `${GET_CONTENT_TEXT_CONTACT}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_TEXT_CONTACT}_SUCCESS`: {
      return {
        ...state,
        contact: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_TEXT_CONTACT}_FAILED`: {
      return {
        ...state,
        contact: null,
        isFetching: false
      }
    }

    case `${GET_CONTENT_TEXT_TARIFF}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_TEXT_TARIFF}_SUCCESS`: {
      return {
        ...state,
        tariff: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_TEXT_TARIFF}_FAILED`: {
      return {
        ...state,
        tariff: null,
        isFetching: false
      }
    }
    
    case `${GET_CONTENT_TEXT_SITETC}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_TEXT_SITETC}_SUCCESS`: {
      return {
        ...state,
        siteTC: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_TEXT_SITETC}_FAILED`: {
      return {
        ...state,
        siteTC: null,
        isFetching: false
      }
    }
    
    case `${GET_CONTENT_TEXT_CARDSTC}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_TEXT_CARDSTC}_SUCCESS`: {
      return {
        ...state,
        cardsTC: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_TEXT_CARDSTC}_FAILED`: {
      return {
        ...state,
        cardsTC: null,
        isFetching: false
      }
    }
    
    case `${GET_CONTENT_TEXT_COOKIES}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_TEXT_COOKIES}_SUCCESS`: {
      return {
        ...state,
        cookies: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_TEXT_COOKIES}_FAILED`: {
      return {
        ...state,
        cookies: null,
        isFetching: false
      }
    }
    
    case `${GET_CONTENT_TEXT_PRIVACY}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_CONTENT_TEXT_PRIVACY}_SUCCESS`: {
      return {
        ...state,
        privacy: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CONTENT_TEXT_PRIVACY}_FAILED`: {
      return {
        ...state,
        privacy: null,
        isFetching: false
      }
    }


    default:
      return state
  }
} 