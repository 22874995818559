import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import ReactCodeInput from "react-code-input";
import { Modal } from '../../components/Modal'
import { screenHOC } from '../HOC/screenHOC'
import './auth.css';
import BgImge from '../../assets/images/auth/bg-02.png';
import { Footer } from '../../components/Footer';

class RecoveryCode extends Component {
  constructor (props) {
    super(props);
    this.state = {
      newPassword: '',
      retypePassword: '',
      code: '',
      newPasswordError: '',
      retypePasswordError: '',
      codeError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      errorCount: 0,
    }
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    switch(fieldName) {
      case 'newPassword':
        if (value.length < 6) {
          text = 'INCORRECT NEW PASSWORD';
          error = true
        }
        break;
      case 'retypePassword':
        if (value.length < 6) {
          text = 'INCORRECT RETYPE PASSWORD';
          error = true;
        }
        break;
      case 'code':
        if (value.length != 6) {
          text = 'INCORRECT CODE';
          error = true;
        }
        break;
    }

    this.setState({errorValidate: error, [fieldName + 'Error']: text});

    return !error; //lk
  }

  sendForm = () => {
    // if (
    //   this.validateField('newPassword', this.state.newPassword) &&
    //   this.validateField('retypePassword', this.state.retypePassword) &&
    //   this.validateField('code', this.state.code)
    // ) {
      this.setState({loader: true});
      // this.setState({errorCount: this.state.errorCount + 1});

      //window.location.replace("/recoveryok");
      //lk incorrect confirmation code
    // }
  }

  openRegister = () => {
    window.location.replace("/register")
  }

  render () {
    return (
      <div className="App">
        <div className="auth-content">
          <div className="auth-c1">
            
          </div>
          <div className="auth-c2" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form">
              <h2 className="auth-t1">Password Recovery</h2>
              <p className="recoverycode-t2">Master Key has generated a one-time confirmation code,  please insert it below to complete the password recovery process.</p>

              <div className="auth-input">
                <label className="auth-input-label">New Password</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" required name="newPassword"
                    value={this.state.newPassword}
                    onChange={this.handleInput}
                  />
                  <div className={'auth-error auth-input-error' + (this.state.newPasswordError != '' ? ' auth-has-error' : '')}>
                    <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.newPasswordError}</div>
                  </div>
                </div>
              </div>

              <div className="auth-input">
                <label className="auth-input-label">Retype New Password</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" required name="retypePassword"
                    value={this.state.retypePassword}
                    onChange={this.handleInput}
                  />
                  <div className={'auth-error auth-input-error' + (this.state.retypePasswordError != '' ? ' auth-has-error' : '')}>
                    <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.retypePasswordError}</div>
                  </div>
                </div>
              </div>

              <div className="auth-code-group">
                <div className="auth-code-label">Confirmation Code:</div>
                <ReactCodeInput
                  type='text'
                  fields={6}
                  value={this.state.code}
                  onChange={(value) => this.handleInput({target: {name: 'code', value: value}})}
                  className="auth-code-input"
                />
                <div className={'auth-error auth-code-error' + (this.state.codeError != '' ? ' auth-has-error' : '')}>
                  <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.codeError}</div>
                </div>
                <div className="auth-code-t1">
                  Problems with my Master Key code
                </div>
              </div>

              <div className="auth-btn-group">
                <div className="auth-btn auth-b3 auth-btn-loader" onClick={this.sendForm}>
                  <span>Submit</span>
                  <Loader
                    style={{marginLeft: '10px', height: '25px'}}
                    visible={this.state.loader}
                    type="Oval"
                    color="#d7c29a"
                    height={25}
                    width={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title='Master Key Error'
          hideButton={true}
          modalOpen={this.state.errorCount >= 3}
          content={
            <div className="auth-modal">
              <p>If you are having problems receiving your Master Key authentication code please contact our customer services team on the number below:</p>
              <p style={{marginTop: '15px'}}>24-hour phone support:</p>
              <p>+44-203-151-7000 / +800-800-00-700</p>
            </div>
          }
          buttons={[
            {onClick: () => {}, text: 'Download Now', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
        <Footer />
      </div>
    )
  }
}

export default RecoveryCode

/*
Your details have been sent
Please note that your username has been sent to your email address. If you have any further questions, please contact the call centre team on the number below:
*/