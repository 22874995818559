import React, { createRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import Card from '../../assets/images/royal-digital-card.png'
import './CardSlider.css'
import classNames from 'classnames'
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  getCardsProducts,
  getAllInvoicesCardAction,
  getAllStatemensCardAction
} from '../../redux/actions/cardsActions'
import { baseURL } from '../../api/api'
import { withRouter } from 'react-router-dom'
import ICACard from '../ICOCard/ICOCard';

const slickSliderRef = createRef()

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, height: '100%', display: 'flex', alignItems: 'center' }}
      onClick={onClick}
    >
      <Arrow style={{ left: 10, position: 'relative' }} className={classNames(props.inactive && 'inactive')} />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, height: '100%', display: 'flex', alignItems: 'center' }}
      onClick={onClick}
    >

      <Arrow style={{ transform: 'rotate(180deg)' }} className={classNames(props.inactive && 'inactive')} />
    </div>
  );
}

const settings = {
  focusOnSelect: true,
  dots: false,
  infinite: false,
  speed: 500,
  variableWidth: true,
  slidesToScroll: 7
};

const mapStateToProps = state => ({
  invoices: state.cards.invoices,
  steatments: state.cards.steatments,
  productsCards: state.cards.cardsProducts || [],
})

export const CardSlider = connect(mapStateToProps)(
  withRouter(({
    cardsArr = [],
    visibleCount = 3,
    cardClassName,
    cardSliderClassName,
    isNeedSeparator,
    onSelectCard,
    onSelectICA,
    toggleCards,
    showCardId = 0,
    blurCardsIds = [],
    customCardArrowSeparatorStyles,
    dispatch,
    invoices,
    steatments,
    productsCards,
    history,
    dontRotate,
    goToSlideNumber,
    showNameOverCard,
    renderButtons,
    showICA,
    cardImage = 'front'
  }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
      if (cardsArr.length && (!cardsArr[0].images || !cardsArr[0].images[cardImage])) {
        dispatch(
          getCardsProducts()
        )
      }
    }, [cardsArr])

    useEffect(() => {
      if (!!goToSlideNumber && slickSliderRef) {
        setTimeout(
          () => {
            if (slickSliderRef.current !== null) {
              slickSliderRef.current.slickGoTo(goToSlideNumber)
            }
          },
          250
        )
      }
    }, [goToSlideNumber, slickSliderRef])

    const renderCardNumber = number => {
      const result = []
      if (number) {
        for (let i = 0; i < number.length; i++) {
          if (i % 4 === 0) {
            result.push(' ')
            result.push(number[i])
          } else {
            result.push(number[i])
          }
        }
        return result.join('')
      } else {
        return ''
      }
    }

    const renderBackgroundImg = (item) => {
      let bg = ''
      for (let i = 0; i < productsCards.length; i++) {
        if (productsCards[i].id === item.productId) {
          bg = productsCards[i].images ? productsCards[i].images[cardImage] : null
        }
      }
      return bg ? `${baseURL}/${bg}` : Card
    }

    const getColor = (item) => {
      let color = '#ffffff'
      for (let i = 0; i < productsCards.length; i++) {
        if (productsCards[i].id === item.productId) {
          color = productsCards[i].textColor
        }
      }
      return color
    }

    const renderCard = (item, key) => (
      <>
        <div
          id={key}
          key={key}
          className={classNames(`card ${cardClassName && cardClassName}`, toggleCards && showCardId !== key ? showNameOverCard ? 'toggleCardSmall' : 'toggleCard' : null, blurCardsIds.filter(i => i === key).length && 'toggleCard')}
          onClick={(e) => {
            if (showCardId === key) {
              onSelectCard && onSelectCard(null, null, e);
            } else {
              onSelectCard && onSelectCard(item, key, e);
              slickSliderRef.current.slickGoTo(key);
              onSelectICA({});
            }

            dispatch(
              getAllStatemensCardAction(item.id)
            )

            dispatch(
              getAllInvoicesCardAction(item.id)
            )

          }}

        >
          {
            showNameOverCard
              ? <div className='textOverCard'>
                {item.name}
              </div>
              : null
          }
          {
            item.currency && item.cardNumber
              ? showCardId === key && !dontRotate
                ? <div className={classNames('card-item')}>
                  <div className='selected-card-info'>
                    <div style={{ flexGrow: 1, flexDirection: 'row', display: 'flex', margin: '11px 0 0 13px' }}>
                      <div style={{
                        width: '22px',
                        height: '22px',
                        backgroundColor: '#b70000',
                        borderRadius: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                      }}>!</div>
                      <div className='selected-card-text'>
                        <div>Outstanding Balance:</div>
                        <div>{item.currency}{item.statementBalance}</div>
                        {
                          invoices && invoices.length && invoices[0].date
                            ? <div>
                              Invoice Date: {moment(invoices[0].date).format('DD/MM/YYYY')}
                            </div>
                            : null
                        }
                        {
                          // steatments && steatments.length && steatments[0].date
                          item.settelmentDate
                            ? <div>
                              Settelment Date: {moment(item.settelmentDate).format('DD/MM/YYYY')}
                            </div>
                            : null
                        }
                      </div>
                    </div>
                    <div>
                      {
                        invoices && invoices.length && invoices[0].paymentDue &&
                        <div className='due' onClick={() => {
                          history.push('/cards', { item })
                        }}>
                          invoice due
                              </div>
                      }
                      <div className='selected-card-currency'>
                        <div>{item.currency}</div>
                      </div>
                    </div>
                  </div>
                </div>
                : <div className={classNames('card-item', isNeedSeparator && showCardId !== key && 'unsetBoxShadow')} style={{ backgroundImage: `url(${renderBackgroundImg(item)})` }}>
                  {
                    item.paymentDue &&
                    <div className='due' onClick={() => {
                      history.push('/cards', { item })
                    }}>
                      invoice due
                    </div>
                  }
                  <div className={classNames('currency', !item.paymentDue && 'due-space')}>
                    <div style={{ color: getColor(item) }}>{item.currency}</div>
                  </div>
                  <p className='card-item-number' style={{ color: getColor(item) }}>{renderCardNumber(item.cardNumber)}</p>
                  <p className='card-item-number' style={{ color: getColor(item) }}>{item.cardName}</p>
                </div>
              : <img src={item.images && item.images[cardImage] ? `${baseURL}/${item.images[cardImage]}` : Card} alt='text' className={isNeedSeparator && showCardId !== key && 'unsetBoxShadow'} />
          }
          {
            isNeedSeparator ? showCardId !== key ? <div className={classNames('card-arrow-separator', customCardArrowSeparatorStyles)} /> : <div className='card-separator' /> : null
          }
        </div>
        {
          showCardId === key &&
          <div className="card-current">
            {
              renderButtons && renderButtons()
            }
          </div>
        }
      </>
    )

    const beforeChange = (old, newIndex) => {
      setCurrentIndex(newIndex)
    }

    return (
      <div className={cardSliderClassName || 'cardSlider'} >
        {
          cardsArr.length
            ? <Slider
              {...{ ...settings, slidesToShow: visibleCount === 7 ? 1 : 3, slidesToScroll: visibleCount === 7 ? 1 : 3, className: 'card', nextArrow: <NextArrow inactive={currentIndex >= (cardsArr.length / visibleCount)} />, prevArrow: <PrevArrow inactive={currentIndex === 0} /> }}
              beforeChange={beforeChange}
              ref={slickSliderRef}
            >
              {cardsArr.length && cardsArr.map(renderCard)}
            </Slider>
            : null
        }
      </div>
    )
  })
)
