import { put, takeLatest, call } from 'redux-saga/effects';
import {
  register,
  login,
  refresh,
  recoverPassword,
  verifyCode
} from '../../api'
import {
  REGISTER,
  LOGIN,
  VERIFY_CODE,
  REFRESH,
  RECOVER_PASSWORD,
} from '../constants';

function* registerSaga({payload}) {
  try {
    const response = yield call(register, payload);
    yield put({ type: `${REGISTER}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${REGISTER}_FAILED`, payload: error.response });
  }
}

function* loginSaga({payload}) {
  try {
    const response = yield call(login, payload);
    yield put({ type: `${LOGIN}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${LOGIN}_FAILED`, payload: error.response || error });
  }
}

function* verifyCodeSaga({payload}) {
  try {
    const response = yield call(verifyCode, payload);
    yield put({ type: `${VERIFY_CODE}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${VERIFY_CODE}_FAILED`, payload: error.response });
  }
}

function* refreshSaga() {
  try {
    const response = yield call(refresh);
    yield put({ type: `${REFRESH}_SUCCESS`, payload: response });
  } catch (error) {
    console.log({error});
  }
}

function* recoverPasswordSaga({payload}) {
  try {
    const response = yield call(recoverPassword, payload);
    yield put({ type: `${RECOVER_PASSWORD}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${RECOVER_PASSWORD}_FAILED`, payload: error.response });
    console.log({error});
  }
}

export function checkAuthError(error) {

}

export function* authActionWatcher() {
  yield takeLatest(`${REGISTER}_REQUEST`, registerSaga)
  yield takeLatest(`${LOGIN}_REQUEST`, loginSaga)
  yield takeLatest(`${VERIFY_CODE}_REQUEST`, verifyCodeSaga)
  yield takeLatest(`${REFRESH}_REQUEST`, refreshSaga)
  yield takeLatest(`${RECOVER_PASSWORD}_REQUEST`, recoverPasswordSaga)
}
