import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal } from '../../components/Modal';
import { screenHOC } from '../HOC/screenHOC';
import './auth.css';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import BgImge from '../../assets/images/auth/bg-01.png';
import { Footer } from '../../components/Footer';

class Masterkey extends Component {
  constructor (props) {
    super(props);
    this.state = {

    }
  }

  openRegister = () => {
    window.location.replace("/register")
  }

  render () {
    return (
      <div className="App">
        <div className="logo-box">
          <NavLink to='/'>
            <img src={Logo} alt='logo' width='100%' height='100%' className='logo' />
          </NavLink>
        </div>
        <div className="auth-content">
          <div className="auth-c1"></div>
          
          <div className="auth-c2 auth-c2-masterkey" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form-recovery">
              <h2 className="auth-t1">Master Key </h2>

              <div className="masterkey-group">
                <span className="masterkey-i">1.</span><span className="masterkey-t">Search for Master Key EQ in the App Store for iOS devices or Google Play for Android devices.</span>
              </div>
              <div className="masterkey-group">
                <span className="masterkey-i">2.</span><span className="masterkey-t">Open the Master Key application and allow notifications.</span>
              </div>
              <div className="masterkey-group">
                <span className="masterkey-i">3.</span><span className="masterkey-t">In order to activate your Master Key app, please call: +44 203 530 5007</span>
              </div>
              <div className="masterkey-group">
                <span className="masterkey-i">4.</span><span className="masterkey-t">During the call you will receive an activation SMS. Follow the link to activate.</span>
              </div>
              <div className="masterkey-group">
                <span className="masterkey-i">5.</span><span className="masterkey-t">Trigger the activation by opening the page <br/> in your Master Key app.</span>
              </div>

              <div className="auth-btn-group">
                {/* <div className="auth-btn auth-b1" onClick={this.openRegister}>Registration</div> */}
                {/* <a className="auth-btn auth-b2" href='https://apps.apple.com/us/app/master-key/id1227460308' target='_blank' rel="noreferrer">Download New</a> */}
                <a  href='https://apps.apple.com/us/app/master-key/id1227460308' target='_blank' rel='noreferrer' >
                  <img src='../../assets/images/AppStoreLogo.png' alt='text' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=ltd.masterkey.app' target='_blank' rel='noreferrer'  style={{marginLeft: 10}}>
                  <img src='../../assets/images/googlePlayLogo.png' alt='text' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Masterkey


/*


Open the Master Key application and allow notifications.





Trigger the activation by opening the page in
your Master Key app.
*/