import { all, fork } from "redux-saga/effects";
import { authActionWatcher } from "./authSaga";
import { cardsActionWatcher } from "./cardsSaga";
import { contentActionWatcher } from "./contentSaga";
import { lettersActionWatcher } from "./lettersSaga";
import { profileActionWatcher } from "./profileSaga";
import { startUpSaga } from "./startUpSaga";
import { stuffActionWatcher } from "./stuffSaga";

export default function* root() {
  yield fork(startUpSaga);
  const tasks = [
    authActionWatcher(),
    cardsActionWatcher(),
    contentActionWatcher(),
    lettersActionWatcher(),
    profileActionWatcher(),
    stuffActionWatcher()
  ];
  yield all(tasks)
}