import Axios from "axios";
import { take } from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import { RESET } from "../constants";

export function* resetSaga() {
  try {
    localStorage.removeItem('stuff')
    Axios.defaults.headers.common['Authorization'] = null;
    
    yield take({ type: RESET });
  } catch (error) {
    console.log(error);
  }
}

export function* stuffActionWatcher() {
  yield take(RESET, resetSaga)
}