import { LOGIN, RECOVER_PASSWORD, REFRESH, REGISTER, VERIFY_CODE, SET_TOKEN, RESET_ERRORS } from "../constants";

export const registerAction = (data) => {
  return {
    type: `${REGISTER}_REQUEST`,
    payload: data
  }
};

export const loginAction = (data) => {
  return {
    type: `${LOGIN}_REQUEST`,
    payload: data
  }
};

export const verifyCodeAction = (data) => {
  return {
    type: `${VERIFY_CODE}_REQUEST`,
    payload: data
  }
};

export const refreshAction = (data) => {
  return {
    type: `${REFRESH}_REQUEST`,
    payload: data
  }
};

export const recoverPasswordAction = (data) => {
  return {
    type: `${RECOVER_PASSWORD}_REQUEST`,
    payload: data
  }
};

export const setToken = (data) => {
  return {
    type: `${SET_TOKEN}`,
    payload: data
  }
};

export const resetErrors = () => {
  return {
    type: `${RESET_ERRORS}`,
  }
};