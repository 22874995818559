import { REFRESH, RESET, STARTUP } from '../constants'

const initState = {}

export default function contentReducer(state = initState, action) {
  const statusCode = action.payload?.status || 0
  if (statusCode === 401 || statusCode === 404) {
    action.type = REFRESH
  }
  switch (action.type) {
    case STARTUP: {
      return {
        ...state,
        ...action.payload
      }
    }
    case RESET: {
      return initState
    }

    default:
      return state
  }
} 