import React from 'react'
import classNames from 'classnames/bind'
import Loader from 'react-loader-spinner'

import './Button.css'

const cx = classNames.bind('./Button.css')

export const Button = ({text, icon, buttonStyles, textStyles, onClick, whiteButton, loading, refControl, className}) => {
  return (
    <div className={className + ' ' + cx(whiteButton ? 'whiteButton' : 'button')} style={buttonStyles} onClick={onClick} ref={refControl}>
      {}
      <span style={textStyles}>
        {text}
      </span>
      {loading ? <Loader
        style={{ marginLeft: '-35px', height: '25px', position: 'relative', left: '45px' }}
        visible={loading}
        type="Oval"
        color="#d7c29a"
        height={25}
        width={25}
      /> : null}      
    </div>
  )
}
