import React, { useEffect, useState } from 'react'
import { screenHOC } from '../HOC/screenHOC'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Home.css'
import { CardSlider } from '../../components/CardSlider';
import Slider from 'react-slick'
import { CardInfo } from '../../components/CardInfo';
import Card from '../../assets/images/right.png'
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { connect } from 'react-redux';
import { getCardsAll, getCardsLatest } from '../../redux/actions/cardsActions';
import InsigniaCards from '../../components/InsigniaCards';
import Axios from 'axios';
import { setToken } from '../../redux/actions/authActions';
import { getContentFeedAction, getContentServicesAction } from '../../redux/actions/contentActions';
import { baseURL } from '../../api/api';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg'
import { useRef } from 'react';
import { Preloader } from '../../components/Preloader';

const rewards = [
  {
    img: '../../assets/images/rewards.png',
    title: 'Rewards1',
    text: 'Our pioneering loyalty points programme allows us to reward you wherever and whenever you use your Insignia card. Once you’ve accrued a balance, you’ll be able to shop, book travel or simply check your points at the touch of a button. The breadth of luxury brand goods and premium travel options ensures that Insignia Rewards is a loyalty programme with genuine incentivisation for our valued members.',
  },
  {
    img: '../../assets/images/img001.png',
    title: 'Rewards2',
    text: 'Our pioneering loyalty points programme allows us to reward you wherever and whenever you use your Insignia card. Once you’ve accrued a balance, you’ll be able to shop, book travel or simply check your points at the touch of a button. The breadth of luxury brand goods and premium travel options ensures that Insignia Rewards is a loyalty programme with genuine incentivisation for our valued members.',
  },
  {
    img: '../../assets/images/rewards.png',
    title: 'Rewards3',
    text: 'Our pioneering loyalty points programme allows us to reward you wherever and whenever you use your Insignia card. Once you’ve accrued a balance, you’ll be able to shop, book travel or simply check your points at the touch of a button. The breadth of luxury brand goods and premium travel options ensures that Insignia Rewards is a loyalty programme with genuine incentivisation for our valued members.',
  },
]

const Home = ({latestCards, allCards, token, news, services, dispatch, isFetching}) => {
  const [currentReward, setCurrentReward] = useState(0)
  const [selectedCard, setSelectedCard] = useState({})
  const [shownCard, setShownCard] = useState(null)
  const [refs] = useState({
    newsRef1: useRef(),
    newsRef2: useRef(),
    newsRef3: useRef(),
    newsRef4: useRef(),
  })

  useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem('stuff'));
  
    if (storageData) {
      if (storageData?.token) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${storageData?.token}`;
      }
      setToken(storageData?.token)
    }
    
    setTimeout(() => {
      dispatch(
          getCardsAll()
      )
      dispatch(
          getCardsLatest()
      )
      dispatch(
          getContentFeedAction(4)
      )
      dispatch(
          getContentServicesAction()
      )
    }, 250)
  }, [])

  const onSelectCard = (item, key) => {
    // setSelectedCard(item)
    setShownCard(key)
  }

  const renderReward = () => {
    const {backgroundImagePath, title, description, foregroundImagePath, url} = services?.[currentReward || 0] || {}
    return <div style={{marginLeft: '-3rem'}} className='reward' >
      <img src={`${baseURL}/${backgroundImagePath}`} alt='' style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}} />
      <div className='reward-img-container'>
        <div
          className={`arrow arrow-left ${currentReward === 0 && 'inactive'}`}
          onClick={() => setCurrentReward(old => currentReward === 0 ? old : old - 1)}
        />
        <div
          className={`arrow arrow-right ${currentReward === rewards.length && 'inactive'}`}
          onClick={() => setCurrentReward(old => currentReward === rewards.length ? old : old + 1)}
        />
        <img src={`${baseURL}/${foregroundImagePath}`} alt='' className='reward-img' />
      </div>
      <div className='reward-content'>
        <h3 className='reward-content-title'>{title}</h3>
        <p className='reward-content-text'>
          {description}
        </p>
        <a href={url} target='_blank' rel="noreferrer">
          <Button text='Find Out More' buttonStyles={{width: 200, marginTop: 20}} />
        </a>
      </div>
    </div>
  }

  if (allCards && news && services) {
    return (
      <div className="Home">
        {/* <CardSlider blurCardsIds={[]} cardsArr={allCards} onSelectCard={onSelectCard} showCardId={shownCard}  /> */}
        <div>
          <InsigniaCards />
        </div>
        <div className='news'>
            <h3>Insignia News</h3>
            <p className='text'>
              From trending restaurants in major capital cities to the most secluded wellness and spa hideaways, we live and breathe luxury living and curate insightful articles to keep you informed
            </p>
            <div className='news-block'>
              {
                news.map((item, key) => {
                  return <div key={key} className='news-item'>
                    <img src={item.imageSrc} alt='' className='news-item-img' />
                    {
                      key === 0 && <div className='separate' />
                    }
                    <div className='news-item-content'>
                      <div className='news-item-title' ref={refs[`newsRef${key + 1}`]}>{item.title}</div>
                      <div className='hr' />
                      {
                            <p
                              className='news-item-text'
                              style={{
                                maxHeight: 642 - (refs[`newsRef${key + 1}`]?.current?.clientHeight) - 50 - 58 - 28 - 55,
                                WebkitLineClamp: Math.floor((642 - (refs[`newsRef${key + 1}`]?.current?.clientHeight) - 50 - 58 - 28 - 55) / 28.08) || 7
                              }}
                            >
                                {item.description.replace(/\[[^()]*\]/g,'...').replace(/&#[^()]*;/g,'')}
                              </p>
                      }
                      
                      <a className='link-to-news' href={item.imageHref} target='_blank' rel='noreferrer'>
                        Read more
                      </a>
                    </div>
                    {
                      key === 3 && news && news.length >= 4
                        ?
                        <a className='more-news' href='https://insignia.com/lifestyle-management/magazine/news/'
                          target='_blank'>
                          <div style={{
                            objectFit: 'contain',
                            fontFamily: 'ProximaNova',
                            fontSize: '18px',
                            fontWeight: '500',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: '1.56',
                            letterSpacing: 'normal',
                            color: '#000',
                            marginBottom: 25,
                          }}>More News
                          </div>
                          <div style={{width: 49, height: 1, marginBottom: 30, backgroundColor: '#d7c29a',}}/>
                          <div style={{
                            height: 61,
                            width: 61,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#d7c29a',
                            borderRadius: '50%'
                          }}>
                            <Arrow style={{left: 2, position: 'relative'}}/>
                          </div>
                        </a>
                        : null
                    }
                  </div>
                })
              }
            </div>
        </div>
        <div className='rewards'>
          {
            renderReward()
          }
        </div>
      </div>
    )
  } else {
    return <Preloader />
  }
  
}

const mapStateToProps = state => ({
  latestCards: state.cards.cardsLatest || [],
  allCards: state.cards.cardsAll || [],
  news: state.content.feed || [],
  token: state.auth.token,
  services: state.content.services,
  isFetching: state.cards.isFetching //|| state.content.isFetching || state.letters.isFetching || state.profile.isFetching || state.stuff.isFetching
})

export default connect(mapStateToProps)(screenHOC(Home))