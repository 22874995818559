import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { getContentTextTariffAction } from '../../redux/actions/contentActions';
import { screenHOC } from '../HOC/screenHOC'
import './info.scss';
import './tariffs.scss';

const TariffPlan = ({dispatch, tariff}) => {
    useEffect(() => {
        dispatch(
            getContentTextTariffAction()
        )
    })

    const [opened, setOpened] = useState('');
    const [openedSub, setOpenedSub] = useState('');
  
    const setOpenedItem = (l1) => setOpened(l1 == opened ? '' : l1);
    const setOpenedSubItem = (l2) => setOpenedSub(l2 == openedSub ? '' : l2);

    const isOpened = (l1) => {
        if (l1 === '') {
            return false;
        }

        if (l1 !== opened) {
            return false;
        }

        return true;
    };

    const isOpenedSub = (l1, l2) => {
        if (!isOpened(l1)) {
            return false;
        }

        if (openedSub === '') {
            return false;
        }

        if (l2 !== openedSub) {
            return false;
        }

        return true;
    };
  

    const tariffsData = {
        'Jewellery Card': {
            'Fees': {
                'Currencies availale': 'USD / EUR / CHF / GBP',
                'Jewellery Card production cost': 'POA',
                'Annual Fee': '6,990',
                'Family programme': 'Not Applicable',
                'Supplementary cards': 'Two Elite Friends &amp; Family cards free',
                'Card replacement fee and/or PIN reprinting fee': 'One card free, 250 thereafter (subject to discression)',
                'PIN change fee': '50',
                'Printed statement fee 2': '55',
                'Insignia Online Network': 'Free',
                'Master Key 3': 'Free',
                'Emergency card delivery fee 4': 'On request',
                'Late payment fee': 'Min 25.00 max 2.75%',
                'Late payment interest': 'Min 25 max 22.5%',
                'Exchange rate conversion fee': '2.50%',
                'Over-limit fee': 'Not Applicable',
                'Purchase return fee': 'Not Applicable',
                'Original credit transactions (no original debit transaction)': '0.30%',
                'Quarterly Administrative Fees': '39.8',
                'Administrative investigation fee 6': '95',
                'VISA arbitration fee': '750',
            },
            'Sending Limits': {
                'Limit per billing cycle': 'According to the available balance',
            },
            'Cash Withdrawal': {
                'Limit per billing cycle 7': 'Up to 50,000',
                'ATM withdrawal fee': 'Min 12.00 max 2.3% 8',
                'Cash-desk withdrawal fee': 'Min 15.00 max 2.5%',
            },
            'Statement Issuance': {
                'Billing cycle': 'Quarterly',
            },
            'Card Validity': {
                'Valid for': '5 years',
                'Companion Card': '5 years',
            },
        },
        'Royal Card': {
            'Fees': {
                'Currencies availale': 'USD / EUR / CHF / GBP',
                'Jewellery Card production cost': 'Not Applicable',
                'Annual Fee': '6,990',
                'Family programme': '8,990',
                'Supplementary cards': 'Two Elite Friends &amp; Family cards free',
                'Card replacement fee and/or PIN reprinting fee': 'One card free, 250 thereafter (subject to discression)',
                'PIN change fee': '50',
                'Printed statement fee 2': '55',
                'Insignia Online Network': 'Free',
                'Master Key 3': '9.90 per month',
                'Emergency card delivery fee 4': 'On request',
                'Late payment fee': 'Min 25.00 max 2.75%',
                'Late payment interest': 'Min 25 max 22.5%',
                'Exchange rate conversion fee': '2.50%',
                'Over-limit fee': 'Not Applicable',
                'Purchase return fee': 'Not Applicable',
                'Original credit transactions (no original debit transaction)': '0.30%',
                'Quarterly Administrative Fees': '39.8',
                'Administrative investigation fee 6': '95',
                'VISA arbitration fee': '750',
            },
            'Sending Limits': {
                'Limit per billing cycle': 'According to the available balance',
            },
            'Cash Withdrawal': {
                'Limit per billing cycle 7': 'Up to 50,000',
                'ATM withdrawal fee': 'Min 12.00 max 2.3%',
                'Cash-desk withdrawal fee': 'Min 15.00 max 2.5%',
            },
            'Statement Issuance': {
                'Billing cycle': 'Quarterly',
            },
            'Card Validity': {
                'Valid for': '5 years',
                'Companion Card': 'Not Applicable',
            },
        },
        'Label Card': {
            'Fees': {
                'Currencies availale': 'Multi Currency',
                'Jewellery Card production cost': 'Not Applicable',
                'Annual Fee': '2,990',
                'Family programme': 'Not Applicable',
                'Supplementary cards': 'Not&nbsp;Applicable',
                'Card replacement fee and/or PIN reprinting fee': '180',
                'PIN change fee': '50',
                'Printed statement fee 2': '55',
                'Insignia Online Network': 'Free',
                'Master Key 3': '9.90 per month',
                'Emergency card delivery fee 4': 'On request',
                'Late payment fee': 'Min 25.00 max 2.90%',
                'Late payment interest': 'Min 25 max 22.5%',
                'Exchange rate conversion fee': '2.50%',
                'Over-limit fee': 'Not Applicable',
                'Purchase return fee': 'Not Applicable',
                'Original credit transactions (no original debit transaction)': '0.30%',
                'Quarterly Administrative Fees': '48.6',
                'Administrative investigation fee 6': '95',
                'VISA arbitration fee': '750',
            },
            'Sending Limits': {
                'Limit per billing cycle': 'According to the available balance',
            },
            'Cash Withdrawal': {
                'Limit per billing cycle 7': 'Up to 25,000',
                'ATM withdrawal fee': 'Min 12.00 max 2.3%',
                'Cash-desk withdrawal fee': 'Min 15.00 max 2.5%',
            },
            'Statement Issuance': {
                'Billing cycle': 'Quarterly',
            },
            'Card Validity': {
                'Valid for': '2 years',
                'Companion Card': 'Not Applicable',
            },
        },
        'Elite Card': {
            'Fees': {
                'Currencies availale': 'USD / EUR / CHF / GBP',
                'Jewellery Card production cost': 'Not Applicable',
                'Annual Fee': '690',
                'Family programme': 'Not Applicable',
                'Supplementary cards': 'Not Applicable',
                'Card replacement fee and/or PIN reprinting fee': '90',
                'PIN change fee': '50',
                'Printed statement fee 2': '55',
                'Insignia Online Network': 'Free',
                'Master Key 3': '9.90 per month',
                'Emergency card delivery fee 4': 'On request',
                'Late payment fee': 'Min 25.00 max 3%',
                'Late payment interest': 'Min 25 max 22.5%',
                'Exchange rate conversion fee': '2.50%',
                'Over-limit fee': 'Not Applicable',
                'Purchase return fee': 'Not Applicable',
                'Original credit transactions (no original debit transaction)': '0.30%',
                'Quarterly Administrative Fees': '29.7',
                'Administrative investigation fee 6': '95',
                'VISA arbitration fee': '750',
            },
            'Sending Limits': {
                'Limit per billing cycle': 'According to the available balance',
            },
            'Cash Withdrawal': {
                'Limit per billing cycle 7': 'Up to 10,000',
                'ATM withdrawal fee': 'Min 12.00 max 2.3%',
                'Cash-desk withdrawal fee': 'Min 15.00 max 2.5%',
            },
            'Statement Issuance': {
                'Billing cycle': 'Monthly',
            },
            'Card Validity': {
                'Valid for': '5 years',
                'Companion Card': 'Not Applicable',
            },
        },
        'C by Insignia': {
            'Fees': {
                'Currencies availale': 'USD / EUR / GBP',
                'Jewellery Card production cost': 'Not Applicable',
                'Annual Fee': '1,490',
                'Family programme': 'Not Applicable',
                'Supplementary cards': 'Not Applicable',
                'Card replacement fee and/or PIN reprinting fee': 'One card free, 120 thereafter (subject to discression)',
                'PIN change fee': 'free',
                'Printed statement fee 2': '10',
                'Insignia Online Network': 'Free',
                'Master Key 3': 'Not Applicable',
                'Emergency card delivery fee 4': 'On request',
                'Late payment fee': 'Min 25.00 max 5%',
                'Late payment interest': 'Not Applicable',
                'Exchange rate conversion fee': '2.50%',
                'Over-limit fee': 'Min 5 max 2.0%',
                'Purchase return fee': '0.30%',
                'Original credit transactions (no original debit transaction)': '0.30%',
                'Quarterly Administrative Fees': 'Not Applicable',
                'Administrative investigation fee 6': '95',
                'VISA arbitration fee': '750',
            },
            'Sending Limits': {
                'Limit per billing cycle': 'According to the available balance',
            },
            'Cash Withdrawal': {
                'Limit per billing cycle 7': 'Up to 25,000',
                'ATM withdrawal fee': 'Min 4.00 max 0.4%',
                'Cash-desk withdrawal fee': 'Min 4.00 max 0.4%',
            },
            'Statement Issuance': {
                'Billing cycle': 'Quarterly',
            },
            'Card Validity': {
                'Valid for': '5 years',
                'Companion Card': 'Not Applicable',
            },
        },
    };

  return (
    <div className="privacy">
      <div className="privacy-content content-padding">
          <div className="privacy-line-title">
            <div className="privacy-line"/>
            <h2 className="section-title content-padding-title content-padding-tariff">Tariff</h2>
          </div>
          <p className="title-text">Enter our world of crafted benefits to assist the lifes of our UNHW client base. </p>
        <div className="tariffs-table" dangerouslySetInnerHTML={{__html: tariff}} />

        <div className="tariffs-list">
        {
            Object.keys(tariffsData).map((cardName) => <div className="tariffs-list-item" key={cardName}>
                <div
                    onClick={() => {setOpenedItem(cardName)}}
                    className={`tariffs-list-item__title ${isOpened(cardName) ? 'active' : ''}`}>
                    {cardName}
                </div>

                <div className={`tariffs-list-item-inner ${isOpened(cardName) ? 'active' : ''}`}>
                    {
                        Object.keys(tariffsData[cardName]).map((param) => <div className="tariffs-list-item" key={`${cardName}_${param}`}>

                            <div
                                onClick={() => {setOpenedSubItem(param)}}
                                className={`tariffs-list-item__title ${isOpenedSub(cardName, param) ? 'active' : ''}`}
                            >
                                {param}
                            </div>

                            <div className={`tariffs-list-item-subinner ${isOpenedSub(cardName, param) ? 'active' : ''}`}>
                            {
                                Object.keys(tariffsData[cardName][param]).map((subparam) => <div className="tariffs-list-subitem" key={`${cardName}_${subparam}`}>
                                    <div className="tariffs-list-item__subtitle">{subparam}</div>
                                    <div>{tariffsData[cardName][param][subparam]}</div>
                                </div>)
                            }
                            </div>
                        </div>)
                    }
                </div>
            </div>)
        }
        </div>

        {/* <table>
        <tr className="first-row">
            <th>Card Type</th>
            <th>Jewellery Card</th>
            <th>Royal Card</th>
            <th>Glamour Card</th>
            <th>Label Card</th>
            <th>Elite Card</th>
            <th>Universe Card</th>
            <th>C By Insignia</th>
            <th>Platinum Business</th>
        </tr>
        <tr className="second-row">
            <td>Currencies available </td>
            <td>USD / EUR / CHF/ GBP</td>
            <td>USD / EUR / CHF/ GBP</td>
            <td>USD / EUR / CHF/ GBP</td>
            <td>Multi Currency</td>
            <td>USD / EUR / CHF/ GBP</td>
            <td>USD/EUR</td>
            <td>USD / EUR / CHF/ GBP</td>
            <td>USD / EUR / CHF/ GBP</td>
        </tr>
        <tr className="special-row">
            <td colspan="9">Feels<sup>1</sup></td>
        </tr>
        <tr>
            <td>Jewellery Card production cost </td>
            <td>POA</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
        </tr>
        <tr>
            <td>Annual fee </td>
            <td> 6,990 </td>
            <td> 6,990 </td>
            <td> 6,990 </td>
            <td> 2,990 9 </td>
            <td>690</td>
            <td>890</td>
            <td> 1,490 </td>
            <td> 550 </td>
        </tr>
        <tr>
            <td>Family programme</td>
            <td> Not Applicable </td>
            <td> 8,990 </td>
            <td> 8,990 </td>
            <td> Not Applicable </td>
            <td> Not Applicable </td>
            <td> Not Applicable </td>
            <td> Not Applicable </td>
            <td> Not Applicable </td>
        </tr>
        <tr>
            <td>Supplementary cards </td>
            <td>Two Elite Friends &<br />Family cards free</td>
            <td>Two Elite Friends &<br />Family cards free</td>
            <td>Two Elite Friends &<br />Family cards free</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>5 free, 300 thereafter</td>
            <td>Not Applicable</td>
        </tr>
        <tr>
            <td>Card replacement fee and/or<br /> PIN reprinting fee </td>
            <td>One card free, 250 <br />thereafter <br />(subject to discression)</td>
            <td>One card free, 250 <br />thereafter <br />(subject to discression)</td>
            <td>One card free, 250 <br />thereafter <br />(subject to discression)</td>
            <td>180</td>
            <td>90</td>
            <td>180</td>
            <td>One card free, 120 <br />thereafter <br />(subject to discression)</td>
            <td>20</td>
        </tr>
        <tr>
            <td>PIN change fee </td>
            <td>50</td>
            <td>50</td>
            <td>50</td>
            <td>50</td>
            <td>50</td>
            <td>50</td>
            <td>Free</td>
            <td>Free</td>
        </tr>
        <tr>
            <td>Printed statement fee² </td>
            <td>55</td>
            <td>55</td>
            <td>55</td>
            <td>55</td>
            <td>55</td>
            <td>55</td>
            <td>10</td>
            <td>10</td>
        </tr>
        <tr>
            <td>Insignia Online Network</td>
            <td>Free</td>
            <td>Free</td>
            <td>Free</td>
            <td>Free</td>
            <td>Free</td>
            <td>Free</td>
            <td>Free</td>
            <td>Free</td>
        </tr>
        <tr>
            <td>Master Key</td>
            <td>Free</td>
            <td>9.90 per month</td>
            <td>9.90 per month</td>
            <td>9.90 per month</td>
            <td>9.90 per month</td>
            <td>9.90 per month</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
        </tr>
        <tr>
            <td>Emergency card delivery fee</td>
            <td>On request</td>
            <td>On request</td>
            <td>On request</td>
            <td>On request</td>
            <td>On request</td>
            <td>On request</td>
            <td>On request</td>
            <td>On request</td>
        </tr>
        <tr>
            <td>Late payment fee </td>
            <td>Min 25.00 max 2.75%</td>
            <td>Min 25.00 max 2.75%</td>
            <td>Min 25.00 max 2.75%</td>
            <td>Min 25.00 max<br />2.90%</td>
            <td>Min 25.00 max 3%</td>
            <td>3% or 25.00</td>
            <td>Min 25.00 max 5%</td>
            <td>Min 25.00 max 5%</td>
        </tr>
        <tr>
            <td>Late payment interest </td>
            <td>Min 25 max 22.5% </td>
            <td>Min 25 max 22.5% </td>
            <td>Min 25 max 22.5% </td>
            <td>Min 25 max 22.5% </td>
            <td>Min 25 max 22.5% </td>
            <td>Min 25 max 22.5% </td>
            <td>Min 25 max 22.5% </td>
            <td>Not Applicable</td>
        </tr>
        <tr>
            <td>Exchange rate conversion fee </td>
            <td>2.50%</td>
            <td>2.50%</td>
            <td>2.50%</td>
            <td>2.50%</td>
            <td>2.50%</td>
            <td>2.50%</td>
            <td>2.50%</td>
            <td>1.25%</td>
        </tr>
        <tr>
            <td>Over-limit fee</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Min 5 max 2.0%</td>
            <td>Min 5 max 2.0%</td>
        </tr>
        <tr>
            <td>Purchase return fee</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>0.30%</td>
            <td>0.30%</td>
        </tr>
        <tr>
            <td>Original credit transactions (no<br />original debit transaction) </td>
            <td>0.30%</td>
            <td>0.30%</td>
            <td>0.30%</td>
            <td>0.30%</td>
            <td>0.30%</td>
            <td>0.30%</td>
            <td>0.30%</td>
            <td>0.30%</td>
        </tr>
        <tr>
            <td>Quarterly Administrative Fees </td>
            <td>39.8</td>
            <td>39.8</td>
            <td>39.8</td>
            <td>48.6</td>
            <td>29.7</td>
            <td>29.7</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
        </tr>
        <tr>
            <td>Administrative investigation fee</td>
            <td>95</td>
            <td>95</td>
            <td>95</td>
            <td>95</td>
            <td>95</td>
            <td>95</td>
            <td>95</td>
            <td>95</td>
        </tr>
        <tr>
            <td>VISA arbitration fee </td>
            <td>750</td>
            <td>750</td>
            <td>750</td>
            <td>750</td>
            <td>750</td>
            <td>750</td>
            <td>750</td>
            <td>750</td>
        </tr>
        <tr className="special-row">
            <td colspan="9">SPENDING LIMITS </td>
        </tr>
        <tr>
            <td>Limit per billing cycle </td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
            <td>According to the <br />available balance</td>
        </tr>
        <tr className="special-row">
            <td colspan="9">CASH WITHDRAWAL </td>
        </tr>
        <tr>
            <td>Limit per billing cycle</td>
            <td>Up to 50,000</td>
            <td>Up to 50,000</td>
            <td>Up to 50,000</td>
            <td>Up to 25,000</td>
            <td>Up to 10,000</td>
            <td>Up to 10,000</td>
            <td>Up to 25,000</td>
            <td>Up to 15,000</td>
        </tr>
        <tr>
            <td>ATM withdrawal fee</td>
            <td>Min 12.00 max 2.3%</td>
            <td>Min 12.00 max 2.3%</td>
            <td>Min 12.00 max 2.3%</td>
            <td>Min 12.00 max 2.3%</td>
            <td>Min 12.00 max 2.3%</td>
            <td>Min 12.00 max 2.3%</td>
            <td>Min 4.00 max 0.4%</td>
            <td>Min 4.00 max 0.4%</td>
        </tr>
        <tr>
            <td>Cash-desk withdrawal fee </td>
            <td>Min 15.00 max 2.5%</td>
            <td>Min 15.00 max 2.5%</td>
            <td>Min 15.00 max 2.5%</td>
            <td>Min 15.00 max 2.5%</td>
            <td>Min 15.00 max 2.5%</td>
            <td>Min 15.00 max 2.5%</td>
            <td>Min 4.00 max 0.4%</td>
            <td>Min 4.00 max 0.4%</td>
        </tr>
        <tr className="special-row">
            <td colspan="9">STATEMENT ISSUANCE </td>
        </tr>
        <tr>
            <td>Billing cycle </td>
            <td>Quarterly</td>
            <td>Quarterly</td>
            <td>Quarterly</td>
            <td>Quarterly</td>
            <td>Monthly</td>
            <td>Monthly</td>
            <td>Monthly</td>
            <td>Monthly</td>
        </tr>
        <tr className="special-row">
            <td colspan="9">CARD VALIDITY </td>
        </tr>
        <tr>
            <td>Valid for</td>
            <td>5 years</td>
            <td>5 years</td>
            <td>5 years</td>
            <td>5 years</td>
            <td>2 years</td>
            <td>5 years</td>
            <td>5 years</td>
            <td>2 years</td>
        </tr>
        <tr>
            <td>Companion Card </td>
            <td>5 years</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
        </tr>
    </table> */}

    {/* <p className="tariff-text">
    1 Depending on chosen card currency. <br />
    2 Statement printing fees are applied per printed page, (electronic versions are available online for free). <br />
    3 Applied on a monthly basis. 				<br />
    4 Emergency cards are delivered worldwide within 48 hours, separate charges apply.<br />
    5 A fee is applied when the card limit is exceeded.			<br />
    6 Per transaction where valid proof of purchase is provided. 		<br />
    7 Insignia Cards Limited reserves the right to impose limits on a case-by-case basis. 7ATM withdrawals only available on the companion card <br />
    8 ATM withdrawals only available on the companion card.			<br />
    9 Full Label card services are charged at 2,990, reduced service charges available: Call-Center Service 1,490, Card Only Service 990, Multi-Currency 490, Insignia Connect 20			<br />
    </p> */}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
    tariff: state.content.tariff
})

export default connect(mapStateToProps)(screenHOC(TariffPlan))
