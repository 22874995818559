import React, { createRef, useEffect, useState } from 'react'
import { screenHOC } from '../HOC/screenHOC'
import { Document } from 'react-pdf';
import './styles.scss';
import CardInvoice from '../../assets/images/royal-digital-card.png';
import CardNoInvoice from '../../assets/images/royal-digital-card-no-invoice.png';
import CardPremium from '../../assets/images/royal-digital-card-premium.png';
import { Button } from '../../components/Button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactComponent as Download } from '../../assets/images/download.svg';
import { ReactComponent as Eye } from '../../assets/images/eye.svg';
import { ReactComponent as Print } from '../../assets/images/print.svg';
import { ReactComponent as DownloadMobile } from '../../assets/images/cards/invoices_download_ico.svg';
import { ReactComponent as EyeMobile } from '../../assets/images/cards/invoices_eye_ico.svg';
import { ReactComponent as PrintMobile } from '../../assets/images/cards/invoices_print_ico.svg';
import { connect } from 'react-redux';
import moment from 'moment';
import { getCardInvoicesViewAction, getCardsAll, getCardsLatest, getCardsProducts, getCardSteatmentsViewAction, getInvoicesCardAction, getInvoicesDownloadAction, getInvoicesPrintAction, getInvoicesStatemensAction, getInvoicesStatemensDownloadAction, getInvoicesStatemensPrintAction, resetData,
  getAllInvoicesCardAction, getAllStatemensCardAction } from '../../redux/actions/cardsActions';
import { withRouter } from 'react-router-dom';
import classNamesBind from 'classnames/bind';
import { insertSpaces } from '../../redux/helpers';
import { baseURL } from '../../api/api';
import { Modal } from '../../components/Modal';
import { Preloader } from '../../components/Preloader';
import MessagePopUp from '../../components/MessagePopUp';
import Slider from 'react-slick'

const slickSliderRef = createRef();

const settings = {
  focusOnSelect: true,
  dots: false,
  infinite: false,
  speed: 500,
  variableWidth: true,
  slidesToScroll: 7
};

const TableStatements = ({ dispatch, item, statements, handleViewClick }) => {
  // statements = statements.slice(0,2)

  useEffect(() => {
    dispatch(
      //getInvoicesStatemensAction(item.productId || 1)
      getAllStatemensCardAction(item.id)
    )
  }, [])

  const getDownload = (id, name) => () => {
    dispatch(
      getInvoicesStatemensDownloadAction(id, name, true)
    )
    setTimeout(
      () => dispatch(
        resetData()
      ),
      2000
    )
  }

  const getPrint = id => () => {
    dispatch(
      getInvoicesStatemensPrintAction(id)
    )
  }

  const getViewDoc = id => () => {
    handleViewClick && handleViewClick()
    dispatch(
      getInvoicesStatemensDownloadAction(id)
      //getCardInvoicesViewAction(id)
    )
  }

  return (
    <div className="invoices-table statements-table">
      <table className='table'>
        <thead>
          <tr>
            <th>date</th>
            <th>previous balance</th>
            <th>new credits</th>
            <th>new charges</th>
            <th>new balance</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
            
          {
            statements && statements.length
              ? statements.map((item, key) => (
                <tr key={key}>
                  <td>
                    {moment(item.date || new Date()).format('DD/MM/YY')}
                    {/* <div className="badge-red show-mobile">New</div> */}
                  </td>

                  <td
                    className={item.previousBalance < 0 ? 'balance-minus' : ''}
                  >
                    <div>
                      <span className="balance-currency">{item.currency}</span> {`${(insertSpaces(item.previousBalance)) || '59,463.22'}`}
                    </div>
                  </td>
                  <td
                    className={item.newCredit < 0 ? 'balance-minus' : ''}
                  >
                    <div>
                      <span className="balance-currency">{item.currency}</span> {`${(insertSpaces(item.newCredit)) || '1,030,511.31'}`}
                    </div>
                  </td>
                  <td
                    className={item.newCharges < 0 ? 'balance-minus' : ''}
                  >
                    <div>
                      <span className="balance-currency">{item.currency}</span> {`${(insertSpaces(item.newCharges)) || '1,028,438.07'}`}
                    </div>
                  </td>
                  <td
                    className={item.newBalance < 0 ? 'balance-minus' : ''}
                  >
                    <div>
                      <span className="balance-currency">{item.currency}</span> {`${(insertSpaces(item.newBalance)) || '57,389.98'}`}
                    </div>
                  </td>

                  <td className='action'>
                    <div className="last-col">
                      <div onClick={getViewDoc(item.id || '1')} className='clicked-item hovered-icon'>
                        <span className="show-desktop">
                          <Eye />
                        </span>
                        <span className="show-mobile">
                          <EyeMobile />
                        </span>
                        <div className='action-text'>View</div>
                      </div>
                      
                      <div onClick={getDownload(item.id || '1', `statement_${item.date}`)} className='clicked-item hovered-icon'>
                        <span className="show-desktop">
                          <Download />
                        </span>
                        <span className="show-mobile">
                          <DownloadMobile />
                        </span>
                        <div className='action-text'>Download</div>
                      </div>

                      <div onClick={getPrint(item.id || '1')} className='clicked-item hovered-icon'>
                        <span className="show-desktop">
                          <Print />
                        </span>
                        <span className="show-mobile">
                          <PrintMobile />
                        </span>
                        <div className='action-text'>Print</div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
              : statements
                ? <tr style={{ width: '100%', textAlign: 'center' }}>
                    <td className="no-data" colSpan={6} style={{ height: 150, borderRight: 0 }}>Statements were not found.</td>
                </tr>
                : null
          }
        </tbody>
      </table>
    </div>
  )
};

const TableInvoices = ({ dispatch, item, invoices, handleViewClick }) => {
  // invoices = invoices.slice(0,5)

  useEffect(() => {
    dispatch(
      //getInvoicesCardAction(item.productId || 1)
      getAllInvoicesCardAction(item.id)
    )
  }, [])

  const getDownload = (id, name) => () => {
    dispatch(
      getInvoicesDownloadAction(id, name, true)
    )
    setTimeout(
      () => dispatch(
        resetData()
      ),
      2000
    )
  }

  const getPrint = id => () => {
    dispatch(
      getInvoicesPrintAction(id)
    )
  }

  const getViewDoc = id => () => {
    handleViewClick && handleViewClick()
    dispatch(
      getInvoicesDownloadAction(id)
      //getCardSteatmentsViewAction(id)
    )
  }

  return (
    <div className="invoices-table">
      <table className='table'>
        <thead>
          <tr>
            <th>date</th>
            <th style={{ width: 349 }}>invoice</th>
            <th style={{ width: 321 }}>amount</th>
            <th style={{ width: 177 }}>action</th>
          </tr>
        </thead>
        <tbody>

          {
            invoices && invoices.length
              ? invoices.map((item, key) => (
                <tr key={key}>
                  <td>
                    {moment(item.date || new Date()).format('DD/MM/YY')}
                    {/* <div className="badge-red show-mobile">New</div> */}
                  </td>
                  <td>{item.name || 'ROY TU 1 1202 / 1014'}</td>
                  <td>
                    <div>
                      <span className="balance-currency">{item.currency}</span> {`${insertSpaces(item.amount) || '57 389,98'}`}
                    </div>
                  </td>

                  <td className='action'>
                    <div className="last-col">
                      <div onClick={getViewDoc(item.id || '1')} className='clicked-item hovered-icon'>
                        <span className="show-desktop">
                          <Eye />
                        </span>
                        <span className="show-mobile">
                          <EyeMobile />
                        </span>
                        <div className='action-text'>View</div>
                      </div>

                      <div onClick={getDownload(item.id || '1', `invoice_${item.name}`)} className='clicked-item hovered-icon'>
                        <span className="show-desktop">
                          <Download />
                        </span>
                        <span className="show-mobile">
                          <DownloadMobile />
                        </span>
                        <div className='action-text'>Download</div>
                      </div>
                      
                      <div onClick={getPrint(item.id || '1')} className='clicked-item hovered-icon'>
                        <span className="show-desktop">
                          <Print />
                        </span>
                        <span className="show-mobile">
                          <PrintMobile />
                        </span>
                        <div className='action-text'>Print</div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
              : invoices
                ? <tr style={{ width: '100%', textAlign: 'center' }}>
                  <td className="no-data" colSpan={4} style={{ height: 150, borderRight: 0 }}>Invoices were not found.</td>
                </tr>
                : null
          }
        </tbody>
      </table>
    </div>
  )
}

const CardsScreen = ({ dispatch, history, invoices, steatments, cards, invoiceDownloadData, cardsProducts, statementsData, invoicesData }) => {
  const [active, setActive] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [currentCard, setCurrentCard] = useState(0);
  const [currentMobileState, setCurrentMobileState] = useState('');

  const refs = cards.reduce((acc, card) => {
    acc[card.id] = React.createRef();
    return acc;
  }, {});
  useEffect(() => {
    dispatch(
      getCardsAll()
    )
    dispatch(
      getCardsLatest()
    )
    if (history.location?.state?.item) {
      setActive(history.location.state.item)
      setActiveIndex(history.location.state.item)
      refs[history.location.state.item.id]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [])

  const handleManageCard = (item, index) => {
    localStorage.setItem('selectedCardIndex', index)
    localStorage.setItem('selectedCard', JSON.stringify(item))
    history.push('/manage-cards', { item, index, cardOptions: true })
  }

  useEffect(() => {
    if (cardsProducts.length === 0) {
      dispatch(
        getCardsProducts()
      )
    }
  }, [])

  const handleClick = (value, index) => {
    setActiveIndex(index)
    if (active) {
      setActive(null)
    } else {
      setActive(value)
    }
  };

  const renderCardNumber = number => {
    const result = []
    if (number) {
      for (let i = 0; i < number.length; i++) {
        if (i % 4 === 0) {
          result.push(' ')
          result.push(number[i])
        } else {
          result.push(number[i])
        }
      }
      return result.join('')
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (history.location.state) {
      setActive(history.location.state.selectedCard)
    } else {
      setActive(null);
    }
  }, [])

  const renderBackgroundImg = (item) => {
    let bg = '';

    for (let i = 0; i < cardsProducts.length; i++) {
      if (cardsProducts[i].id === item.productId) {
        bg = cardsProducts[i].images?.front
      }
    }

    return `${baseURL}/${bg}`
  }

  const getColor = (item) => {
    let color = '#ffffff';

    for (let i = 0; i < cardsProducts.length; i++) {
      if (cardsProducts[i].id === item.productId) {
        color = cardsProducts[i].textColor
      }
    }
    return color
  }

  const renderOneCard = (item) => {
    return (
      <div
          className={classNamesBind('card-item')}
          style={{ backgroundImage: `url(${renderBackgroundImg(item)})` }}
      >
          <div className='currency due-space'>
              <div style={{ color: getColor(item) }}>{item.currency}</div>
          </div>

          <p
              className='card-item-number'
              style={{ color: getColor(item) }}
          >
              {renderCardNumber(item.cardNumber)}
          </p>

          <p
              className='card-item-number'
              style={{ color: getColor(item) }}
          >
              {item.cardName}
          </p>
      </div>
    );
  };

  const renderCardInner = (item, index) => {
    return (
      <div className={`cards-page-card-wrapper ${active?.id === item?.id ? 'card-active' : undefined}`} key={index} id={item.id} ref={refs[item.id]}>
        <div>
          {renderOneCard(item)}

          {/* <div className={classNamesBind('card-item')} style={{ backgroundImage: `url(${renderBackgroundImg(item)})` }}>
            <div className='currency due-space'>
              <div style={{ color: getColor(item) }}>{item.currency}</div>
            </div>
            <p className='card-item-number' style={{ color: getColor(item) }}>{renderCardNumber(item.cardNumber)}</p>
            <p className='card-item-number' style={{ color: getColor(item) }}>{item.cardName}</p>
          </div> */}
          {/* !!!!!!! */}
          <div className='cards-page-right-block'>
            {active?.id === item?.id &&
              <Button buttonStyles={{ marginBottom: 37, width: '200px' }} text="Manage Card" onClick={() => {
                handleManageCard(item, index);
              }} />

            }
            <div className='link-to' onClick={() => handleClick(item, index)}>
              {
                item.paymentDue
                  ? <div>
                    <span className='sign'>!</span>
                    <span>Payment due on {moment(item.paymentDue || new Date()).format('DD MMM YYYY')}</span>
                  </div>
                  : <span style={{ color: '#4c4c4c' }}>Invoices and Statements</span>
              }

              <i className='payment-arrow' />
            </div>
            <div className='statements'>
              <div>
                Statement Balance: <span className={item.statementBalance <= 0 ? 'text-red' : undefined}>{`${item.currency} ${(insertSpaces(item.statementBalance)) || '57,383'}`}</span>
              </div>
              <div>
                New Charges: <span>{`${item.currency} ${insertSpaces(item.newCharges) || '1,028,438'}`}</span>
              </div>
              <div>
                New Credits: <span>{`${item.currency} ${insertSpaces(item.newCredits) || '1,030,511'}`}</span>
              </div>
              <div>
                Previous Balance: <span className={item.previousBalance <= 0 ? 'text-red' : undefined}>{`${item.currency} ${(insertSpaces(item.previousBalance)) || '59,463'}`}</span>
              </div>
            </div>
          </div>
        </div>
        {active?.id === item?.id &&
          <Tabs className='tabs'>
            <div className='tabList'>
              <TabList className='tabList-tabs'>
                <Tab className='tabList-tab' selectedClassName='tabList-tab-active'>Invoices</Tab>
                <Tab className='tabList-tab' selectedClassName='tabList-tab-active'>Statements</Tab>
              </TabList>
            </div>

            <TabPanel className={'tabPanel'}>
              {getCurrentInvoices(item)}
              {/* <TableInvoices item={item} dispatch={dispatch} invoices={invoices} handleViewClick={() => setViewMode(true)} /> */}
            </TabPanel>

            <TabPanel>
              {getCurrentStatements(item)}
              {/* <TableStatements item={item} dispatch={dispatch} statements={steatments} handleViewClick={() => setViewMode(true)} /> */}
            </TabPanel>
          </Tabs>
        }
      </div>
    );
  }

  const beforeChange = (old, newIndex) => {
    setCurrentCard(newIndex);
  }

  const renderMobileCard = (item, key) => {
    return (
      <div
        id={key}
        key={key}
        className="cards-list-mobile--item"
      >
        <div className="cards-list-mobile--item-inner">
          {renderOneCard(item)}
        </div>
      </div>
    );
  }

  const getCurrentInvoices = (item) => {
    const currentItem = item ? item : cards[currentCard] || cards[0];

    if (!currentItem) {
      return '';
    }

    return (
      <div>
        {/* <div onClick={() => setCurrentMobileState('')}>BACK</div> */}
        <h2 className="cards-header cards-header-invoices">Invoices</h2>

        <TableInvoices
          item={currentItem}
          dispatch={dispatch}
          invoices={invoices}
          handleViewClick={() => setViewMode(true)}
        />
      </div>
    );
  }

  const getCurrentStatements = (item) => {
    const currentItem = item ? item : cards[currentCard] || cards[0];

    if (!currentItem) {
      return '';
    }

    return (
      <div>
        {/* <div onClick={() => setCurrentMobileState('')}>BACK</div> */}
        <h2 className="cards-header cards-header-statements">Statements</h2>

        <TableStatements
          item={currentItem}
          dispatch={dispatch}
          statements={steatments}
          handleViewClick={() => setViewMode(true)}
        />
      </div>
    );
  }

  const renderCurrentCard = () => {
    const currentItem = cards[currentCard] || cards[0];

    if (!currentItem) {
      return '';
    }

    return (
      <div className="cards-list-mobile--card-detail">
        <div className="cards-list-mobile--card-balance">
            {
              currentItem.paymentDue
                ? 
                <div className='badge-due-date'><span onClick={() => setCurrentMobileState('invoices')}>
                  Payment due on {moment(currentItem.paymentDue || new Date()).format('DD MMM YYYY')}
                  </span></div>
                : ''
            }
          
          <div>
            <span>Statement Balance:</span>
            <span className={currentItem.statementBalance <= 0 ? 'text-red' : undefined}>{currentItem.currency} <b>{(insertSpaces(currentItem.statementBalance)) || '57,383'}</b></span>
          </div>

          <div>
            <span>New Charges:</span> <span>{currentItem.currency} <b>{insertSpaces(currentItem.newCharges) || '1,028,438'}</b></span>
          </div>

          <div>
            <span>New Credits:</span> <span>{currentItem.currency} <b>{insertSpaces(currentItem.newCredits) || '1,030,511'}</b></span>
          </div>

          <div>
            <span>Previous Balance:</span> <span className={currentItem.previousBalance <= 0 ? 'text-red' : undefined}>{currentItem.currency} <b>{(insertSpaces(currentItem.previousBalance)) || '59,463'}</b></span>
          </div>
        </div>

        <div className="cards-list-mobile--card-actions">
          <div className="cards-button cards-button-manage">
            <Button text={'Manage the Card'} onClick={() => {
                handleManageCard(currentItem, currentCard);
              }} />
          </div>

          <div className="cards-button cards-button-invoices">
            <Button text={'Invoices'} onClick={() => setCurrentMobileState('invoices')} />
          </div>

          <div className="cards-button cards-button-statements">
            <Button text={'Statements'} onClick={() => setCurrentMobileState('statements')} />
          </div>
        </div>
      </div>
    );
  }

  if (cards) {
    return (
      <div className={`cards-page ${active ? 'active-wrapper' : undefined}`}>
        <div className="show-mobile">
          { currentMobileState == '' &&
            <div className="cards-list-mobile">
              <div className="cards-list-mobile-list">
                <Slider
                  {...{ ...settings, slidesToShow: 1, slidesToScroll: 1, className: 'card'}}
                  beforeChange={beforeChange}
                  ref={slickSliderRef}
                >
                  {cards.length && cards.map(renderMobileCard)}
                </Slider>

                <ul className="cards-list-mobile-dots">
                  {cards.map((item, index) => {
                    return (
                      <li className={currentCard == index ? 'active' : ''}></li>
                    )
                  })}
                </ul>
              </div>

              <div>
                {renderCurrentCard()}
              </div>
            </div>
          }

          { currentMobileState === 'invoices' && getCurrentInvoices()}

          { currentMobileState === 'statements' && getCurrentStatements()}
        </div>

        <div className="show-desktop">
          {cards.map(renderCardInner)}
        </div>

        <MessagePopUp
            pdfDoc={statementsData || invoicesData}
            pdfType={statementsData ? 'Statement' : 'Invoice'}
            onClose={() => dispatch(resetData())}
        >
            <div/>
        </MessagePopUp>
        {/*}
        {
          statementsData && viewMode
            ? <Modal
              modalOpen={!!statementsData}
              title='invoice'
              content={
                <div dangerouslySetInnerHTML={{ __html: statementsData }} style={{ width: '100%', height: 551 - 129 }} />
                // <iframe title='pdf' src={URL.createObjectURL(statementsData)} width={'100%'} height={551-129} />
              }
              buttons={[{ text: 'Ok', onClick: () => dispatch(resetData()) }]}
              onClose={() => {
                setViewMode(false);
                dispatch(resetData());
              }}
            />
            : null
        }
        {
          invoicesData && viewMode
            ? <Modal
              modalOpen={!!invoicesData}
              title='statement'
              content={
                <div dangerouslySetInnerHTML={{ __html: invoicesData }} style={{ width: '100%', height: 551 - 129 }} />
                // <iframe title='pdf' src={URL.createObjectURL(invoicesData)} width={'100%'} height={551-129} />
              }
              buttons={[{ text: 'Ok', onClick: () => dispatch(resetData()) }]}
              onClose={() => {
                setViewMode(false);
                dispatch(resetData());
              }}
            />
            : null
        } */}

      </div>
    )
  } else {
    return <Preloader />
  }
}

const mapStateToProps = state => ({
  cards: state.cards.cardsAll || [],
  invoices: state.cards.invoices || [],
  steatments: state.cards.steatments || [],
  invoiceDownloadData: state.cards.invoiceDownloadData,
  cardsProducts: state.cards.cardsProducts || [],
  statementsData: state.cards.statementsData,
  invoicesData: state.cards.invoicesData,
})

export default connect(mapStateToProps)(withRouter(screenHOC(CardsScreen)))
