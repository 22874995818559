import { Button } from "../../../components/Button";

const PHONE = '+420458965896';
const PHONE_FORMATTED = '+420-4589-65-896';

export const Error = ({onAction}) => {
  return (
    <div className="add-money">
      <section className="add-money__section">
        <h2 className="add-money__small-title">Something went wrong</h2>      
        <div>
          <p>Please contact our support team</p>
          <p>if you have any questions</p>
          <p>tel.: <a href={`tel:${PHONE}`}>{PHONE_FORMATTED}</a></p>
        </div>
      </section>
      <div className="add-money__actions">
        <Button className="add-money__action" text="Try again" onClick={onAction} />
      </div>
    </div>
  )
}