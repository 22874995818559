import Axios from "axios";
import { put } from "redux-saga/effects"
import { STARTUP } from "../constants"

export function* startUpSaga() {
  try {
    const stuff = JSON.parse(localStorage.getItem('stuff'))
    if (stuff?.token) {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${stuff?.token}`;
    }
    yield put({ type: STARTUP, payload: stuff });
  } catch (error) {
    console.log(error);
  }
}