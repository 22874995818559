import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import saga from './sagas/'

import reducers from './reducers'
import middleware, { sagaMiddleware } from './middleware'

const store =  createStore(
  combineReducers({
    ...reducers,
  }),
  composeWithDevTools(applyMiddleware(...middleware))
)

sagaMiddleware.run(saga)

export default store