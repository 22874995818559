import React from 'react';
import classNames from 'classnames';

import { formatMoney } from '../../../utils/format';

import { Button } from '../../../components/Button';
import { Preloader } from '../../../components/Preloader';

import visa from '../visa.svg'

export const Form = ({loading, onPost, cardNumber, amount, onChange, currency, finalAmount, finalFee}) => {
  
  return (
    <div className={classNames('add-money', loading ? 'add-money_loading' : null)}>
      {loading ? <Preloader preloaderOpacity={.95} top /> : null}
      <div className="add-money__fields">
        <div className="add-money__field">
          <label htmlFor="To" className="add-money__label">To</label>
          <span className="add-money__input add-money__input_label">
            <img className="add-money__visa" src={visa} alt="" />
            {cardNumber}
          </span>        
        </div>    
        <div className="add-money__field">
            <label htmlFor="Amount" className="add-money__label">Amount</label>
            <div className="add-money__input-container">
              <input
                  id="Amount"
                  className="add-money__input"
                  autoFocus
                  required
                  value={amount}
                  onChange={onChange}
              />
              <div className="add-money__input-measurer-container">
                <div className="add-money__input-measurer">
                  <div className="add-money__input-measurer-currency">{currency}</div>
                  {amount}
                </div>
              </div>
            </div>
        </div>
        <div className={classNames('add-money__summary', finalAmount ? 'add-money__summary_visible' : null)}>
          <div className="add-money__summary-row">
            <span className="add-money__summary-key">Fee {currency}{formatMoney(+finalFee)}</span>
          </div>
          <div className="add-money__summary-row">
            <span className="add-money__summary-key">Final amount</span>
            <span className="add-money__summary-value"> {currency}{formatMoney(+finalAmount)}</span>
          </div>
        </div>
      </div>
      <div className="add-money__actions">
        <Button className="add-money__action" text="Next" onClick={onPost} />
      </div>
    </div>
  );
}