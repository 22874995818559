import { Button } from "../../../components/Button";
import { formatMoney } from "../../../utils/format";

export const Success = ({currency, amount, fee, finalAmount, finalFee, onAction}) => {
  return (
    <div className="add-money">
      <section className="add-money__section">
        <h2 className="add-money__small-title">Payment was successful</h2>
        <div className="add-money__receipt">
          <div className="add-money__receipt-line">
            <span>Amount</span>
            <span>{currency}{formatMoney(+amount)}</span>
          </div>
          <div className="add-money__receipt-line">
            <span>{fee}% fee</span>
            <span>{currency}{formatMoney(+finalFee)}</span>
          </div>
          <hr/>
          <div className="add-money__receipt-total">
            <span>Total</span>
            <span>{currency}{formatMoney(+finalAmount)}</span>
          </div>
        </div>
      </section>
      <div className="add-money__actions">
        <Button className="add-money__action" text="OK" onClick={onAction} />
      </div>
    </div>
  )
}