import React, { useEffect } from 'react';
import './styles.css';
import contact from '../../assets/images/contact/contact.png';
import phone from '../../assets/images/contact/phone.svg';
import mail from '../../assets/images/contact/mail.svg';
import location from '../../assets/images/contact/location.svg';
import phoneMobile from '../../assets/images/contact/phone_mobile.svg';
import mailMobile from '../../assets/images/contact/mail_mobile.svg';
import locationMobile from '../../assets/images/contact/location_mobile.svg';
import {screenHOC} from '../HOC/screenHOC';
import { getContentTextContactAction } from '../../redux/actions/contentActions';
import { connect } from 'react-redux';

const Contact = ({dispatch, contacts}) => {
    useEffect(() => {
        dispatch(
            getContentTextContactAction()
        )
    }, [])
    return (
        <div className="wrapper wrapper-contacts">
            <div className="info">
                <div className="info-wrapper">
                    <div className="info-block">
                        <div className="info-block-line-title">
                            <div className="line-block">
                                <div className="line" />
                            </div>
                            <h1 className="info-block-title">Contact</h1>
                        </div>
                        {/* <div dangerouslySetInnerHTML={{__html: contacts}} /> */}
                        <div className="info-block-items">
                            <div className="info-block-item">
                                <div className="info-block-item-icon-block">
                                    <img src={ phone } alt="icon" className="info-block-item-icon show-desktop"/>
                                    <img src={ phoneMobile } alt="icon" className="info-block-item-icon show-mobile"/>
                                </div>
                                <div className="info-block-item-text">
                                    Call us: {contacts?.phone}
                                </div>
                            </div>
                            <div className="info-block-item">
                                <div className="info-block-item-icon-block">
                                    <img src={ mail } alt="icon" className="info-block-item-icon show-desktop"/>
                                    <img src={ mailMobile } alt="icon" className="info-block-item-icon show-mobile"/>
                                </div>
                                <div className="info-block-item-text">
                                    Email us: {contacts?.email}
                                </div>
                            </div>
                            <div className="info-block-item">
                                <div className="info-block-item-icon-block">
                                    <img src={ location } alt="icon" className="info-block-item-icon show-desktop"/>
                                    <img src={ locationMobile } alt="icon" className="info-block-item-icon show-mobile"/>
                                </div>
                                <div className="info-block-item-text">
                                    {contacts?.address}
                                    {/* <span>410 Des Voeux Road West,</span>
                                    <span>Suite 2302-3 Pacific Plaza,</span>
                                    <span>Hong Kong</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-block">
                <img src={ contact } alt="image" className="image" />
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    contacts: state.content.contact
})

export default connect(mapStateToProps)(screenHOC(Contact))
