import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  getAllInvoicesCard,
  getAllStatementsCard,
  getCardsAll,
  getCardsLatest,
  getCardSteatmentsView,
  getInvoicesCard,
  getInvoicesDownload,
  getInvoicesPrint,
  getInvoicesStatemens,
  getInvoicesStatemensDownload,
  getInvoicesStatemensPrint,
  applyCard,
  manageCardName,
  viewPin,
  changePin,
  lostCard,
  blockCard,
  getCardInvoicesView,
  getProducts,
  postDisputeTransactions,
  getCard3ds,
  setCard3ds,
} from '../../api'
import {
  GET_ALL_INVOICES_CARD,
  GET_ALL_STATEMENS_CARD,
  GET_CARDS_ALL,
  GET_CARDS_LATEST,
  GET_INVOICES_CARD,
  GET_INVOICES_DOWNLOAD,
  GET_INVOICES_PRINT,
  GET_INVOICES_STATEMENS,
  GET_INVOICES_STATEMENS_DOWNLOAD,
  GET_INVOICES_STATEMENS_PRINT,
  APPLY_CARD,
  MANAGE_CARD_NAME,
  VIEW_PIN,
  CHANGE_PIN,
  LOST_CARD,
  BLOCK_CARD,
  GET_CARD_STEATMENTS_VIEW,
  GET_CARD_INVOICES_VIEW,
  GET_CARDS_PRODUCTS,
  RESET_CARDS_ERRORS,
  POST_DISPUTE_TRANSACTIONS_SUCCESS,
  POST_DISPUTE_TRANSACTIONS,
  SET_CARD_3DS,
  GET_CARD_3DS,  
} from '../constants';

function* getCardsProductsSaga() {
  try {
    const response = yield call(getProducts);
    yield put({ type: `${GET_CARDS_PRODUCTS}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CARDS_PRODUCTS}_FAILED`, payload: error.response });
  }
}

function* getCardsAllSaga() {
  try {
    const response = yield call(getCardsAll);
    yield put({ type: `${GET_CARDS_ALL}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CARDS_ALL}_FAILED`, payload: error.response });
  }
}

function* getCardsLatestSaga() {
  let response
  try {
    response = yield call(getCardsLatest);
    yield put({ type: `${GET_CARDS_LATEST}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CARDS_LATEST}_FAILED`, payload: error.response });
  } 
}


function* getInvoicesCardSaga({payload}) {
  try {
    const response = yield call(getInvoicesCard, payload);
    yield put({ type: `${GET_INVOICES_CARD}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_CARD}_FAILED`, payload: error.response });
  }
}

function* getAllInvoicesCardSaga({payload}) {
  try {
    const response = yield call(getAllInvoicesCard, payload);
    yield put({ type: `${GET_INVOICES_CARD}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_CARD}_FAILED`, payload: error.response });
  }
}

function* getAllStatementsCardSaga({payload}) {
  try {
    const response = yield call(getAllStatementsCard, payload);
    yield put({ type: `${GET_INVOICES_STATEMENS}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_CARD}_FAILED`, payload: error.response });
  }
}

function* getInvoicesStatemensSaga({payload}) {
  try {
    const response = yield call(getInvoicesStatemens, payload);
    yield put({ type: `${GET_INVOICES_STATEMENS}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_STATEMENS}_FAILED`, payload: error.response });
  }
}

function* getInvoicesDownloadSaga({payload}) {
  try {
    const response = yield call(getInvoicesDownload, payload.id);
    console.log(response);
    yield put({ type: `${GET_INVOICES_DOWNLOAD}_SUCCESS`, payload: {response, name: payload.name, reset: payload.reset} });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_DOWNLOAD}_FAILED`, payload: error.response });
  }
}

function* getInvoicesPrintSaga({payload}) {
  try {
    const response = yield call(getInvoicesPrint, payload);
    console.log(response);
    yield put({ type: `${GET_INVOICES_PRINT}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_PRINT}_FAILED`, payload: error.response });
  }
}

function* getInvoicesStatemensDownloadSaga({payload}) {
  try {
    const response = yield call(getInvoicesStatemensDownload, payload.id);
    yield put({ type: `${GET_INVOICES_STATEMENS_DOWNLOAD}_SUCCESS`, payload: {response, name: payload.name, reset: payload.reset} });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_STATEMENS_DOWNLOAD}_FAILED`, payload: error.response });
  }
}

function* getInvoicesStatemensPrintSaga({payload}) {
  try {
    const response = yield call(getInvoicesStatemensPrint, payload);
    yield put({ type: `${GET_INVOICES_STATEMENS_PRINT}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_INVOICES_STATEMENS_PRINT}_FAILED`, payload: error.response });
  }
}


function* manageCardNameSaga({payload}) {
  try {
    const response = yield call(manageCardName, payload);
    console.log(response);
    if (response.response) {
      yield put({ type: `${MANAGE_CARD_NAME}_FAILED`, payload: response.response });
    } else {
      yield put({ type: `${MANAGE_CARD_NAME}_SUCCESS`, payload: response });
    }
  } catch (error) {
    yield put({ type: `${MANAGE_CARD_NAME}_FAILED`, payload: error.response });
  }
}

function* viewPinSaga({payload}) {
  try {
    const response = yield call(viewPin, payload);
    if (response.response) {
      yield put({ type: `${VIEW_PIN}_FAILED`, payload: response.response });
    } else {
      yield put({ type: `${VIEW_PIN}_SUCCESS`, payload: response });
    }
  } catch (error) {
    yield put({ type: `${VIEW_PIN}_FAILED`, payload: error.response });
  }
}

function* changePinSaga({payload}) {
  try {
    const response = yield call(changePin, payload);
    if (response.response) {
      yield put({ type: `${CHANGE_PIN}_FAILED`, payload: response.response });
    } else {
      yield put({ type: `${CHANGE_PIN}_SUCCESS`, payload: response });
    }
  } catch (error) {
    yield put({ type: `${CHANGE_PIN}_FAILED`, payload: error.response });
  }
}

function* lostCardSaga({payload}) {
  try {
    const response = yield call(lostCard, payload);
    if (response.response) {
      yield put({ type: `${LOST_CARD}_FAILED`, payload: response.response });
    } else {
      yield put({ type: `${LOST_CARD}_SUCCESS`, payload: response });
    }
  } catch (error) {
    yield put({ type: `${LOST_CARD}_FAILED`, payload: error.response });
  }
}

function* blockCardSaga({payload}) {
  try {
    const response = yield call(blockCard, payload);
    if (response.response) {
      yield put({ type: `${BLOCK_CARD}_FAILED`, payload: response.response });
    } else {
      yield put({ type: `${BLOCK_CARD}_SUCCESS`, payload: response });
    }
  } catch (error) {
    yield put({ type: `${BLOCK_CARD}_FAILED`, payload: error.response });
  }
}
// --------------------------------------------------------






function* applyCardSaga({payload}) {
  try {
    const response = yield call(applyCard, payload);
    console.log(response);
    yield put({ type: `${APPLY_CARD}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${APPLY_CARD}_FAILED`, payload: error.response });
  }
}

function* getCardSteatmentsViewSaga({payload}) {
  try {
    const response = yield call(getCardSteatmentsView, payload);
    yield put({ type: `${GET_CARD_STEATMENTS_VIEW}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CARD_STEATMENTS_VIEW}_FAILED`, payload: error.response });
  }
}

function* getCardInvoicesViewSaga({payload}) {
  try {
    const response = yield call(getCardInvoicesView, payload);
    yield put({ type: `${GET_CARD_INVOICES_VIEW}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_CARD_INVOICES_VIEW}_FAILED`, payload: error.response });
  }
}

function * handlePostDisputeTransactions({payload}) {
  try {
    const result = yield call(postDisputeTransactions, payload);
    yield put({ type: `${POST_DISPUTE_TRANSACTIONS_SUCCESS}`});
  } catch(error) {
    console.log('error', error);
  }
}

function * handleGetCard3ds({payload}) {
  try {
    const result = yield call(getCard3ds, payload);
    yield put({type: `${GET_CARD_3DS}/set`, payload: result.data});
  } catch (error) {
    if (error.response?.data) {
      yield put({type: `${GET_CARD_3DS}/error`, payload: error.response.data.title || 'Unknown error'});
    }
    console.log('error', error); 
  }
}

function * handleSetCard3ds({payload}) {
  try {
    const result = yield call(setCard3ds, payload);
    yield put({ type: `${SET_CARD_3DS}/success`});
  } catch (error) {
    if (error.response?.data) {
      yield put({type: `${SET_CARD_3DS}/error`, payload: error.response.data.title || 'Unknown error'});
    }
    console.log('error', error);
  }
}

export function* cardsActionWatcher() {
  yield takeLatest(`${GET_CARDS_PRODUCTS}_REQUEST`, getCardsProductsSaga)
  yield takeLatest(`${GET_CARDS_ALL}_REQUEST`, getCardsAllSaga)
  yield takeLatest(`${GET_CARDS_LATEST}_REQUEST`, getCardsLatestSaga)
  yield takeLatest(`${GET_INVOICES_CARD}_REQUEST`, getInvoicesCardSaga)
  yield takeLatest(`${GET_ALL_INVOICES_CARD}_REQUEST`, getAllInvoicesCardSaga)
  yield takeLatest(`${GET_INVOICES_DOWNLOAD}_REQUEST`, getInvoicesDownloadSaga)
  yield takeLatest(`${GET_INVOICES_PRINT}_REQUEST`, getInvoicesPrintSaga)
  yield takeLatest(`${GET_INVOICES_STATEMENS}_REQUEST`, getInvoicesStatemensSaga)
  yield takeLatest(`${GET_ALL_STATEMENS_CARD}_REQUEST`, getAllStatementsCardSaga)
  yield takeLatest(`${GET_INVOICES_STATEMENS_DOWNLOAD}_REQUEST`, getInvoicesStatemensDownloadSaga)
  yield takeLatest(`${GET_INVOICES_STATEMENS_PRINT}_REQUEST`, getInvoicesStatemensPrintSaga)
  yield takeLatest(`${APPLY_CARD}_REQUEST`, applyCardSaga)
  yield takeLatest(`${MANAGE_CARD_NAME}_REQUEST`, manageCardNameSaga)
  yield takeLatest(`${VIEW_PIN}_REQUEST`, viewPinSaga)
  yield takeLatest(`${CHANGE_PIN}_REQUEST`, changePinSaga)
  yield takeLatest(`${LOST_CARD}_REQUEST`, lostCardSaga)
  yield takeLatest(`${BLOCK_CARD}_REQUEST`, blockCardSaga)
  yield takeLatest(`${GET_CARD_STEATMENTS_VIEW}_REQUEST`, getCardSteatmentsViewSaga)
  yield takeLatest(`${GET_CARD_INVOICES_VIEW}_REQUEST`, getCardInvoicesViewSaga)
  yield takeLatest(`${POST_DISPUTE_TRANSACTIONS}`, handlePostDisputeTransactions);
  yield takeLatest(`${GET_CARD_3DS}/get`, handleGetCard3ds);
  yield takeLatest(`${SET_CARD_3DS}/post`, handleSetCard3ds);
}
