import React, {Component} from 'react';
import PropTypes from 'prop-types';

const CAN_USE_DOM = typeof window !== 'undefined';

if (CAN_USE_DOM) {
  window.dataLayer = window.dataLayer || [];
}

function gtag(){
  if (CAN_USE_DOM) {
    window.dataLayer.push(arguments);
  }
}

if (CAN_USE_DOM) {
  window.gtag = gtag;
}

function install(id) {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
  document.head.appendChild(script);
}

export default class Google extends Component {
  constructor(props) {
    super(props);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }
  componentDidMount() {
    this._unlisten = this.props.history.listen(this.handleLocationChange);
    if (CAN_USE_DOM) {
      install(this.props.trackingId);
      gtag('js', new Date());
      gtag('config', this.props.trackingId);
    }
  }
  componentWillUnmount() {
    this._unlisten();
  }
  handleLocationChange(e) {
    gtag('config', this.props.trackingId, {'page_path': e.pathname});
    // gtag('event', 'page_view', { 'send_to': 'GA_MEASUREMENT_ID' });
  }
  render() {
    return null;
  }
};

Google.propTypes = {
  history: PropTypes.object.isRequired,
  trackingId: PropTypes.string.isRequired
};

