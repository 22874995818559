import React from 'react';
import classNames from 'classnames';

import './Select.css';

export default function Select({className, caption, name, value, onChange, children}) {
  return (
    <label className={classNames('select', className)}>
      {caption ? <span className="select__caption">{caption}</span> : null}
      <div className="select__wrapper">
        <select className="select__control" name={name} value={value} onChange={onChange}>
          {children}
        </select>
      </div>
    </label>
  )
}