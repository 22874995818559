import { useCallback, useRef } from 'react';
import classNames from 'classnames/bind';
import classes from './TableHeader.scss';

const cx = classNames.bind(classes)

export const TableHeader = ({name, value, onChange, open, onToggle, children}) => {
  const refToggle = useRef(false);
  const refInput = useCallback((control) => {
    if (refToggle.current) {
      refToggle.current = false;
      if (control) control.focus();
    }    
  }, [refToggle]);
  const handleToggle = useCallback(() => {
    refToggle.current = true;
    onToggle && onToggle();
  }, [onToggle, refToggle]);
  const handleChange = useCallback((e) => {
    const { name, value } = e.currentTarget;
    if (onChange) onChange((current) => {
      const result = {...current};      
      result[name] = value;
      return result;
    });
  }, [onChange]);
  return (
    <div className={cx('table-header')}>
      <div className={cx('table-header__title')}>
        {children}
        <span className={cx('table-header__filter-button')} onClick={handleToggle} />
      </div>
      {open ? <div className={cx('table-header__filter')}>
        <input className={cx('table-header__filter-control')} ref={refInput} name={name} value={value || ''} onChange={handleChange} />
      </div> : null}
    </div>
  );
};
