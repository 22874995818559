import React, {Component, createRef} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal } from '../../components/Modal'
import { screenHOC } from '../HOC/screenHOC'
import './profile.css';
import EditIcon from '../../assets/images/profile/edit-01.svg';
import InputText from '../../components/InputText/InputText';
import { connect } from 'react-redux';
import { getProfileAction, postProfileAction, changePasswordAction } from '../../redux/actions/profileActions';
import { Button } from '../../components/Button';
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';

const input = [
  {name: '', key: 'userName', modal: true, edit: true, type: 'username'},
  {name: '', key: 'name'},
  {name: '', key: 'surname'},
  {name: '', key: 'email', modal: true, edit: true, type: 'email'},
  {name: '', key: 'phoneNumber'},
  {name: '', key: 'changePassword', modal: true, edit: true, type: 'password'},
];

const defaultButtonsStyles = {
  width: '129px',
  height: '40px',
  borderRadius: '8px',
  border: 'solid 1px #000000',
}
class Profile extends Component {
  constructor (props) {
    super(props);
    this.state = {
      userName: '',
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      changePassword: '',
      userNameError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      content: null,
      buttons: null,
      contentWrapperStyle: null,
      modalError: '',
      wrapperClassname: 'profile-modal-block',
    }
  }

  userNameRef = createRef();
  nameRef = createRef();
  surnameRef = createRef();
  emailRef = createRef();
  phoneNumberRef = createRef();
  changePasswordRef = createRef();

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(
      getProfileAction()
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.changePasswordSuccess !== prevProps.changePasswordSuccess && this.props.changePasswordSuccess === undefined) {
      this.setState({
        modalError: <div className={'auth-error auth-input-error'}>
        <div className="auth-error-i">!</div>
        <div className="auth-error-t">SOMETHING WENT WRONG. PLEASE TRY AGAIN OR CONTACT CUSTOMER SERVICE.</div>
      </div>


      })
    }
    if (this.props.changePasswordSuccess !== prevProps.changePasswordSuccess && !isNil(this.props.changePasswordSuccess)) {
      const modalTitle = 'Change Password'
      const text = 'Your password has been successfully changed'
      this.setState({
          buttons: null,
          modalTitle,
          modalText: text,
          content: <div className='modalSuccesfulFormSave-wrap'>
            <img src='../../assets/images/password-ok.svg' alt='' style={{marginBottom: 25}} />
            <p className='modalSuccesfulFormSave-text'>{text}</p>
          </div>,
          // contentWrapperStyle: {marginTop: 64},
          'input-1': null,
          'input-2': null,
          'input-3': null,
          wrapperClassname: 'profile-modal-block recovery-custom-modal login-success-modal pass-success-modal',
        })
    }
  }


  renderModalInput = (label, rightLabel, type, isCompare, id) => {
    return <div className='modal-input'>
      <div>
        {
          label && <span>{label}</span>
        }
        {
          rightLabel && <span>{rightLabel}</span>
        }
      </div>
      <input type='password' value={this.state[`input-${id}`]} onChange={(e) => {
        if (
            id === 3 &&
            isCompare &&
            e.target.value.length >= this.state[`input-${id - 1}`]?.length &&
            e.target.value !== this.state[`input-${id - 1}`]
          ) {
          this.setState({
            modalError: <div className={'auth-error auth-input-error'}>
            <div className="auth-error-i">!</div>
            <div className="auth-error-t">{`${this.state.type} does not match`}</div>
          </div>
          })
        } else if (
          (id === 2 || id === 3) &&
          (
            e.target.value.length < 8 &&
            this.state[`input-${id - 1}`]?.length < 8
          )
        ) {
          this.setState({
            modalError: null,
            modalLengthError: <div className={'auth-error auth-input-error'}>
            <div className="auth-error-i">!</div>
            <div className="auth-error-t">8 characters minimum</div>
          </div>
          })
        } else {
          this.setState({
            modalError: null,
            modalLengthError: null
          })
        }
        this.setState({[`input-${id}`]: e.target.value})
      }} />
    </div>
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    switch(fieldName) {
      case 'userName':
        if (value.length < 1) {
          text = 'incorrect username';
          error = true
        }
        break;
    }

    this.setState({errorValidate: error, [fieldName + 'Error']: text});

    return !error; //lk
  }

  sendForm = () => {
    this.props.dispatch(
      postProfileAction({
        userName: this.userNameRef.value,
        name: this.nameRef.value,
        surname: this.surnameRef.value,
        email: this.emailRef.value,
        phoneNumber: this.phoneNumberRef.value,
      })
    )
    this.setState({
      modal: true,
      content: <div className="profile-modal"><p>Your profile changes have been updated successfully </p></div>,
      wrapperClassname: 'profile-modal-block recovery-custom-modal login-success-modal',
    })
  }

  handleCloseModalForm = () => {
    this.setState({
      modalError: null,
      modalLengthError: null,
      modal: false,
      content: null,
      'input-1': null,
      'input-2': null,
      'input-3': null,
      passwordIncorrect: false
    })
  }

  handleSaveModalForm = (type) => () => {
    let modalTitle = ''
    let text = ''
    if (!this.state.modalError && !this.state.passwordIncorrect) {
      switch (type) {
        case 'password':
          modalTitle = 'Change Password'
          text = 'Your password has been successfully changed'
          if (
            this.state[`input-1`] &&
            this.state[`input-2`] &&
            this.state[`input-3`] &&
            this.state[`input-1`] !== this.state[`input-2`] &&
            this.state[`input-2`] === this.state[`input-3`]
          ) {
            this.props.dispatch(
              changePasswordAction({
                currentPassword: this.state[`input-1`],
                newPassword: this.state[`input-2`],
                newPassswordConfirm: this.state[`input-3`],
              })
            )
            this.setState({
              passwordIncorrect: false,
              wrapperClassname: 'profile-modal-block recovery-custom-modal login-success-modal',
            })
          } else {
            this.setState({
              passwordIncorrect: true,
              modalError: <div className={'auth-error auth-input-error form-error-block'}>
                <div className="auth-error-i">!</div>
                <div className="auth-error-t">{`Please fill all mandatory fields`}</div>
              </div>
            })
          }
          break;
        default:
          break;
      }
    }
  }

  renderModalError = (type) => {
      if (this.state.modalError) {
        return this.state.modalError
      } else if (this.state.modalLengthError) {
        return this.state.modalLengthError
      } else if (this.state[`input-1`] === this.state[`input-2`] && this.state[`input-1`] && this.state[`input-2`]) {
        if (!this.state.passwordIncorrect) {
          this.setState({
            passwordIncorrect: true
          })
        }
        return <div className={'auth-error auth-input-error form-error-block'}>
          <div className="auth-error-i">!</div>
          <div className="auth-error-t">Password incorrect</div>
        </div>
      } else {
        this.state.passwordIncorrect &&
        this.setState({
          passwordIncorrect: false
        })
        return null
      }
  }

  openEditModal = type => () => {
    let content;
    let buttons;
    let modalTitle;

    switch (type) {
      case 'password':
        content = <div className='modal-content'>
          {this.renderModalInput('Current Password', '', false, false, 1)}
          {this.renderModalInput('New Password', '8 characters minimum', false, true, 2)}
          {this.renderModalInput('Retype New password', '', false, true, 3)}
        </div>
        buttons = [{text: 'Cancel', whiteButton: true, buttonStyles: defaultButtonsStyles, onClick:this.handleCloseModalForm}, {text: 'Save', onClick: this.handleSaveModalForm(type).bind(this), buttonStyles: defaultButtonsStyles}]
        modalTitle = 'Change Password'
        break;
      default: return
    }

    this.setState({
      content,
      modalTitle,
      buttons,
      modal: true,
      type
    })
  }

  render () {
    return (
      <div className="App">
        <div className="profile-content">
          <div className="profile-c1">
            <div className="profile-line"></div>
          </div>
          <div className="profile-c2">
            <div className="profile-form">
              <h2 className="profile-t1">Profile</h2>
              <div className="profile-form-block">
                <div className='input-wrapper'>
                  <InputText title={'Username'} className={'profile-input'} value={this.props.profileData.userName} ref={r => this.userNameRef = r} disabled />
                </div>
                <div className='input-wrapper'>
                  <InputText title={'Name'} className={'profile-input'} value={this.props.profileData.name} ref={r => this.nameRef = r} disabled />
                </div>
                <div className='input-wrapper'>
                  <InputText title={'Surname'} className={'profile-input'} value={this.props.profileData.surname} ref={r => this.surnameRef = r} disabled />
                </div>
                <div className='input-wrapper'>
                  <InputText title={'Email'} className={'profile-input'} value={this.props.profileData.email} ref={r => this.emailRef = r} disabled />
                </div>
                <div className='input-wrapper'>
                  <InputText title={'Phone number'} className={'profile-input'} value={this.props.profileData.phoneNumber} ref={r => this.phoneNumberRef = r} disabled />
                </div>
                <div className="profile-button">
                  <Button text={'Change password'} onClick={this.openEditModal('password').bind(this)} />
                </div>
                <div className="profile-button">
                  <Button text={'Update security answers'} onClick={() => this.props.history.push('/profile/security-answers/')} />
                </div>
              </div>

            </div>
          </div>
        </div>
        <Modal
          title={this.state.modalTitle || 'Your Profile Changes'}
          hideButton={true}
          modalOpen={this.state.modal}
          content={
            this.state.content
          }
          errorAfterContent={this.renderModalError()}
          buttons={this.state.buttons || [
            {text: 'Ok', buttonStyles: {width: '159px'}, onClick: () => this.setState({modal: false})},
          ]}
          onClose={() => this.setState({
            modal: false,
            content: null,
            contentWrapperStyle: null,
            modalError: null,
            'input-1': null,
            'input-2': null, 
            'input-3': null, 
            wrapperClassname: 'profile-modal-block',
            modalText: ''
          })}
          contentWrapperStyle={this.state.contentWrapperStyle}
          disableDefaultHandler
          wrapperClassname={this.state.wrapperClassname}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profileData: state.profile.profileData || {},
  changePasswordError: state.profile.changePasswordError,
  changePasswordSuccess: state.profile.changePasswordSuccess,
})

export default connect(mapStateToProps)(screenHOC(withRouter(Profile)))
