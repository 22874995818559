import React, { useState } from 'react';
import OvalOff from '../../../assets/images/auth/oval-off.svg';
import OvalOn from '../../../assets/images/auth/oval-on.svg';

const Radio = ({ title, name, onClick }) => {
    const [toggleRadio, setToggleRadio] = useState(false);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',

                marginBottom: '10px',
            }}
            onClick={() => {onClick(title === 'Block Temporarily' ? 'TemporaryBlock' : title)}}
            >
            {/* <img
                src={toggleRadio ? OvalOn : OvalOff}
                onClick={() => setToggleRadio(!toggleRadio)}
            /> */}
            <input type='radio' name={name} value={title} className={'radio'} />
            <label htmlFor={title} onClick={() => setToggleRadio(!toggleRadio)} style={{ fontSize: '16px', fontWeight: 500, marginLeft: '13px' }}>{title}</label>
        </div>
    );
};

export default Radio;
