import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'
import '../ManageCards.css';

const CardOption = ({ title, icon, turned, description, onClick }) => {
    return (
        <div className="Item">
            <div className={classNames('Icon', turned ? 'IconTurned' : null)}>
                {typeof icon === 'string' ? <img src={icon} alt="text" /> : icon}
            </div>
            <Link to="#" onClick={onClick} style={{color: '#000000'}}>
                <div className="Title">{title}</div>
                <div className="Description">{description}</div>
            </Link>
        </div>
    );
};

export default CardOption;
