export default [
  [93,'Afghanistan','AF','🇦🇫'],
  [355,'Albania','AL','🇦🇱'],
  [213,'Algeria','DZ','🇩🇿'],
  [1684,'American Samoa','AS','🇦🇸'],
  [376,'Andorra','AD','🇦🇩'],
  [244,'Angola','AO','🇦🇴'],
  [1264,'Anguilla','AI','🇦🇮'],
  [672,'Antarctica','AQ','🇦🇶'],
  [1268,'Antigua and Barbuda','AG','🇦🇬'],
  [54,'Argentina','AR','🇦🇷'],
  [374,'Armenia','AM','🇦🇲'],
  [297,'Aruba','AW','🇦🇼'],
  [61,'Australia','AU','🇦🇺'],
  [43,'Austria','AT','🇦🇹'],
  [994,'Azerbaijan','AZ','🇦🇿'],
  [973,'Bahrain','BH','🇧🇭'],
  [880,'Bangladesh','BD','🇧🇩'],
  [1246,'Barbados','BB','🇧🇧'],
  [375,'Belarus','BY','🇧🇾'],
  [32,'Belgium','BE','🇧🇪'],
  [501,'Belize','BZ','🇧🇿'],
  [229,'Benin','BJ','🇧🇯'],
  [1441,'Bermuda','BM','🇧🇲'],
  [975,'Bhutan','BT','🇧🇹'],
  [591,'Bolivia (Plurinational State of)','BO','🇧🇴'],
  [5997,'Bonaire, Sint Eustatius and Saba','BQ','🇧🇶'],
  [387,'Bosnia and Herzegovina','BA','🇧🇦'],
  [267,'Botswana','BW','🇧🇼'],
  [47,'Bouvet Island','BV','🇧🇻'],
  [55,'Brazil','BR','🇧🇷'],
  [246,'British Indian Ocean Territory','IO','🇮🇴'],
  [673,'Brunei Darussalam','BN','🇧🇳'],
  [359,'Bulgaria','BG','🇧🇬'],
  [226,'Burkina Faso','BF','🇧🇫'],
  [257,'Burundi','BI','🇧🇮'],
  [238,'Cabo Verde','CV','🇨🇻'],
  [855,'Cambodia','KH','🇰🇭'],
  [237,'Cameroon','CM','🇨🇲'],
  [1,'Canada','CA','🇨🇦'],
  [1345,'Cayman Islands','KY','🇰🇾'],
  [236,'Central African Republic','CF','🇨🇫'],
  [235,'Chad','TD','🇹🇩'],
  [56,'Chile','CL','🇨🇱'],
  [86,'China','CN','🇨🇳'],
  [61,'Christmas Island','CX','🇨🇽'],
  [61891,'Cocos (Keeling) Islands','CC','🇨🇨'],
  [57,'Colombia','CO','🇨🇴'],
  [1242,'Commonwealth of The Bahamas','BS','🇧🇸'],
  [1670,'Commonwealth of the Northern Mariana Islands','MP','🇲🇵'],
  [269,'Comoros','KM','🇰🇲'],
  [682,'Cook Islands','CK','🇨🇰'],
  [506,'Costa Rica','CR','🇨🇷'],
  [385,'Croatia','HR','🇭🇷'],
  [53,'Cuba','CU','🇨🇺'],
  [599,'Curaçao','CW','🇨🇼'],
  [357,'Cyprus','CY','🇨🇾'],
  [420,'Czechia','CZ','🇨🇿'],
  [225,'Côte d\'Ivoire','CI','🇨🇮'],
  [243,'Democratic Republic of the Congo','CD','🇨🇩'],
  [45,'Denmark','DK','🇩🇰'],
  [253,'Djibouti','DJ','🇩🇯'],
  [767,'Dominica','DM','🇩🇲'],
  [1,'Dominican Republic','DO','🇩🇴'],
  [593,'Ecuador','EC','🇪🇨'],
  [20,'Egypt','EG','🇪🇬'],
  [503,'El Salvador','SV','🇸🇻'],
  [240,'Equatorial Guinea','GQ','🇬🇶'],
  [291,'Eritrea','ER','🇪🇷'],
  [372,'Estonia','EE','🇪🇪'],
  [268,'Eswatini','SZ','🇸🇿'],
  [251,'Ethiopia','ET','🇪🇹'],
  [500,'Falkland Islands','FK','🇫🇰'],
  [298,'Faroe Islands','FO','🇫🇴'],
  [679,'Fiji','FJ','🇫🇯'],
  [358,'Finland','FI','🇫🇮'],
  [33,'France','FR','🇫🇷'],
  [594,'French Guiana','GF','🇬🇫'],
  [689,'French Polynesia','PF','🇵🇫'],
  [672,'French Southern and Antarctic Lands','TF','🇹🇫'],
  [241,'Gabon','GA','🇬🇦'],
  [220,'Gambia','GM','🇬🇲'],
  [995,'Georgia','GE','🇬🇪'],
  [49,'Germany','DE','🇩🇪'],
  [233,'Ghana','GH','🇬🇭'],
  [350,'Gibraltar','GI','🇬🇮'],
  [30,'Greece','GR','🇬🇷'],
  [299,'Greenland','GL','🇬🇱'],
  [1473,'Grenada','GD','🇬🇩'],
  [590,'Guadeloupe','GP','🇬🇵'],
  [1,'Guam','GU','🇬🇺'],
  [502,'Guatemala','GT','🇬🇹'],
  [44,'Guernsey','GG','🇬🇬'],
  [224,'Guinea','GN','🇬🇳'],
  [245,'Guinea-Bissau','GW','🇬🇼'],
  [592,'Guyana','GY','🇬🇾'],
  [509,'Haiti','HT','🇭🇹'],
  [39,'Holy See','VA','🇻🇦'],
  [504,'Honduras','HN','🇭🇳'],
  [852,'Hong Kong','HK','🇭🇰'],
  [36,'Hungary','HU','🇭🇺'],
  [354,'Iceland','IS','🇮🇸'],
  [91,'India','IN','🇮🇳'],
  [62,'Indonesia','ID','🇮🇩'],
  [98,'Iran (Islamic Republic of)','IR','🇮🇷'],
  [964,'Iraq','IQ','🇮🇶'],
  [353,'Ireland','IE','🇮🇪'],
  [44,'Isle of Man','IM','🇮🇲'],
  [972,'Israel','IL','🇮🇱'],
  [39,'Italy','IT','🇮🇹'],
  [876,'Jamaica','JM','🇯🇲'],
  [81,'Japan','JP','🇯🇵'],
  [44,'Jersey','JE','🇯🇪'],
  [962,'Jordan','JO','🇯🇴'],
  [7,'Kazakhstan','KZ','🇰🇿'],
  [254,'Kenya','KE','🇰🇪'],
  [686,'Kiribati','KI','🇰🇮'],
  [965,'Kuwait','KW','🇰🇼'],
  [996,'Kyrgyzstan','KG','🇰🇬'],
  [856,'Lao People\'s Democratic Republic','LA','🇱🇦'],
  [371,'Latvia','LV','🇱🇻'],
  [961,'Lebanon','LB','🇱🇧'],
  [266,'Lesotho','LS','🇱🇸'],
  [231,'Liberia','LR','🇱🇷'],
  [218,'Libya','LY','🇱🇾'],
  [423,'Liechtenstein','LI','🇱🇮'],
  [370,'Lithuania','LT','🇱🇹'],
  [352,'Luxembourg','LU','🇱🇺'],
  [853,'Macao','MO','🇲🇴'],
  [261,'Madagascar','MG','🇲🇬'],
  [265,'Malawi','MW','🇲🇼'],
  [60,'Malaysia','MY','🇲🇾'],
  [960,'Maldives','MV','🇲🇻'],
  [223,'Mali','ML','🇲🇱'],
  [356,'Malta','MT','🇲🇹'],
  [596,'Martinique','MQ','🇲🇶'],
  [222,'Mauritania','MR','🇲🇷'],
  [230,'Mauritius','MU','🇲🇺'],
  [262,'Mayotte','YT','🇾🇹'],
  [52,'Mexico','MX','🇲🇽'],
  [691,'Micronesia (Federated States of)','FM','🇫🇲'],
  [377,'Monaco','MC','🇲🇨'],
  [976,'Mongolia','MN','🇲🇳'],
  [382,'Montenegro','ME','🇲🇪'],
  [1664,'Montserrat','MS','🇲🇸'],
  [212,'Morocco','MA','🇲🇦'],
  [258,'Mozambique','MZ','🇲🇿'],
  [95,'Myanmar','MM','🇲🇲'],
  [264,'Namibia','NA','🇳🇦'],
  [674,'Nauru','NR','🇳🇷'],
  [977,'Nepal','NP','🇳🇵'],
  [31,'Netherlands','NL','🇳🇱'],
  [687,'New Caledonia','NC','🇳🇨'],
  [64,'New Zealand','NZ','🇳🇿'],
  [505,'Nicaragua','NI','🇳🇮'],
  [227,'Niger','NE','🇳🇪'],
  [234,'Nigeria','NG','🇳🇬'],
  [683,'Niue','NU','🇳🇺'],
  [672,'Norfolk Island','NF','🇳🇫'],
  [850,'North Korea','KP','🇰🇵'],
  [389,'North Macedonia','MK','🇲🇰'],
  [47,'Norway','NO','🇳🇴'],
  [968,'Oman','OM','🇴🇲'],
  [92,'Pakistan','PK','🇵🇰'],
  [680,'Palau','PW','🇵🇼'],
  [970,'Palestine, State of','PS','🇵🇸'],
  [507,'Panama','PA','🇵🇦'],
  [675,'Papua New Guinea','PG','🇵🇬'],
  [595,'Paraguay','PY','🇵🇾'],
  [51,'Peru','PE','🇵🇪'],
  [63,'Philippines','PH','🇵🇭'],
  [64,'Pitcairn','PN','🇵🇳'],
  [48,'Poland','PL','🇵🇱'],
  [351,'Portugal','PT','🇵🇹'],
  [1,'Puerto Rico','PR','🇵🇷'],
  [974,'Qatar','QA','🇶🇦'],
  [373,'Republic of Moldova','MD','🇲🇩'],
  [242,'Republic of the Congo','CG','🇨🇬'],
  [692,'Republic of the Marshall Islands','MH','🇲🇭'],
  [40,'Romania','RO','🇷🇴'],
  [7,'Russia','RU','🇷🇺'],
  [250,'Rwanda','RW','🇷🇼'],
  [262,'Réunion','RE','🇷🇪'],
  [590,'Saint Barthélemy','BL','🇧🇱'],
  [290,'Saint Helena, Ascension and Tristan da Cunha','SH','🇸🇭'],
  [1869,'Saint Kitts and Nevis','KN','🇰🇳'],
  [1758,'Saint Lucia','LC','🇱🇨'],
  [590,'Saint Martin (French part)','MF','🇲🇫'],
  [508,'Saint Pierre and Miquelon','PM','🇵🇲'],
  [1784,'Saint Vincent and the Grenadines','VC','🇻🇨'],
  [685,'Samoa','WS','🇼🇸'],
  [378,'San Marino','SM','🇸🇲'],
  [239,'Sao Tome and Principe','ST','🇸🇹'],
  [966,'Saudi Arabia','SA','🇸🇦'],
  [221,'Senegal','SN','🇸🇳'],
  [381,'Serbia','RS','🇷🇸'],
  [248,'Seychelles','SC','🇸🇨'],
  [232,'Sierra Leone','SL','🇸🇱'],
  [65,'Singapore','SG','🇸🇬'],
  [1721,'Sint Maarten (Dutch part)','SX','🇸🇽'],
  [421,'Slovakia','SK','🇸🇰'],
  [386,'Slovenia','SI','🇸🇮'],
  [677,'Solomon Islands','SB','🇸🇧'],
  [252,'Somalia','SO','🇸🇴'],
  [27,'South Africa','ZA','🇿🇦'],
  [500,'South Georgia and the South Sandwich Islands','GS','🇬🇸'],
  [82,'South Korea','KR','🇰🇷'],
  [211,'South Sudan','SS','🇸🇸'],
  [34,'Spain','ES','🇪🇸'],
  [94,'Sri Lanka','LK','🇱🇰'],
  [249,'Sudan','SD','🇸🇩'],
  [597,'Suriname','SR','🇸🇷'],
  [4779,'Svalbard and Jan Mayen','SJ','🇸🇯'],
  [46,'Sweden','SE','🇸🇪'],
  [41,'Switzerland','CH','🇨🇭'],
  [963,'Syrian Arab Republic','SY','🇸🇾'],
  [886,'Taiwan, Province of China','TW','🇹🇼'],
  [992,'Tajikistan','TJ','🇹🇯'],
  [672,'Territory of Heard Island and McDonald Islands','HM','🇭🇲'],
  [66,'Thailand','TH','🇹🇭'],
  [670,'Timor-Leste','TL','🇹🇱'],
  [228,'Togo','TG','🇹🇬'],
  [690,'Tokelau','TK','🇹🇰'],
  [676,'Tonga','TO','🇹🇴'],
  [868,'Trinidad and Tobago','TT','🇹🇹'],
  [216,'Tunisia','TN','🇹🇳'],
  [90,'Turkey','TR','🇹🇷'],
  [993,'Turkmenistan','TM','🇹🇲'],
  [1649,'Turks and Caicos Islands','TC','🇹🇨'],
  [688,'Tuvalu','TV','🇹🇻'],
  [256,'Uganda','UG','🇺🇬'],
  [380,'Ukraine','UA','🇺🇦'],
  [971,'United Arab Emirates','AE','🇦🇪'],
  [44,'United Kingdom','GB','🇬🇧'],
  [255,'United Republic of Tanzania','TZ','🇹🇿'],
  [246,'United States Minor Outlying Islands','UM','🇺🇲'],
  [1,'United States of America','US','🇺🇸'],
  [598,'Uruguay','UY','🇺🇾'],
  [998,'Uzbekistan','UZ','🇺🇿'],
  [678,'Vanuatu','VU','🇻🇺'],
  [58,'Venezuela (Bolivarian Republic of)','VE','🇻🇪'],
  [84,'Vietnam','VN','🇻🇳'],
  [1284,'Virgin Islands (British)','VG','🇻🇬'],
  [1340,'Virgin Islands (U.S.)','VI','🇻🇮'],
  [681,'Wallis and Futuna','WF','🇼🇫'],
  [212,'Western Sahara','EH','🇪🇭'],
  [967,'Yemen','YE','🇾🇪'],
  [260,'Zambia','ZM','🇿🇲'],
  [263,'Zimbabwe','ZW','🇿🇼'],
  [358,'Åland Islands','AX','🇦🇽']
];