import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'
import InstaLogo from '../../assets/images/instagram.svg'
import { connect } from 'react-redux'
import classNamesBind from 'classnames/bind'

const mapStateToProps = state => ({
  token: state.stuff.token || state.auth?.userData?.token || false
})

const year = new Date().getFullYear();

export const Footer = connect(mapStateToProps)(({token}) => {
  return (
    <div className='footer'>
      <div className={classNamesBind('footer-nav-menu', !token && 'loggedOut')}>
        <div style={{flexGrow: token ? 1 : 0}}>
          <NavLink to='/contact' className='nav-menu-title' >
            Contact
          </NavLink>
        </div>

        <div>
          <p className='nav-menu-title' >
            Website
          </p>
          <NavLink to='/privacy' className='nav-menu-item' >
            Privacy Policy
          </NavLink>
          <NavLink to='/cook' className='nav-menu-item' >
            Cookies Policy
          </NavLink>
          <NavLink to='/conditions' className='nav-menu-item' >
            Website Terms &amp; Conditions
          </NavLink>
        </div>
        {
          token
            ? <div>
                <p className='nav-menu-title' >
                  Resource Centre
                </p>
                <NavLink to='/conditionsCard' className='nav-menu-item' >
                  Cards Terms &amp; Conditions
                </NavLink>
                <NavLink to='/Form' className='nav-menu-item' >
                  Eligibility Form
                </NavLink>
                <NavLink to='/tariff' className='nav-menu-item' >
                  Tariff Plans
                </NavLink>
                {/* <NavLink to='/FAQ' className='nav-menu-item' >
                  FAQs
                </NavLink> */}
              </div>
            : null
        }

      </div>
      <div className='footer-links'>
        <div className='footer-links-social'>
          <div className="footer-links-socials">
            <a href={'https://www.instagram.com/insignia.worldwide/'} target='_blank'>
              <img src={InstaLogo} className='insta' alt=''/>
            </a>
            <a href={'https://www.linkedin.com/company/insignia-lifestyle-management/'}  target='_blank'>
              <img className='linkedin' src='../../assets/images/linked-in-icon.svg' alt=''/>
            </a>
          </div>
          <a href={'https://insignia.com'}  target='_blank'>
            <img src='../../assets/images/INSIGNIA_TOTAL_LOGO_FINAL.svg' alt=''/>
          </a>
        </div>
        {/* <a href={'https://apps.apple.com/us/app/master-key/id1227460308'}>
          <img src='../../assets/images/app-store-load-banner.svg' alt='' className='appStore' />
        </a> */}
        <div className='copyrights'>
          ©Copyright {year} Insignia Group
        </div>
      </div>
    </div>
  )
})
