const CAN_USE_DOM = typeof document !== 'undefined';

let scrollPosition = 0;
let count = 0;

const body = CAN_USE_DOM ? document.querySelector('body') : null;

export function getScrollbarSize() {
  if (!CAN_USE_DOM) return 0;
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.overflow = 'scroll';

  body.appendChild(scrollDiv);
  let scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  body.removeChild(scrollDiv);
  if (scrollbarSize !== 0) return scrollbarSize + 'px';

  return scrollbarSize;
}

const scroll = {
  disable: () => {
    // console.log('!!!scroll.disable');
    count++;
    if (count === 1 && body) {
      body.style.overflow = 'hidden';
      body.style.paddingRight = getScrollbarSize();
    }
  },
  enable: () => {
    // console.log('!!!scroll.enable');
    count--;
    if (count === 0 && body) {
      // console.log('ENABLE');
      body.style.overflow = null;
      body.style.paddingRight = null;
    }
  }
};

export default scroll;
