// import Axios from "axios";

export const openPDFViewByData = (data, withoutBlobHandler) => {
  let file = null;
  if (withoutBlobHandler) {
    file = data
  } else {
    file = new Blob(
      [data],
      {type: 'application/pdf'});
  }
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export const downloadPDFViewByData = (data, name, withoutBlobHandler) => {
  let file = null;
  if (withoutBlobHandler) {
    file = data
  } else {
    file = new Blob(
      [data],
      {type: 'application/pdf'});
  }
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  const linkName = name.replace(/[#_.,\s]/gi,'')
  link.download = linkName + ".pdf";
  link.click();
}

export const printPDFViewByData = (data) => {
    const file = new Blob(
      [data],
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    var iframe = document.createElement('iframe');
    // iframe.id = 'pdfIframe'
    iframe.className='pdfIframe'
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(fileURL)
            // document.body.removeChild(iframe)
        }, 1);
    };
    iframe.src = fileURL;
    // URL.revokeObjectURL(url)
}

export const insertSpaces = (d) => {
  let result = [];
  let lastDigits = `${d}`.match(/([.]\d{0,})/gi)?.[0] || ''
  let dArr = `${d}`.replace(/([.]\d{0,})/, '').split('');
  let counter = 0
  for (let i = dArr.length; i >= 0; i--) {
      if (counter % 4 === 0 && counter !== 0) {
          result.unshift(' ')
      }
      result.unshift(dArr[i] || '')
      counter++
  }
  return result.join('').concat(lastDigits)
}

/*export const  currency_symbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'EU': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'CHF': '₣', // Swiss Frank
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
};*/

export const validateEmail = (email) => {
  return !!email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
}