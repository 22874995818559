import React, { useCallback, useEffect } from 'react';

export const Approval = ({url, onSetStep}) => {
  const handleMessage = useCallback(e => {
    if (e.data.function !== 'iframeParent') return;
    const ok = e.data.status === 'AUTHORIZED';
    const step = ok ? 'success' : 'error';
    onSetStep(step);
  }, [onSetStep]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);
  return (
    <div className="add-money">
      {url ? <div className="add-money__frame-container">
        <iframe className="add-money__frame" title="approval" src={url} /> 
      </div> : null}
    </div>
  );
}