import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getContentTextPrivacyAction } from '../../redux/actions/contentActions';
import { screenHOC } from '../HOC/screenHOC'
import './info.scss';

const PrivacyPolicy = ({dispatch, privacy}) => {
  useEffect(() => {
    dispatch(
      getContentTextPrivacyAction()
    )
  })
  return (
    <div className="privacy">
      <div className="privacy-content">
        <div className="privacy-line-title">
          <div className="privacy-line"/>
          <h2 className="section-title section-title__cards">Privacy Policy</h2>
        </div>
        <div className="privacy-text" >
          <div dangerouslySetInnerHTML={{__html: privacy}} className="privacy-text-content" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  privacy: state.content.privacy
})

export default connect(mapStateToProps)(screenHOC(PrivacyPolicy))
