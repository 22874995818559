import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getContentTextCookiesAction } from '../../redux/actions/contentActions';
import { screenHOC } from '../HOC/screenHOC'
import './info.scss';

const CookiesPolicy = ({dispatch, cookies}) => {
  useEffect(() => {
    dispatch(
      getContentTextCookiesAction()
    )
  })
  return (
    <div className="privacy">
      <div className="privacy-content">
        <div className="privacy-line-title">
          <div className="privacy-line"/>
          <h2 className="section-title section-title__cards">Cookies Policy</h2>
        </div>
        <div className="privacy-text" >
          <div dangerouslySetInnerHTML={{__html: cookies}} className="privacy-text-content" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  cookies: state.content.cookies
})

export default connect(mapStateToProps)(screenHOC(CookiesPolicy))
