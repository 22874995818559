import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAddMoney, useFeeFactor } from './useAddMoney';

import { Modal } from '../../components/Modal';

import { Wizard, Step } from '../../shared/ui';

import './AddMoney.css';
import { Form } from './components/Form';
import { Approval } from './components/Approval';
import { Error } from './components/Error';
import { Success } from './components/Success';

const formatCardNumber = number => number ? '**** **** **** ' + number.slice(-4) : '';

const CURRENCIES = {
  'USD': '$',
  'EUR': '€',
};

export const AddMoney = ({ card, onClose }) => {  
  const [step, setStep] = useState('form');
  const [amount, setAmount] = useState('');
  // const [feeFactor] = useState(0.03);
  const {getFeeFactor, feeFactor} = useFeeFactor();
  const handleChange = useCallback(e => setAmount(e.target.value), []);
  const currencyCode = card ? card.currency : '';
  const currency = CURRENCIES[currencyCode] || currencyCode;
  const fee = useMemo(() => feeFactor && amount ? feeFactor * parseFloat(amount) : '', [amount, feeFactor]);
  const finalAmount = useMemo(() => amount && fee ? (parseFloat(amount) + parseFloat(fee)).toFixed(2) : '', [amount, fee]);
  const finalFee = useMemo(() => fee ? fee.toFixed(2) : '', [fee]);
  const feeText = useMemo(() => feeFactor * 100, [feeFactor]);
  const {loading, post, approvalUrl, reset} = useAddMoney();  
  //
  const handlePost = useCallback(() => {
    if (!card) return;
    post({ cardId: card.id, amount: +finalAmount });    
  }, [finalAmount, card, post]);    
  //
  useEffect(() => {
    if (!card) {
      setAmount('');      
      reset();
      setStep('form');
    } else {
      getFeeFactor();
    }
  }, [card, reset, getFeeFactor]);
  useEffect(() => {
    if (approvalUrl) setStep('approval');
  }, [approvalUrl]);  
  useEffect(() => {
    if (step === 'success' || step === 'error') {
      reset();
    }
  }, [step, reset]);    
  const titleContent = step === 'form' || step === 'approval' ? 'Add money' : '';
  const title = titleContent ? <div className="add-money__title">{titleContent}</div> : undefined;
  const content = <Wizard step={step}>
    <Step step="form">
      <Form 
        cardNumber={card ? formatCardNumber(card.cardNumber) : ''}
        currency={currency}
        amount={amount} 
        fee={3} 
        finalAmount={finalAmount} 
        finalFee={finalFee} 
        onPost={handlePost}
        loading={loading} 
        onChange={handleChange}
      /></Step>
    <Step step="approval">
      <Approval url={approvalUrl} onSetStep={setStep} />
    </Step>
    <Step step="success">
      <Success amount={amount} currency={currency} fee={feeText} finalFee={finalFee} finalAmount={finalAmount} onAction={onClose} />
    </Step>
    <Step step="error">
      <Error onAction={() => setStep('form')} />
    </Step>
  </Wizard>
  return (
    <>
      <Modal modalOpen={!!card} onClose={onClose} hideButton content={content} title={title} />
    </>
  );
};
