import React from 'react';
import classNames from 'classnames';
import './InputText.css';

const InputText = React.forwardRef(({title, style, className, type, pattern, value, disabled, error, onChange}, ref) => {
    return (
        <div className={classNames('container', className)} style={{...style}}>
            <div className='title-Input'>
                <p className='title-Text-Input'>{title}</p>
            </div>
            <input className='input' type={type} ref={ref} pattern={pattern} defaultValue={value} disabled={disabled} style={{borderColor: error ? 'red' : null}} onChange={onChange} />
        </div>
    )
})

export default InputText;
