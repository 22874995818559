import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal } from '../../components/Modal'
import { screenHOC } from '../HOC/screenHOC'
import './auth.css';
import BgImge from '../../assets/images/auth/bg-01.png';
import { registerAction } from '../../redux/actions/authActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {isNil} from 'lodash'
import { Footer } from '../../components/Footer';

class Register extends Component {
  constructor (props) {
    super(props);
    this.state = {
      cardNumber: '',
      phoneCountryCode: '',
      phoneNumber: '',
      formError: '',
      errorValidate: false,
      modal: true,
      loader: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.registerDone !== prevProps.registerDone && !isNil(this.props.registerDone) ) {
      this.props.history.push('/registercode')
    }
  }
  
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let text = '';
    let error = false;

    switch(fieldName) {
      case 'cardNumber':
        if (value.length < 1) {
          text = 'Please enter "Card number"';
          error = true
        }
        break;
      case 'phoneCountryCode':
        if (value.length < 1) {
          text = 'Please enter "Mobile phone code"';
          error = true;
        }
        break;
      case 'phoneNumber':
        if (value.length < 1) {
          text = 'Please enter "Mobile phone number"';
          error = true;
        }
        break;
    }

    this.setState({errorValidate: error, formError: text});

    return !error; //lk
  }

  sendForm = () => {
    if (
      this.validateField('cardNumber', this.state.cardNumber) &&
      this.validateField('phoneCountryCode', this.state.phoneCountryCode) &&
      this.validateField('phoneNumber', this.state.phoneNumber)
    ) {
      this.setState({loader: true});
      this.props.dispatch(
        registerAction({
          cardNumber: this.state.cardNumber,
          phoneCountryCode: this.state.phoneCountryCode,
          phoneNumber: this.state.phoneNumber,
        })
      )
      // axios.post('https://iobapi-dev.crm555.com/api/v1/Auth/register', {
      //   cardNumber: this.state.cardNumber,
      //   phoneCountryCode: this.state.phoneCountryCode,
      //   phoneNumber: this.state.phoneNumber,
      // })
      // .then(response => {
      //   this.setState({loader: false});
      //   window.location.replace("/registercode");
      // })
      // .catch(error => {
      //   if (error.response.data.detail) this.setState({formError: error.response.data.detail, errorValidate:true}) //lk
      //   //lk console.log(error.response.data.detail);
      //   this.setState({loader: false});
      // });
    }
    
  }

  openMasterkey = () => {
    this.setState({modal: false});
    window.location.replace("/masterkey");
  }

  openLogin = () => {
    window.location.replace("/login")
  }

  render () {
    return (
      <div className="App">
        <div className="auth-content">
          <div className="auth-c1">
            
          </div>
          <div className="auth-c2" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form">
              <h2 className="auth-t1">Registration</h2>
              <p className="auth-t2">Register to the world of Insignia</p>

              <div className="auth-input">
                <label className="auth-input-label">Enter the Last Four Digits on your Card</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" name="cardNumber"
                    value={this.state.cardNumber}
                    onChange={this.handleInput}
                  />
                </div>
              </div>
              <div className="auth-input">
                <label className="auth-input-label">Mobile Number</label>
                <div className="auth-input-row">
                  <input className="auth-input-input register-i2" type="text" name="phoneCountryCode"
                    value={this.state.phoneCountryCode}
                    onChange={this.handleInput}
                  />
                  <input className="auth-input-input register-i3" type="text" name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleInput}
                  />
                </div>
              </div>
              <div className={'auth-row auth-error' + (this.state.errorValidate ? ' auth-has-error' : '')}>
                <div className="auth-error-i">!</div>
                <div className="auth-error-t">{this.state.formError}</div>
              </div>

              <div className="auth-btn-group register-group-btn">
                <div className="auth-btn auth-b1" onClick={this.openLogin}>Log In</div>
                <div className="auth-btn auth-b2 auth-btn-loader" onClick={this.sendForm}>
                  <span>{this.state.loader ? '' : 'Generate Code'}</span>
                  <Loader
                    style={{marginLeft: '10px', height: '25px'}}
                    visible={this.state.loader}
                    type="Oval"
                    color="#d7c29a"
                    height={25}
                    width={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title='Before we get started'
          hideButton={true}
          modalOpen={this.state.modal}
          content={
            <div className="auth-modal">
              <p>To register for Insignia's Online Network, you need to download and activate the secure Master Key App. If you need any assistance please contact our customer services team on the number below:</p>
              <p style={{marginTop: '15px'}}>24-hour phone support:</p>
              <p>+44-203-151-7000 / +800-800-00-700</p>
            </div>
          }
          buttons={[
            {onClick: this.openMasterkey, text: 'Download Now', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  registerDone: state.auth.registerDone
})

export default connect(mapStateToProps)(withRouter(Register))

/*
ошибка валидации с бэка
Your details do not match
The information provided does not match the details in our system. Kindly contact customer services for assistance.
*/
