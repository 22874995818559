import React, {createRef, useEffect, useState} from 'react';
import classes from './EligibilityForm.css'
import {screenHOC} from "../HOC/screenHOC";
import cart from '../../assets/images/cart.png'
import InputText from "../../components/InputText/InputText";
import {Button} from "../../components/Button";
import {Modal} from "../../components/Modal";
import TitlePage from "../../components/TitlePage/TitlePage";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {CardSlider} from "../../components/CardSlider";
import {CardInfo} from "../../components/CardInfo";
import Card from "../../assets/images/right.png";
import { connect, useDispatch } from 'react-redux';
import { applyCardAction, resetData } from '../../redux/actions/cardsActions';
import classNamesBind from 'classnames/bind';
import InsigniaCards from '../../components/InsigniaCards';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import { validateEmail } from '../../redux/helpers';

const cx = classNamesBind.bind(classes);

// refs
const firstName = createRef();
const lastName = createRef();
const birthDate = createRef();
const address = createRef();
const nationality = createRef();
const passportIssued = createRef();
const employmentType = createRef();
const companyName = createRef();
const annualIncome = createRef();
const email = createRef();
const mobileNumber = createRef();
const desiredProduct = createRef();
const jewelleryCardName = createRef();

const card = {
  images: [Card, Card, Card],
  title: 'Royal Card',
  text: 'One of the world’s first super-premium payment cards, a puissant symbol of status designed to serve the financial and luxury lifestyle requirements of our most extraordinary members.'
}

const EligibilityForm = ({cardsArr = Array(10).fill(1), applyCard, applyCardError}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentReward, setCurrentReward] = useState(0)
  const [selectedCard, setSelectedCard] = useState({})
  const [shownCard, setShownCard] = useState(0)
  const [error, setError] = useState(false)
  const [emailError, setEmailErro] = useState(false)
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    if (location.state?.selectedCard) {
      desiredProduct.current.value = location.state.selectedCard?.name || ''
      // jewelleryCardName.current.value = location.state.selectedCard?.name || ''
      window.scrollTo({
        top: 750,
        behavior: 'smooth',
      })
    }
  }, [location])

  useEffect(() => {
    setLoading(false);
    !isNil(applyCard) && setOpenModal(true)

    dispatch(
      resetData(true)
    )
  }, [applyCard])

  useEffect(() => {
    setLoading(false);
    !isNil(applyCardError) && setError(true)
  }, [applyCardError])


  const enqNow = () => {
    if (
      firstName.current.value &&
      lastName.current.value &&
      birthDate.current.value &&
      address.current.value &&
      nationality.current.value &&
      passportIssued.current.value &&
      employmentType.current.value &&
      companyName.current.value &&
      annualIncome.current.value &&
      email.current.value &&
      validateEmail(email.current.value) &&
      mobileNumber.current.value &&
      desiredProduct.current.value &&
      jewelleryCardName.current.value
    ) {
      setLoading(true)
      dispatch(
        applyCardAction({
          firstName: firstName.current.value,
          lastName: lastName.current.value,
          birthDate: birthDate.current.value,
          address: address.current.value,
          nationality: nationality.current.value,
          passportIssued: passportIssued.current.value,
          employmentType: employmentType.current.value,
          companyName: companyName.current.value,
          annualIncome: +annualIncome.current.value || 0,
          email: email.current.value,
          mobileNumber: mobileNumber.current.value,
          desiredProduct: desiredProduct.current.value,
          jewelleryCardName: jewelleryCardName.current.value,
        })
      )
    } else {
      !validateEmail(email.current.value || '') && setEmailErro(true)
      setError(true)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    firstName.current.value = ''
    lastName.current.value = ''
    birthDate.current.value = ''
    address.current.value = ''
    nationality.current.value = ''
    passportIssued.current.value = ''
    employmentType.current.value = ''
    companyName.current.value = ''
    annualIncome.current.value = ''
    email.current.value = ''
    mobileNumber.current.value = ''
    desiredProduct.current.value = ''
    jewelleryCardName.current.value = ''
  }

  const renderCards = (type) => <div className='tabContent'>
    {renderTabContent(type)}
  </div>

  const renderTabContent = type => {
    switch (type) {
      case 'all':
        return renderAll()
        break;
      case 'personal':
        return renderPersonal()
        break;
      case 'business':
        return renderBusiness()
        break;
      default: return null
    }
  }


  const handleCardSelect = (card, index) => {
    setSelectedCard(card)
    setShownCard(index)
  }
  const renderAll = () => <div style={{width: '100%'}}>
    <CardSlider
      visibleCount={7}
      cardClassName={cx('smallCardsSliderCard', 'slick-slide-bg')}
      cardSliderClassName='smallCardsSlider'
      isNeedSeparator
      onSelectCard={handleCardSelect}
      cardsArr={cardsArr}
      toggleCards
      showCardId={shownCard}
      customCardArrowSeparatorStyles={cx('custom-cards-arrow-separator')}
    />
    <div className='cardInfo-container'>
      <CardInfo card={card} />
    </div>
  </div>
  const renderPersonal = () => <div>
    Personal
  </div>
  const renderBusiness = () => <div>
    Business
  </div>

  return (
    <div className='up-wrap'>
    <div className='bg'>
      <div className='content-UP'>
        <div className='left-column'>
          <TitlePage
            title="Eligibility Form"
            subtitle="Are you ready to connect to our world? Check to see if you are eligible for additional Insignia services."/>
        </div>
        <img src={cart} />
      </div>
      <div className='content-form content-form-eligibility'>
        <InputText title={'First Name'} ref={firstName} error={!firstName.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Last Name'} ref={lastName} error={!lastName.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Date of Birth'} ref={birthDate} error={!birthDate.current?.value && error} type='date' onChange={() => setError(false)} />
        <InputText title={'Residential Address'} ref={address} error={!address.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Nationality'} ref={nationality} error={!nationality.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Passport Country of Issuance'} ref={passportIssued} error={!passportIssued.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Occupation Type'} ref={employmentType} error={!employmentType.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Company Name'} ref={companyName} error={!companyName.current?.value && error} onChange={() => setError(false)} />
        <InputText title={'Annual Income'} ref={annualIncome} error={!annualIncome.current?.value && error} type='number' onChange={() => setError(false)} />
        <InputText title={'Mobile Number (including country code)'} ref={mobileNumber} error={!mobileNumber.current?.value && error} type='tel' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={() => setError(false)} />
        <InputText title={'Email Address'} ref={email} error={emailError} type='email' onChange={() => {setError(false); setEmailErro(false)}} />
        <InputText title={'Desired Product'} ref={desiredProduct} error={!desiredProduct.current?.value && error} onChange={() => setError(false)} />
        <div className="content-form-eligibility-button">
          <Button text='Enquire Now' onClick={enqNow} loading={loading} />
          {
            applyCardError
              ? <div className={'auth-error auth-input-error auth-has-error'} style={{marginTop: 25, marginLeft: 0}}>
                  <div className="auth-error-i">!</div><div className="auth-error-t">SOMETHING WENT WRONG. PLEASE TRY AGAIN OR CONTACT CUSTOMER SERVICE.</div>
                </div>
              : null
          }
        </div>

        <InputText title={'Jewellery Card Name'} ref={jewelleryCardName} error={!jewelleryCardName.current?.value && error} onChange={() => setError(false)} />
        
        <Modal
          title='Thank you for your enquiry'
          content={
            <div>
              <p>An Insignia representative will contact you shortly.</p>
            </div>
          }
          // "The JSON value could not be converted to System.Int32. Path: $.annualIncome | LineNumber: 0 | BytePositionInLine: 184."
          buttons={[
            {
              onClick: () => {
                setOpenModal(false)
              }, text: 'Ok', buttonStyles: {width: '159px'}, textStyles: {}
            },
          ]}
          onClose={handleCloseModal}
          hideButton={true}
          modalOpen={openModal}
        />
      </div>
      <div className='lineForm' />
    </div>

    <div className="show-desktop" style={{height: 1, width: '85%', backgroundColor: '#6c6c6c', margin: '0 auto'}} />

    <InsigniaCards />
  </div>

  )
}

const mapStateToProps = state => ({
  applyCard: state.cards.applyCard,
  applyCardError: state.cards.applyCardError,
})

export default connect(mapStateToProps)(screenHOC(EligibilityForm));
