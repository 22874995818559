import React, {useCallback} from 'react';
import classNames from 'classnames';

import './DialogWindow.css';

export default function DialogWindow({className, caption, color, openSetter, onClose, children}) {
  const handleClose = useCallback(() => {
    if (openSetter) openSetter(false);
    if (onClose) onClose(false);
  }, [openSetter, onClose]);
  return (
    <div className={classNames('dialog-window', color ? 'dialog-window_color_' + color : null, className)}>
      <div className="dialog-window__caption">
        <span>
          {caption}
        </span>
        <span className="dialog-window__top-actions">
          <span className="dialog-window__close" onClick={handleClose} />
        </span>
      </div>
      <div className="dialog-window__content">
        {children}
      </div>
    </div>
  );
};
