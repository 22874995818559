import React from 'react'
import { connect } from 'react-redux'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import MessagePopUp from '../../components/MessagePopUp'
import LettersCount from '../../components/LettersCount'

import './screenHOC.css';

const mapStateToProps = state => ({
  token: state.stuff.token || state.auth?.userData?.token || false
})

export const screenHOC = (Component) => connect(mapStateToProps)((props) => {
  const pathname = window.location.pathname.toLowerCase().replace('/', '');

  const isWhite = () => {
    let white = true;

    switch (pathname) {
      case 'login':
      case 'masterkey':
      case 'logincode':
      case 'register':
      case 'registercode':
      case 'registerok':
      case 'recovery':
      case 'forgot':
      case 'recoverycode':
      case 'recoveryok':
      case 'profile':
      case 'form':
      case 'contact':
        white = false;

        break;
      default:
        break;
    }

    return white;
  };

  return (
    <div className={`screen ${isWhite() ? 'white-header' : ''}`}>
      <LettersCount />
      <Header />

      <div className="screen__content">
        <Component {...props} />
      </div>
      <Footer />
      {/* <MessagePopUp /> */}
    </div>
  )
});