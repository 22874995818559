import React, { useEffect, useState } from 'react'
import { screenHOC } from '../HOC/screenHOC'
import './Products.css'
import { connect } from 'react-redux';
import { getCardsAll, getCardsLatest } from '../../redux/actions/cardsActions';
import InsigniaCards from '../../components/InsigniaCards';
import Axios from 'axios';
import { setToken } from '../../redux/actions/authActions';
import { getContentFeedAction, getContentServicesAction } from '../../redux/actions/contentActions';
import { useRef } from 'react';
import { Preloader } from '../../components/Preloader';


const Products = ({latestCards, allCards, token, news, services, dispatch, isFetching}) => {
  const [shownCard, setShownCard] = useState(null)
  const [refs] = useState({
    newsRef1: useRef(),
    newsRef2: useRef(),
    newsRef3: useRef(),
    newsRef4: useRef(),
  })

  useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem('stuff'));
    if (storageData) {
      if (storageData?.token) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${storageData?.token}`;
      }
      setToken(storageData?.token)
    }
    setTimeout(() => {
      dispatch(
          getCardsAll()
      )
      dispatch(
          getCardsLatest()
      )
      dispatch(
          getContentFeedAction(4)
      )
      dispatch(
          getContentServicesAction()
      )
    }, 250)
  }, [])

  const onSelectCard = (item, key) => {
    setShownCard(key)
  }

  if (allCards && news && services) {
    return (
      <div className="Products">
        <div>
          <InsigniaCards />
        </div>
      </div>
    )
  } else {
    return <Preloader />
  }
  
}

const mapStateToProps = state => ({
  latestCards: state.cards.cardsLatest || [],
  allCards: state.cards.cardsAll || [],
  news: state.content.feed || [],
  token: state.auth.token,
  services: state.content.services,
  isFetching: state.cards.isFetching //|| state.content.isFetching || state.letters.isFetching || state.profile.isFetching || state.stuff.isFetching
})

export default connect(mapStateToProps)(screenHOC(Products))