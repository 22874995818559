import React, {useCallback, useEffect, useRef, PropsWithChildren} from 'react';
import classNames from 'classnames';
import noScroll from 'no-scroll';

import './Dialog.css';

export default function Dialog({className, open, openSetter, onClose, children}) {
  const ref = useRef(null);
  //
  const handleOpen = useCallback(() => {
    noScroll.on();
  }, [noScroll]);
  //
  const handleClose = useCallback(
    (e) => {      
      noScroll.off();
      if (openSetter) openSetter(false);
      if (onClose) onClose(e);
      
    },
    [onClose, noScroll]
  );
  const handleCloseByClick = useCallback(() => {    
    if (ref.current) {
      ref.current.close();
    }
  }, [ref]);
  //
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('open', handleOpen);
      ref.current.addEventListener('close', handleClose);
    }
  }, [ref.current]);
  //
  useEffect(() => {
    if (!ref.current) return;
    if (open) {
      handleOpen();      
      ref.current.showModal();
    }    
    else ref.current.close();
  }, [ref, open, handleOpen]);
  //
  return (
    <dialog className={classNames('dialog', className)} ref={ref}>
      <div className="dialog__backdrop" onClick={handleCloseByClick} />
      {children}
    </dialog>
  );
}
