import { CHANGE_PASSWORD, GET_PROFILE, POST_PROFILE } from '../constants'

const initState = {}

export default function contentReducer(state = initState, action) {
  
  
  switch (action.type) {
    
    case `${GET_PROFILE}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        profileData: null,
      }
    }
    case `${GET_PROFILE}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        profileData: action.payload.data,
      }
    }
    case `${GET_PROFILE}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        profileData: null,
      }
    }
    
    case `${POST_PROFILE}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        profileData: null,
      }
    }
    case `${POST_PROFILE}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        profileDataPostSuccess: action?.payload?.data,
        profileDataPostError: null,
      }
    }
    case `${POST_PROFILE}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        profileDataPostSuccess: action,
        profileDataPostError: null,
      }
    }
    
    case `${CHANGE_PASSWORD}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
        changePasswordSuccess: null,
        changePasswordError: null,
      }
    }
    case `${CHANGE_PASSWORD}_SUCCESS`: {
      return {
        ...state,
        isFetching: false,
        changePasswordSuccess: action.payload.data,
        changePasswordError: null,
      }
    }
    case `${CHANGE_PASSWORD}_FAILED`: {
      return {
        ...state,
        isFetching: false,
        changePasswordSuccess: null,
        changePasswordError: action.payload.data,
      }
    }

    default:
      return state
  }
} 