import React, {useEffect} from 'react';
import { connect, Provider, useDispatch } from 'react-redux';

import store from './redux/store';
import saga from './redux/sagas/';

// Axios settings
import './api/axiosConfig';

import {setAppElement} from './components/Modal';

import Home from './screens/Home/Home';
import CardsScreen from './screens/Cards';

import AuthLogin from './screens/Auth/Login';
import AuthLoginCode from './screens/Auth/LoginCode';

import AuthRegister from './screens/Auth/Register';
import AuthMasterkey from './screens/Auth/Masterkey';
import AuthRegisterCode from './screens/Auth/RegisterCode';
import AuthRegisterOk from './screens/Auth/RegisterOk';
import ManageCards from './screens/ManageCards/ManageCards'
import AuthRecovery from './screens/Auth/Recovery';
import AuthForgot from './screens/Auth/Forgot';
import AuthRecoveryCode from './screens/Auth/RecoveryCode';
import AuthRecoveryOk from './screens/Auth/RecoveryOk';

import ProfileProfile from './screens/Profile/Profile'; //lk
import SecurityAnswers from './screens/SecurityAnswers/SecurityAnswers'; //lk

import Contact from './screens/Contact';
import EligibilityForm from './screens/EligibilityForm/EligibilityForm'

import { sagaMiddleware } from './redux/middleware';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import GoogleAnalyticsComponent from './components/GoogleAnalytics';
import './App.css';
import Letters from './screens/Letters/Letters';
import { PageNotFound } from './screens/404/PageNotFound';

import Privacy from './screens/Info/Privacy';
import Cookies from './screens/Info/Cookies';
import Conditions from './screens/Info/Conditions';
import Tariff from './screens/Info/Tariff';
import Axios from 'axios';
import { setToken } from './redux/actions/authActions';
import ConditionsCard from './screens/Info/ConditionsCard';
import FAQ from './screens/Info/FAQ';
import Products from './screens/Products/Products';
import DisputedCharges from './screens/DisputedCharges';

const GoogleAnalytics = withRouter(GoogleAnalyticsComponent);


const mapStateToProps = state => ({
  isLoggedIn: state.auth.userData,
  authData: state.auth
})

const PrivateRoute = connect(mapStateToProps)(
  ({ children, ...rest }) => {
    const storageData = JSON.parse(localStorage.getItem('stuff'));

    if (storageData) {
      if (storageData?.token) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${storageData?.token}`;
      }
      
      if (!rest?.isLoggedIn?.token) {
        rest.dispatch(
          setToken(storageData?.token)
        )
      }
    }

    const isLoggedIn = !!rest.isLoggedIn || (storageData && storageData.token && !storageData.secondFactorEnabled);

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
)

const App = ({isLoggedIn}) => {
  useEffect(() => {
    setAppElement('body');
  }, []);
  return (
  <Provider store={store}>
    <Router>
      <GoogleAnalytics trackingId="UA-25829125-12" />
        <Switch>
          <Route path="/login">
            <AuthLogin />
          </Route>
          <Route path="/logincode">
            <AuthLoginCode />
          </Route>

          <Route path="/register">
            <AuthRegister />
          </Route>
          <Route path="/masterkey">
            <AuthMasterkey />
          </Route>
          <Route path="/registercode">
            <AuthRegisterCode />
          </Route>
          <Route path="/registerok">
            <AuthRegisterOk />
          </Route>
          <Route path="/recovery">
            <AuthRecovery />
          </Route>
          <Route path="/forgot">
            <AuthForgot />
          </Route>
          <Route path="/recoverycode">
            <AuthRecoveryCode />
          </Route>
          <Route path="/recoveryok">
            <AuthRecoveryOk />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          {/* <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute> */}
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/cook">
            <Cookies />
          </Route>
          <Route path="/conditions">
            <Conditions />
          </Route>
          <Route path="/conditionsCard">
            <ConditionsCard />
          </Route>
          <Route path="/FAQ">
            <FAQ />
          </Route>
          <Route path="/tariff">
            <Tariff />
          </Route>
          <PrivateRoute exact path="/cards">
            <CardsScreen />
          </PrivateRoute>
          <PrivateRoute path="/profile/security-answers">
            <SecurityAnswers />
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <ProfileProfile />
          </PrivateRoute>
          <PrivateRoute path="/products">
            <Products />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <ManageCards />
          </PrivateRoute>
          <PrivateRoute exact path="/manage-cards">
            <ManageCards />
          </PrivateRoute>
          <PrivateRoute path="/Form">
            <EligibilityForm />
          </PrivateRoute>
          <PrivateRoute path="/disputed-charges">
            <DisputedCharges />
          </PrivateRoute>
          <PrivateRoute path="/Letters">
            <Letters />
          </PrivateRoute>
          <PrivateRoute path="/Test">
            <Home />
          </PrivateRoute>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>

    </Router>
  </Provider>
)}

export default App
