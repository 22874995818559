import React, { useEffect, useState } from 'react';

const Input = React.forwardRef(({ label, value, maxLength, className, minLength, type}, ref) => {
    const [val, setVal] = useState(value || '');

    // useEffect(() => {
    //     setVal(value)
    // }, [value])
    return (
        <div className={className} className="modal-input">
            <div><span>{label}</span></div>
            <input
                type={type || "text"}
                id={label}
                placeholder={label}
                // value={value || val}
                pattern={`${type === 'number' ? '[0-9]*' : '.*'}`}
                value={val}
                onChange={(e) =>
                  setVal((v) => (e.target.validity.valid ? e.target.value : v))
                }
                // onChange={e => setVal(e.nativeEvent.target.value)}
                ref={ref}
                maxLength={maxLength}
                minLength={minLength}
            />
        </div>
        // <div className={className} style={{ marginBottom: '17px' }}>
        //     <label
        //         style={{ marginBottom: '10px', float: 'left', color: '#88888b', fontSize: '14px', fontFamily: 'ProximaNova' }}
        //         htmlFor={label}>
        //         {label}
        //     </label>
        //     <input
        //         type={type || "text"}
        //         style={{
        //             padding: '1rem 6px',
        //             borderRadius: '2px',
        //             backgroundColor: '#f3f4f4',
        //             border: 'none',
        //             width: '100%',
        //             boxSizing: 'border-box',
        //             fontSize: '14px',
        //             fontWeight: 500,
        //             fontFamily: 'ProximaNova'
        //         }}
        //         id={label}
        //         placeholder={label}
        //         value={value || val}
        //         onChange={e => setVal(e.nativeEvent.target.value)}
        //         ref={ref}
        //         maxLength={maxLength}
        //         minLength={minLength}
        //     />
        // </div>
    );
})

export default Input;
