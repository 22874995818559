import React, {useMemo} from 'react';
import classNames from 'classnames';

import './TransactionTile.css';

export function formatAmount(amount) {
  if (amount === null || typeof amount === 'undefined') return null;
  const c = '';
  const absAmount = amount >= 0 ? amount : -amount;
  const d = Math.floor(absAmount);
  const n = (
    Array.from(d.toString())
      .reverse()
      .join('')
      .match(/\d{1,3}/g) || []
  )
    .join(' ')
    .split('')
    .reverse()
    .join('');
  const sign = amount >= 0 ? '' : '-';
  const p = Math.round((absAmount - d) * 100);
  let result = `${c}${sign}${n}`;  
  result += `.${p < 10 ? '0' : ''}${p}`;
  return result;
  //.${p < 10 ? '0' : ''}${p}`;
}


export default function TransactionTile({className, date, currency, amount, merchant, typeOfDispute, natureOfDispute, onEdit, onDelete}) {
  const dateStr = useMemo(() => {
    return date ? [...date.split('-').reverse()].join('/') : date;
  }, [date]);
  const amountStr = useMemo(() => {
    return formatAmount(amount);
  }, [amount]);
  return (
    <div className={classNames('transaction-tile', className)}>
      <div className="transaction-tile__row">
        <span className="transaction-tile__key">Date</span>
        <span className="transaction-tile__value">{dateStr}</span>
      </div>
      <div className="transaction-tile__row">
        <span className="transaction-tile__key">Amount</span>
        <span className="transaction-tile__value">
          <span className="transaction-tile__currency">{currency} </span>
          <span className="transaction-tile__amount">{amountStr}</span>
        </span>
      </div>
      <div className="transaction-tile__row">
        <span className="transaction-tile__key">Merchant</span>
        <span className="transaction-tile__value">{merchant}</span>
      </div>
      <div className="transaction-tile__row">
        <span className="transaction-tile__key">Type of dispute</span>
        <span className="transaction-tile__value">{typeOfDispute}</span>
      </div>
      <div className="transaction-tile__row">
        <span className="transaction-tile__key">Nature of dispute</span>
        <span className="transaction-tile__value">{natureOfDispute}</span>
      </div>
      <div className="transaction-tile__actions">
        <button className="transaction-tile__action" onClick={onEdit}>
          Edit
        </button>
        <span className="transaction-tile__delimiter" />
        <button className="transaction-tile__action" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  )
}