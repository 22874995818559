import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { API, apiRoutes } from "../../api/api";

import { screenHOC } from "../HOC/screenHOC";
import "./SecurityAnswers.css";
import Select from "../../components/Select";
import { InputSecret } from "../../components/InputSecret";
import { Button } from "../../components/Button";

export const SecurityAnswers = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [resultText, setResultText] = useState('Security questions were updated successfully.');
  const [success, setSuccess] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [value, setValue] = useState({
    question1: "none",
    question2: "none",
    question3: "none",
  });
  const history = useHistory();
  //
  useEffect(() => {
    let cancelled = false;
    const url = `${apiRoutes.securityQuestions}`;
    (async () => {
      const { data: questions } = await API.get({ url });
      if (!cancelled) setQuestions(questions);
    })();
    return () => {
      cancelled = true;
    };
  }, []);
  const handleChangeQuestion = useCallback((name, value) => {
    setValue(current => {
      const result = {...current};
      result[name] = value;
      if (value !== 'none') {
        const others = ['question1', 'question2', 'question3'].filter(item => item !== name);
        others.forEach((name) => {
          if (result[name] === value) result[name] = 'none';
        });
      }
      return result;
    })
  }, [setValue]);
  //
  const handleSubmit = useCallback(() => {        
    const form = document.querySelector('#security-answers');
    const {question1, answer1, question2, answer2, question3, answer3} = form.elements;    
    let errors;
    if (question1.value === "none") {
      if (!errors) errors = {};
      errors['question1'] = 'Invalid data';
    }
    if (!answer1.value) {
      if (!errors) errors = {};
      errors['answer1'] = 'Invalid data';
    }
    if (question2.value !== 'none' && !answer2.value) {
      if (!errors) errors = {};
      errors['answer2'] = 'Invalid data';
    }
    if (question2.value === 'none' && answer2.value) {
      if (!errors) errors = {};
      errors['question2'] = 'Invalid data';
    }
    if (question3.value !== 'none' && !answer3.value) {
      if (!errors) errors = {};
      errors['answer3'] = 'Invalid data';
    }
    if (question3.value === 'none' && answer3.value) {
      if (!errors) errors = {};
      errors['question3'] = 'Invalid data';
    }
    if (errors) {
      setErrors(errors);
      return;
    }
    setErrors(undefined);
    setLoading(true);
    (async () => {
      try {
        const url = `${apiRoutes.securityQuestions}`;
        await API.post({ url, data: {
          question1: question1.value,
          answer1: answer1.value,
          question2: question2.value || '',
          answer2: answer2.value || '',
          question3: question3.value || '',
          answer3: answer3.value || '',
        } });
        setSuccess(true);
        setResultText("Security questions were updated successfully.");        
      } catch (e) {
        setResultText("Something went wrong.");
        setSuccess(false);
      } finally {
        setLoading(false);
        setShowResult(true);
      }
    })();
  }, []);
  const handleClose = useCallback(() => {
    history.push('/profile/');
  }, [history]);
  const questionsOptions = questions.map((item, i) => (
    <option value={item} key={i}>
      {item}
    </option>
  ));
  const questions2 = useMemo(() => {
    return questions.map((item, i) => (
      <option value={item} disabled={item === value.question1} key={i}>
        {item}
      </option>
    ))
  }, [value.question1, questions]);
  const questions3 = useMemo(() => {
    return questions.map((item, i) => (
      <option value={item} disabled={item === value.question1 || item === value.question2} key={i}>
        {item}
      </option>
    ))
  }, [value.question1, value.question2, questions]);
  return (
    <div className="App">
      <div className="sa">
        <div className="sa__header">
          <h1 className="sa__title">Security Questions</h1>
          <div className="sa__sub-title">
            A security question is a word or phrase that only you know and that
            serves as the key to your personal world.
          </div>
        </div>
        <form id="security-answers" className="sa__form">
          <label className="sa__label">Secret question 1</label>
          <Select className="sa__control" name="question1" value={value.question1} onChange={(e) => handleChangeQuestion(e.target.name, e.target.value)}>
            <option value="none" disabled selected defaultValue="none" />
            {questionsOptions}
          </Select>
          <InputSecret className="sa__control" name="answer1" />
          <Select className="sa__control" name="question2" value={value.question2} onChange={(e) => handleChangeQuestion(e.target.name, e.target.value)}>
            <option value="none" disabled selected defaultValue="none">
              Secret question 2
            </option>
            {questions2}
          </Select>
          <InputSecret className="sa__control" name="answer2" />
          <Select className="sa__control" name="question3" value={value.question3} onChange={(e) => handleChangeQuestion(e.target.name, e.target.value)}>
            <option value="none" disabled selected defaultValue="none">
              Secret question 3
            </option>
            {questions3}
          </Select>
          <InputSecret className="sa__control" name="answer3" />
          <div className="sa__actions">
            <Button className="sa__action" text="Save" loading={loading} onClick={handleSubmit} />
          </div>
          <div className="sa__errors">
            <div className="sa__error">              
              {errors?.question1}
            </div>
            <div className="sa__error">
              {errors?.answer1}
            </div>
            <div className="sa__error">              
              {errors?.question2}
            </div>
            <div className="sa__error">
              {errors?.answer2}
            </div>
            <div className="sa__error">              
              {errors?.question3}
            </div>
            <div className="sa__error">
              {errors?.answer3}
            </div>
          </div>
        </form>
      </div>
      <dialog open={showResult} className="sa__dialog">
        <div className="sa__result">
          <div className="sa__result-title">Security questions</div>
          <div className="sa__result-content">
            <div className="sa__result-text">{resultText}</div>
            <Button className="sa__action" text="OK" onClick={handleClose} />
            {!success ? <div className="sa__result-contacts">
              Please contact our support team<br/>
              if you have any questions<br/>
              tel.: <a href="tel:+420458965896">+420-4589-65-896</a>
            </div> : null}
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default screenHOC(SecurityAnswers);
