import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getContentFeedAction } from '../../redux/actions/contentActions';
import { screenHOC } from '../HOC/screenHOC'
import './info.scss';

const FAQ = ({dispatch, feed}) => {
  const [selectedFAQ, setSelectedFAQ] = useState(1)
  useEffect(() => {
    dispatch(
      getContentFeedAction(selectedFAQ)
    )
  }, [])
  return (
    <div className="privacy">
      <div className="privacy-content">
        <div className="privacy-top">
          <div className="privacy-line"></div>
          <h2 className="section-title">FAQ`s</h2>
        </div>
        <div className="privacy-text" >
          <div dangerouslySetInnerHTML={{__html: feed}} className="privacy-text-content" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  feed: state.content.feed
})

export default connect(mapStateToProps)(screenHOC(FAQ))