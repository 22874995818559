import React from 'react'
import { screenHOC } from '../HOC/screenHOC'
import classNames from 'classnames/bind'
import classes from './page-not-found.scss'

const cx = classNames.bind(classes)

export const  PageNotFound = screenHOC(
  () => {
    return (
      <div className={cx('wrap')}> 
        404 page not found
      </div>
    )
  }
)
