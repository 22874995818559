import {
  GET_CONTENT_FEED,
  GET_CONTENT_TEXT_CONTACT,
  GET_CONTENT_TEXT_TARIFF,
  GET_CONTENT_TEXT_SITETC,
  GET_CONTENT_TEXT_CARDSTC,
  GET_CONTENT_TEXT_COOKIES,
  GET_CONTENT_TEXT_PRIVACY,
  GET_CONTENT_SERVICES
} from "../constants";

export const getContentFeedAction = (payload) => ({
  type: `${GET_CONTENT_FEED}_REQUEST`,
  payload
});

export const getContentServicesAction = (payload) => ({
  type: `${GET_CONTENT_SERVICES}_REQUEST`,
  payload
});

export const getContentTextContactAction = () => ({
  type: `${GET_CONTENT_TEXT_CONTACT}_REQUEST`,
});

export const getContentTextTariffAction = () => ({
  type: `${GET_CONTENT_TEXT_TARIFF}_REQUEST`,
});

export const getContentTextSiteTCAction= () => ({
  type: `${GET_CONTENT_TEXT_SITETC}_REQUEST`,
});

export const getContentTextCardTCAction = () => ({
  type: `${GET_CONTENT_TEXT_CARDSTC}_REQUEST`,
});

export const getContentTextCookiesAction = () => ({
  type: `${GET_CONTENT_TEXT_COOKIES}_REQUEST`,
});

export const getContentTextPrivacyAction = () => ({
  type: `${GET_CONTENT_TEXT_PRIVACY}_REQUEST`,
});