import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { Button } from '../Button';
import './styles.scss'
import Card from '../../assets/images/right.png'
import { withRouter } from 'react-router-dom';
import {ReactComponent as Arrow} from '../../assets/images/arrow.svg'
import { baseURL } from '../../api/api';
import Loader from 'react-loader-spinner';

let swiperControl = null

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Arrow className={props.inactive && 'inactive'} />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Arrow className={props.inactive && 'inactive'} />
    </div>
  );
}

export const CardInfo = withRouter(({cards, shownCard, onChange, onSwiper, history}) => {
  return (
    <div className='card-info-wrapper'>
      {
        cards && cards.length &&
          <div className="card-info-wrapper-container">
            <PrevArrow className={'prev-arrow'} inactive={shownCard === 0} onClick={() => shownCard > 0 && swiperControl.slideTo(shownCard - 1)} />
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={onChange}
              onSwiper={sw => {
                onSwiper(sw)
                swiperControl = sw
              }}
              className='card-images-slider'
            >
              {
                cards?.map((item, key) => <SwiperSlide key={key}>
                  {
                    key === shownCard
                      ? <img src={(cards?.[shownCard]?.images?.rotated && `${baseURL}/${cards[shownCard].images.rotated}`) || Card} alt='card' />
                      : <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          {
                            !!shownCard || shownCard === 0
                              ? <Loader
                                style={{marginLeft: '10px', height: '25px'}}
                                visible
                                type="Oval"
                                color="#d7c29a"
                                height={25}
                                width={25}
                              />
                              : <div className='card-info-description-text'>please select card</div>
                          }

                      </div>
                  }

                </SwiperSlide>)
              }
            </Swiper>
            <NextArrow className={'next-arrow'} inactive={shownCard === cards.length - 1} onClick={() => shownCard < cards.length - 1 && swiperControl.slideTo(shownCard + 1)} />
          </div>
      }
      <div className='card-info-description'>
        <div className='card-info-description-title'>{cards?.[shownCard]?.name}</div>
        <div className='card-info-description-text'>{cards?.[shownCard]?.description}</div>
        <div className='card-info-description-buttons'>
          <Button
            text='Apply Now'
            buttonStyles={{display: 'flex', flex: '0 0 50%'}}
            onClick={() => history.push('/Form', {selectedCard: cards[shownCard]})}
          />

          <Button
            text='Tariff of Charges'
            whiteButton
            className="button-bordered"
            textStyles={{color: '#272727'}}
            onClick={() => history.push('/tariff')}
          />
        </div>
      </div>
    </div>
  )
})
