import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import ReactCodeInput from "react-code-input";
import { Modal } from '../../components/Modal';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import { screenHOC } from '../HOC/screenHOC';
import './auth.css';
import BgImge from '../../assets/images/auth/bg-01.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { Footer } from '../../components/Footer';
import { resetErrors, verifyCodeAction } from '../../redux/actions/authActions';

class LoginCode extends Component {
  constructor (props) {
    super(props);
    this.state = {
      code: '',
      codeError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      errorCount: 0,
    }
  }

  componentDidMount() {
    document.addEventListener('keypress', (e) => e.key === 'Enter' && this.sendForm())
    this.props.dispatch(resetErrors())
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginSuccessCode !== this.props.loginSuccessCode && !isNil(this.props.loginSuccessCode)) {
      this.props.history.push('/');
      axios.defaults.headers.common['Authorization'] = `Bearer${this.props.userData?.token}`;
      localStorage.setItem('stuff', JSON.stringify({...this.props.userData}));
    }

    if (prevProps.loginErrorCode !== this.props.loginErrorCode && !isNil(this.props.loginErrorCode)) {
      this.setState({loader: false});
    }

    if (prevProps.responseStatus !== 401 && this.props.responseStatus === 401) {
      this.setState({loader: false, codeError: 'INCORRECT CONFIRMATION CODE'});
      this.props.dispatch(resetErrors());
    }
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    this.setState({errorValidate: error, [fieldName + 'Error']: text});

    return !error; //lk
  }

  sendForm = () => {
    // if (
    //   this.validateField('code', this.state.code)
    // ) {
      this.setState({loader: true});
      this.props.dispatch(
        verifyCodeAction({code: this.state.code})
      )
      // this.setState({errorCount: this.state.errorCount + 1});

      //lk window.location.replace("/loginok");
      //lk incorrect confirmation code
    // }
  }

  openLogin = () => {
    // window.location.replace("/login")
  }

  render () {
    return (
      <div className="App">
        <div className="logo-box">
          <NavLink to='/'>
            <img src={Logo} alt='logo' width='100%' height='100%' className='logo' />
          </NavLink>
        </div>

        <div className="auth-content">
          <div className="auth-c1"></div>

          <div className="auth-c2 auth-c2-masterkey" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form">
              <h2 className="auth-t1">Authentication</h2>
              <div className='auth-container'>
                <p className="logincode-t2">Master Key has generated a one-time confirmation code, please insert it below to login.</p>
                <div className="auth-code-group">
                  <label className="auth-code-label">Confirmation Code:</label>
                  <ReactCodeInput
                    type='text'
                    fields={6}
                    value={this.state.code}
                    onChange={(value) => this.handleInput({target: {name: 'code', value: value}})}
                    className="auth-code-input"
                  />
                  {
                      this.state.codeError &&
                        <div className={'auth-error auth-input-error' + (this.state.codeError !== '' ? ' auth-has-error' : '')} style={{marginTop: 10}}>
                          <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.codeError}</div>
                        </div>
                    }
                  <div className="auth-code-t1 hovered-icon" onClick={() => this.props.history.push('/masterkey')}>
                    Problems with my Master Key code
                  </div>
                </div>
                <div className="auth-btn-group">
                  <div className="auth-btn auth-b3 auth-btn-loader" onClick={() => {this.sendForm()}}>
                    <span>Submit</span>
                    <Loader
                      style={{marginLeft: '10px', height: '25px'}}
                      visible={this.state.loader}
                      type="Oval"
                      color="#d7c29a"
                      height={25}
                      width={25}
                    />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

        <Modal
          title='Master Key Error'
          hideButton={true}
          modalOpen={this.props.loginErrorCode}
          content={
            <div className="auth-modal">
              <p>If you are having problems receiving your Master Key authentication code please contact our customer services team on the number below:</p>
              <p style={{marginTop: '15px'}}>24-hour phone support:</p>
              <p>+44-203-151-7000 / +800-800-00-700</p>
            </div>
          }
          buttons={[
            {onClick: this.openLogin, text: 'OK', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
      <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loginSuccessCode: state.auth.codeOk,
  loginErrorCode: state.auth.codeError,
  userData: state.auth.userData,
  responseStatus: state.auth.allResponse?.response?.status || 0
})

export default connect(mapStateToProps)(withRouter(LoginCode))
