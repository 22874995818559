import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal } from '../../components/Modal'
import { screenHOC } from '../HOC/screenHOC'
import Logo from '../../assets/images/logo.png';
import './auth.css';
import BgImge from '../../assets/images/auth/bg-02.png';
import { connect } from 'react-redux';
import { recoverPasswordAction, resetErrors } from '../../redux/actions/authActions';
import { isNil } from 'lodash';
import { NavLink, withRouter } from 'react-router-dom';
import { Footer } from '../../components/Footer';

class Recovery extends Component {
  constructor (props) {
    super(props);
    this.state = {
      userName: '',
      cardNumber: '',
      userNameError: '',
      cardNumberError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      errorCount: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.recoverSended !== this.props.recoverSended && !isNil(this.props.recoverSended)) {
      // this.props.history.push('/recoverycode')
      this.setState({loader: false})
    }
    if (prevProps.error !== this.props.error && !isNil(this.props.error)) {
      this.setState({loader: false})
    }

  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    switch(fieldName) {
      case 'userName':
        if (value.length < 1) {
          text = 'incorrect username';
          error = true
        }
        break;
      case 'cardNumber':
        if (value.length < 1) {
          text = 'incorrect cardnumber';
          error = true;
        }
        break;
    }

    this.setState({errorValidate: error, [fieldName + 'Error']: text});

    return !error; //lk
  }

  sendForm = () => {
    // if (
    //   this.validateField('userName', this.state.userName) &&
    //   this.validateField('cardNumber', this.state.cardNumber)
    // ) {
      this.setState({loader: true});

      this.props.dispatch(
        recoverPasswordAction({
          userName: this.state.userName,
          cardNumber: this.state.cardNumber
        })
      )
    // }
  }

  openRegister = () => {
    this.props.history.push("/register")
  }

  openForgot = () => {
    this.props.history.push("/forgot")
  }

  render () {
    return (
      <div className="App">
        <div className="logo-box" style={{'zIndex': 200}}>
          <NavLink to='/'>
            <img src={Logo} alt='logo' width='100%' height='100%' className='logo' />
          </NavLink>
        </div>
        <div className="auth-content">
          <div className="auth-c2 auth-c2-recovery" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form-recovery">
              <h2 className="auth-t1">Password Recovery</h2>
              <p className="auth-t2">Enter the world of Insignia</p>

              <div className="auth-input">
                <label className="auth-input-label">Username</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" required name="userName"
                    value={this.state.userName}
                    onChange={this.handleInput}
                  />
                  {
                    this.props.error && (this.props.error.detail || this.props.error?.errors?.UserName?.[0]) &&
                      <div className={'auth-error auth-input-error show-desktop' + (this.props.error.detail != '' ? ' auth-has-error' : '')}>
                        <div className="auth-error-i">!</div><div className="auth-error-t">{this.props.error.detail || this.props.error?.errors?.UserName?.[0]}</div>
                      </div>
                  }
                </div>
              </div>

              <div className="auth-input">
                <label className="auth-input-label">Card Number</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" required name="cardNumber"
                    value={this.state.cardNumber}
                    onChange={this.handleInput}
                  />
                  {
                    this.props.error && (this.props.error.detail || this.props.error?.errors?.CardNumber?.[0]) &&
                      <div className={'auth-error auth-input-error show-desktop' + (this.props.error.detail != '' ? ' auth-has-error' : '')}>
                        <div className="auth-error-i">!</div><div className="auth-error-t">{this.props.error.detail || this.props.error?.errors?.CardNumber?.[0]}</div>
                      </div>
                  }
                </div>
              </div>

              <div className="auth-row">
                {/* <span className="auth-t3" onClick={this.openForgot}>Forgotten your username?</span> */}
              </div>

              {
                this.props.error && (this.props.error.detail || this.props.error?.errors?.UserName?.[0]) &&
                this.props.error && (this.props.error.detail || this.props.error?.errors?.CardNumber?.[0]) &&
              <div className="form-error-block show-mobile">
                  {
                    this.props.error && (this.props.error.detail || this.props.error?.errors?.UserName?.[0]) &&
                      <div className={'auth-error form-error-block-row  auth-input-error' + (this.props.error.detail != '' ? ' auth-has-error' : '')}>
                        <div className="auth-error-i">!</div><div className="auth-error-t">{this.props.error.detail || this.props.error?.errors?.UserName?.[0]}</div>
                      </div>
                  }
                  {
                    this.props.error && (this.props.error.detail || this.props.error?.errors?.CardNumber?.[0]) &&
                      <div className={'auth-error form-error-block-row auth-input-error' + (this.props.error.detail != '' ? ' auth-has-error' : '')}>
                        <div className="auth-error-i">!</div><div className="auth-error-t">{this.props.error.detail || this.props.error?.errors?.CardNumber?.[0]}</div>
                      </div>
                  }
              </div>
              }

              <div className="auth-btn-group">
                <div className="auth-btn auth-b1" onClick={() => this.props.history.goBack()}>Cancel</div>
                <div className="auth-btn auth-b2 auth-btn-loader" onClick={this.sendForm}>
                  <span>Next</span>
                  <Loader
                    style={{marginLeft: '10px', height: '25px'}}
                    visible={this.state.loader}
                    type="Oval"
                    color="#d7c29a"
                    height={25}
                    width={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title='Your details do not match'
          hideButton={true}
          modalOpen={this.props.error}
          wrapperClassname="recovery-custom-modal login-error-modal"
          content={
            <div className="auth-modal">
              <p>The information provided does not match the details in our system. Please contact customer services for assistance.</p>
              <div className="auth-modal-info">
                <p>24-hour phone support:</p>
                <p>+44-203-151-7000 / +800-800-00-700</p>
              </div>
            </div>
          }
          buttons={[
            {onClick: () => {
              // this.props.dispatch(
              //   resetErrors()
              // )
            }, text: 'Ok', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
        <Footer />
        <Modal
          hideButton
          modalOpen={!isNil(this.props.recoverSended)}
          title='We will be in touch shortly'
          wrapperClassname="recovery-custom-modal login-success-modal"
          content={
            <div className="auth-modal">
              <p>Please note a member of our team will be in touch with you in the next 2 hours to inform you of your full log in details securely.</p>
              <p>If you need urgent assistance please contact the call centre team on the number below.</p>
              <div className="auth-modal-info">
                <p>24-hour phone support: </p>
                <p>+44-203-151-7000 / +800-800-00-700</p>
              </div>
          </div>}
          buttons={[
            {onClick: () => {

            }, text: 'Ok', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  recoverSended: state.auth.recoverSended,
  error: state.auth.errorRecover
})

export default connect(mapStateToProps)(withRouter(Recovery));
