import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {getLettersCount} from '../../redux/actions/lettersActions';

const REFRESH_COUNT = 30000;

export function LettersCount({isLoggedIn, onRefresh, children}) {
  const [value, setValue] = useState(null);
  const timer = useRef();
  useEffect(() => {
    function refresh() {
      onRefresh();
    }
    function startTimer() {
      stopTimer();
      refresh();
      timer.current = setInterval(refresh, REFRESH_COUNT);
    }
    function stopTimer() {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    }
    if (isLoggedIn) startTimer();
    else stopTimer();
    return () => {
      stopTimer();
    }
  }, [isLoggedIn]);
  return children || null;
};

export default connect(
  state => ({
    isLoggedIn: state.auth.userData
  }),
  dispatch => ({
    onRefresh: () => dispatch(getLettersCount())

  })
)(LettersCount);