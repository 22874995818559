import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import ReactCodeInput from "react-code-input";
import { Modal } from '../../components/Modal';
import { screenHOC } from '../HOC/screenHOC';
import './auth.css';
import BgImge from '../../assets/images/auth/bg-01.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isNil } from 'lodash';
import { verifyCodeAction } from '../../redux/actions/authActions';
import { Footer } from '../../components/Footer';

class RegisterCode extends Component {
  constructor (props) {
    super(props);
    this.state = {
      code: '',
      codeError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      errorCount: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.codeOk !== this.props.codeOk && !isNil(this.props.codeOk)) {
      this.props.history.push('/registerok')
    }
  
  }
  

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    switch(fieldName) {
      case 'code':
        if (value.length != 6) {
          text = 'incorrect code';
          error = true;
        }
        break;
    }

    this.setState({errorValidate: error, [fieldName + 'Error']: text});

    return !error; //lk
  }

  sendForm = () => {
    // if (
    //   this.validateField('code', this.state.code)
    // ) {
      this.setState({loader: true});
      this.props.dispatch(
        verifyCodeAction({code: this.state.code})
      )
      // this.setState({errorCount: this.state.errorCount + 1});

      //lk window.location.replace("/registerok");
      //lk incorrect confirmation code
    // }
  }

  render () {
    return (
      <div className="App">
        <div className="auth-content">
          <div className="auth-c1">
            
          </div>
          <div className="auth-c2" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form">
              <h2 className="auth-t1">Registration</h2>

              <p className="registercode-t2">Master Key has generated a one-time confirmation code, please insert it below to login.</p>

              <div className="auth-code-group">
                <label className="auth-code-label">Confirmation Code:</label>
                <ReactCodeInput
                  type='text'
                  fields={6}
                  value={this.state.code}
                  onChange={(value) => this.handleInput({target: {name: 'code', value: value}})}
                  className="auth-code-input"
                />
                {
                  this.props.codeError &&
                    <div className={'auth-error auth-code-error' + (this.state.codeError != '' ? ' auth-has-error' : '')}>
                      <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.codeError}</div>
                    </div>
                }
                <div className="auth-code-t1">
                  Problems with my Master Key code
                </div>
              </div>

              <div className="auth-btn-group">
                <div className="auth-btn auth-b3 auth-btn-loader" onClick={this.sendForm}>
                  <span>Submit</span>
                  <Loader
                    style={{marginLeft: '10px', height: '25px'}}
                    visible={this.state.loader}
                    type="Oval"
                    color="#d7c29a"
                    height={25}
                    width={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title='Master Key Error'
          hideButton={true}
          modalOpen={this.state.errorCount >= 3}
          content={
            <div className="auth-modal">
              <p>If you are having problems receiving your Master Key authentication code please contact our customer services team on the number below:</p>
              <p style={{marginTop: '15px'}}>24-hour phone support:</p>
              <p>+44-203-151-7000 / +800-800-00-700</p>
            </div>
          }
          buttons={[
            {onClick: () => {}, text: 'OK', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
      <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  codeOk: state.auth.codeOk,
  codeError: state.auth.codeError
})

export default connect(mapStateToProps)(withRouter(RegisterCode))
