import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal } from '../../components/Modal';
import Logo from '../../assets/images/logo.png';
import { screenHOC } from '../HOC/screenHOC';
import './auth.css';
import BgImge from '../../assets/images/auth/bg-02.png';
import OvalOff from '../../assets/images/auth/oval-off.svg';
import OvalOn from '../../assets/images/auth/oval-on.svg';
import { connect } from 'react-redux';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { loginAction } from '../../redux/actions/authActions';
import { isNil } from 'lodash';
import { Footer } from '../../components/Footer';
import { getContentFeedAction } from '../../redux/actions/contentActions';
import * as IoIosIcons from 'react-icons/io';
import { resetStuff } from '../../redux/actions/stuffActions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      rememberMy: false,
      userNameError: '',
      passwordError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      errorCount: 0,
    }
  }

  componentDidMount() {
    // Getting top 1 news
    this.props.dispatch(
      getContentFeedAction(1)
    )

    const credentials = JSON.parse(localStorage.getItem('rememberedPass'))
    if (credentials) {
      this.setState({
        userName: credentials.userName,
        password: credentials.password,
      })
    }
    document.addEventListener('keypress', (e) => e.key === 'Enter' && this.sendForm())
    const { dispatch } = this.props
      dispatch(
          resetStuff()
      )
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginSuccess !== this.props.loginSuccess && !isNil(this.props.loginSuccess)) {
      if (this.props.userData?.token && !this.props.userData?.secondFactorEnabled && this.props.userData?.refreshToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.userData?.token}`;
        localStorage.setItem('stuff', JSON.stringify({ ...this.props.userData }));
        this.props.history.push('/')
        this.setState({ errorModal: false })
      } else if (!this.props.allLoginData) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.userData?.token}`;
        localStorage.setItem('stuff', JSON.stringify({ ...this.props.userData }));
        this.props.history.push('/logincode')
        this.setState({ errorModal: false })
      } else {
        this.setState({error: true, errorModal: this.props?.allLoginData?.status === 403 ? true : null})
      }
      this.setState({ loader: false })
    }
    if (prevProps.error !== this.props.error && !isNil(this.props.error)) {
      this.setState({loader: false, errorModal: this.props?.allLoginData?.status === 403 ? true : null})
    }
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => { this.validateField(name, value) });
  }

  rememberSwitch = () => {
    this.setState({ rememberMy: !this.state.rememberMy });
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    switch (fieldName) {
      case 'userName':
        if (value.length < 1) {
          text = 'incorrect username';
          error = true
        }
        break;
      case 'password':
        if (value.length < 1) {
          text = 'incorrect password';
          error = true;
        }
        break;
    }

    this.setState({ errorValidate: error, [fieldName + 'Error']: text });

    return !error; //lk
  }

  /* //lk validateForm() {
    this.setState({formValid: this.state. && this.state.});
  }*/

  sendForm = () => {
    this.setState({ loader: true });

    this.props.dispatch(
      loginAction({
        userName: this.state.userName,
        password: this.state.password
      })
    )

    if (this.state.rememberMy) {
      localStorage.setItem('rememberedPass', JSON.stringify({
        userName: this.state.userName,
        password: this.state.password
      }))
    }
  }

  openRecovery = () => {
    this.props.history.push("/recovery")
  }

  openRegister = () => {
    this.props.history.push("/register")
  }

  render() {
    const news = this.props.news.length !== 0 && this.props.news[0];

    return (
      <div className="App">
        <div className="logo-box">
          <NavLink to='/'>
            <img src={Logo} alt='logo' width='100%' height='100%' className='logo' />
          </NavLink>
        </div>
        <div className="auth-content">
          <div className="auth-left">
            <div className="auth-c1">
            </div>
            <div className="auth-c2">
              <div className="auth-form">
                <h2 className="auth-t1">Online Network</h2>
                <p className="auth-t2">Enter the world of Insignia</p>
                <div className="auth-input">
                  <label className="auth-input-label">Username</label>
                  <div className="auth-input-row">
                    <input className="auth-input-input" type="text" name="userName"
                      value={this.state.userName}
                      onChange={this.handleInput}
                    />
                    {
                      (this.props.error || this.state.error) &&
                      <div className={'auth-error show-desktop auth-input-error' + ' auth-has-error'}>
                        <div className="auth-error-i">!</div><div className="auth-error-t">INCORRECT USERNAME</div>
                      </div>
                    }
                  </div>
                </div>
                <div className="auth-input">
                  <label className="auth-input-label">Password</label>
                  <div className="auth-input-row">
                    <input className="auth-input-input" type="password" name="password"
                      value={this.state.password}
                      onChange={this.handleInput}
                    />
                    {
                      (this.props.error || this.state.error) &&
                      <div className={'auth-error show-desktop auth-input-error' + ' auth-has-error'}>
                        <div className="auth-error-i">!</div><div className="auth-error-t">INCORRECT PASSWORD</div>
                      </div>
                    }
                  </div>
                </div>

                {
                  (this.props.error || this.state.error) &&
                <div className="form-error-block show-mobile">
                  <div className={'auth-error form-error-block-row auth-input-error' + ' auth-has-error'}>
                    <div className="auth-error-i">!</div><div className="auth-error-t">INCORRECT USERNAME</div>
                  </div>
                  <div className={'auth-error form-error-block-row auth-input-error' + ' auth-has-error'}>
                    <div className="auth-error-i">!</div><div className="auth-error-t">INCORRECT PASSWORD</div>
                  </div>
                </div>
                }

                <div className="login-group3">
                  <img className="login-remember" src={this.state.rememberMy ? OvalOn : OvalOff} onClick={this.rememberSwitch} /> <span className="login-group3-t">Remember my username</span>
                </div>

                <div className="auth-row">
                  <span className="auth-t3" onClick={this.openRecovery} >Have you forgotten you password?</span>
                </div>
                <div className="auth-row" onClick={() => {
                  this.props.history.push('/masterkey')
                }}>
                  <span className="auth-t3">Download Master Key</span>
                </div>

                <div className="auth-btn-group">
                  <div className="auth-btn auth-b3 auth-btn-loader" onClick={this.sendForm}>
                    <span>Login</span>
                    <Loader
                      style={{ marginLeft: '10px', height: '25px' }}
                      visible={this.state.loader}
                      type="Oval"
                      color="#d7c29a"
                      height={25}
                      width={25}
                    />
                  </div>
                </div>

                {/* <div className="auth-btn-group">
                  <div className="auth-b1">Do you have an online account?</div>
                  <div className="auth-btn auth-b1" onClick={this.openRegister}>Register</div>
                </div> */}
              </div>
            </div>

          </div>
          <div className="auth-right" style={{ background: `url('${news.imageSrc}')` }}>
            <div className="auth_right_content">
              <span>{news.title}</span>
              <a href={news.imageHref} className="news_link" target="_blank">
                <IoIosIcons.IoIosArrowForward />
                <p>Read More</p>
              </a>
            </div>
          </div>
        </div>
        <Modal
          title='Incorrect Login Details'
          hideButton={true}
          modalOpen={this.state.errorModal} //lk modal one open
          content={
            <div className="auth-modal login-error-modal">
              <p>You entered the wrong login details more than three times and your account is now temporarily locked. Please contact us for assistance or recover your password.</p>
              <p style={{ marginTop: '15px' }}>24-hour phone support:</p>
              <p>+44-203-151-7000 / +800-800-00-700</p>
            </div>
          }
          buttons={[
            { onClick: this.openRecovery, text: 'Recover Password', buttonStyles: { width: '159px' }, textStyles: {} },
          ]}
        />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loginSuccess: state.auth.loginSuccess,
  error: state.auth.error,
  userData: state.auth.userData,
  allLoginData: state.auth.allLoginData,
  news: state.content.feed || []
})

export default connect(mapStateToProps)(withRouter(Login))
