import { useCallback, useEffect, useState } from 'react';
import { API } from '../../api/api';

export const useAddMoney = () => {
  const [loading, setLoading] = useState(false);
  const [approvalUrl, setApprovalUrl] = useState();
  const post = useCallback(async ({cardId, amount}) => {
    setApprovalUrl('');
    setLoading(true);
    try {
      const result = await API.post({
        url: '/api/v1/Profile/getc2cpaymenturl', 
        data: JSON.stringify({
          cardId,
          amount: parseFloat(amount) * 100,
          ipAddress: "127.0.0.1",
          userAgent: navigator.userAgent,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        params: {},
      });
      if (result && result.data && result.data.details) {
        setApprovalUrl(result.data.details.approvalUrl);
      }          
    } finally {
      setLoading(false);
    }    
  }, []);  
  //
  const reset = useCallback(() => {
    setApprovalUrl('');
  }, []);
  return {
    loading,
    post,
    reset,
    approvalUrl,
  };
};

export const useFeeFactor = () => {
  const [feeFactor, setFeeFactor] = useState();
  const getFeeFactor = useCallback(async () => {
    const result = await API.get({url: '/api/v1/Profile/getc2cfeepercent'});
    setFeeFactor(result.data * 0.01);
  }, []);  
  return {
    getFeeFactor,
    feeFactor,
  }
}
