import React from 'react';
import Select from '../Select';

import codes from './codes';

export default function CountryCodeSelect(props) {
  return (
    <Select {...props}>
      <option value="" disabled>Select country code</option>
      {codes.map((code, i) => (
        <option value={code[0]} key={i}>+{code[0]} ({code[1]})</option>
      ))}
    </Select>
  );
}
