import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import ModalComponent from 'react-modal';
import { Button } from '../Button';
import CloseIcon from '../../assets/images/close-black.svg';
import scroll from '../../utils/scroll';
import './Modal.css'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    borderRadius          : '10px',
    backgroundColor       : '#ffffff',
    padding               : '0',
    // minWidth              : '518px',
    // minHeight             : '290px',
    zIndex                : 999999
  },
  overlay : {
    backgroundColor       : 'rgba(29, 29, 33, .7)',
    zIndex                : 999999
  }
};

export const Modal = ({
  title,
  content,
  onClose,
  openButtonText,
  buttonStyles,
  textStyles,
  hideButton,
  buttons = [],
  modalOpen,
  contentWrapperStyle,
  errorAfterContent,
  disableDefaultHandler,
  customButtonsBlockStyle,
  customButtonsBlockClass = '',
  outerStyles,
  hideTitle,
  wrapperClassname = ''
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setIsOpen(modalOpen);
  }, [modalOpen]);

  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (modalIsOpen) scroll.disable();
      else scroll.enable();
    }
    return () => {
      if (modalIsOpen) scroll.enable();
    }
  }, [modalIsOpen]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal(){
    if (!disableDefaultHandler) {
      setIsOpen(false);
    }
    if (onClose) {
      onClose()
    }
  }

  const renderButtons = (item, key) => (
    <Button
      onClick={() => {
        // if (key === 0 && errorAfterContent) {
        //   closeModal()
        //   return
        // }
        // if (key !== 0 && errorAfterContent) {
        //   return
        // }
        item.onClick && item.onClick();
        if (!errorAfterContent && !disableDefaultHandler ) {
            closeModal();
        }
      }}
      text={item.text}
      buttonStyles={item.buttonStyles}
      textStyles={item.textStyles}
      whiteButton={item.whiteButton}
      key={key}
    />
  )

  return (
    <div>
      {
        !hideButton &&
          <Button onClick={openModal} text={openButtonText} buttonStyles={buttonStyles} textStyles={textStyles} />
      }
      <ModalComponent
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{...customStyles, ...outerStyles}}
      >
        <div className={wrapperClassname}>
          <div className='close-icon' onClick={closeModal}>
            <img src={CloseIcon} />
          </div>
          {
            !hideTitle &&
              <h2 className='title'>
                {title}
              </h2>
          }
          <div className='content' style={contentWrapperStyle}>
            {content}
            {errorAfterContent}
          </div>
          <div className={`buttonsBlock ${customButtonsBlockClass}`} style={{...customButtonsBlockStyle}}>
            {
              buttons.map(renderButtons)
            }
          </div>
        </div>
      </ModalComponent>
    </div>
  )
}

export const setAppElement = element => {
  ModalComponent.setAppElement(element);
};
