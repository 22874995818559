import React, {Component} from 'react';
import { screenHOC } from '../HOC/screenHOC';
import './auth.css';
import BgImge from '../../assets/images/auth/bg-01.png';
import { Footer } from '../../components/Footer';

class RegisterOk extends Component {
  constructor (props) {
    super(props);
    this.state = {

    }
  }

  openLogin = () => {
    window.location.replace("/login")
  }

  render () {
    return (
      <div className="App">
        <div className="auth-content">
          <div className="auth-c1">
            
          </div>
          <div className="auth-c2" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-successful">
              <h2 className="auth-t1">Registration Successful</h2>
              <p className="auth-t2">Your registration was successful. You can now log in.</p>

              <div className="auth-btn-group">
                <div className="auth-btn auth-b3 auth-btn-loader" onClick={this.openLogin}>Login</div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default RegisterOk
