import { LOGIN, RECOVER_PASSWORD, REGISTER, RESET_ERRORS, SET_TOKEN, VERIFY_CODE } from "../../constants"

const initState = {}

export default function AuthReducer(state = initState, action) {
  switch (action.type) {
    case `${REGISTER}_REQUEST`: {
      return {
        ...state,
        registerDone: null,
      }
    }
    case `${REGISTER}_SUCCESS`: {
      return {
        ...state,
        registerDone: action.payload?.data,
      }
    }
    case `${REGISTER}_FAILED`: {
      return {
        ...state,
        registerDone: null,
        error: action.payload?.data
      }
    }
    case `${LOGIN}_REQUEST`: {
      return {
        ...state,
        loginSuccess: null,
      }
    }
    case `${LOGIN}_SUCCESS`: {
      return {
        ...state,
        loginSuccess: true,
        userData: action.payload?.data,
        allLoginData: action.payload?.response || false
      }
    }
    case `${LOGIN}_FAILED`: {
      return {
        ...state,
        loginSuccess: null,
        error: action.payload?.data
      }
    }
    case `${VERIFY_CODE}_REQUEST`: {
      return {
        ...state,
        codeOk: null,
        codeError: null,
      }
    }
    case `${VERIFY_CODE}_SUCCESS`: {

      return {
        ...state,
        codeOk: action.payload?.data,
        userData: action.payload?.data,
        allResponse: action.payload
      }
    }
    case `${VERIFY_CODE}_FAILED`: {
      return {
        ...state,
        codeOk: null,
        codeError: action.payload?.data
      }
    }
    case `${RECOVER_PASSWORD}_REQUEST`: {
      return {
        ...state,
        recoverSended: null,
        errorRecover: null
      }
    }
    case `${RECOVER_PASSWORD}_SUCCESS`: {
      return {
        ...state,
        recoverSended: action.payload?.data,
        errorRecover: action.payload?.response?.data || false
      }
    }
    case `${RECOVER_PASSWORD}_FAILED`: {
      return {
        ...state,
        recoverSended: null,
        errorRecover: action.payload?.data
      }
    }
    
    case `${SET_TOKEN}`: {
      return {
        ...state,
        userData: action.payload ? {
          ...state.userData,
          token: action.payload
        } : null
      }
    }
    
    case `${RESET_ERRORS}`: {
      return {
        ...state,
        error: null,
        codeError: null,
        errorRecover: null,
        allResponse: null
      }
    }
    default:
      return state
  }
}