import React, {Component} from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal } from '../../components/Modal'
import { screenHOC } from '../HOC/screenHOC'
import './auth.css';
import BgImge from '../../assets/images/auth/bg-02.png';
import { Footer } from '../../components/Footer';

class Forgot extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      cardNumber: '',
      emailError: '',
      cardNumberError: '',
      errorValidate: false,
      modal: false,
      loader: false,
      errorCount: 0,
    }
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value)});
  }

  sendForm = () => {
    if (
      this.validateField('email', this.state.email) &&
      this.validateField('cardNumber', this.state.cardNumber)
    ) {
      this.setState({modal: true});
    }
  }

  validateField(fieldName, value) {
    let error = false;
    let text = '';

    switch(fieldName) {
      case 'email':
        if (value.length < 1) {
          text = 'incorrect email';
          error = true
        }
        break;
      case 'cardNumber':
        if (value.length < 1) {
          text = 'incorrect cardnumber';
          error = true;
        }
        break;
    }

    this.setState({errorValidate: error, [fieldName + 'Error']: text});

    return !error; //lk
  }

  openRecovery = () => {
    window.location.replace("/recovery")
  }

  render () {
    return (
      <div className="App">
        <div className="auth-content">
          <div className="auth-c1">
            
          </div>
          <div className="auth-c2" style={{backgroundImage: `url(${BgImge})`}}>
            <div className="auth-form">
              <h2 className="auth-t1">Password Recovery</h2>
              <p className="auth-t2">Enter the world of Insignia</p>

              <div className="auth-input">
                <label className="auth-input-label">Email Address</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" required name="email"
                    value={this.state.email}
                    onChange={this.handleInput}
                  />
                  <div className={'auth-error auth-input-error' + (this.state.emailError != '' ? ' auth-has-error' : '')}>
                    <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.emailError}</div>
                  </div>
                </div>
              </div>

              <div className="auth-input">
                <label className="auth-input-label">Card Number</label>
                <div className="auth-input-row">
                  <input className="auth-input-input" type="text" required name="cardNumber"
                    value={this.state.cardNumber}
                    onChange={this.handleInput}
                  />
                  <div className={'auth-error auth-input-error' + (this.state.cardNumberError != '' ? ' auth-has-error' : '')}>
                    <div className="auth-error-i">!</div><div className="auth-error-t">{this.state.cardNumberError}</div>
                  </div>
                </div>
              </div>

              <div className="auth-row">
                <span className="auth-t3" onClick={this.openRecovery}>Forgotten your password?</span>
              </div>

              <div className="auth-btn-group">
                <div className="auth-btn auth-b1" onClick={null}>Cancel</div>
                <div className="auth-btn auth-b2 auth-btn-loader" onClick={this.sendForm}>
                  <span>Next</span>
                  <Loader
                    style={{marginLeft: '10px', height: '25px'}}
                    visible={this.state.loader}
                    type="Oval"
                    color="#d7c29a"
                    height={25}
                    width={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title='Your details have been sent'
          hideButton={true}
          modalOpen={this.state.modal}
          content={
            <div className="auth-modal">
              <p>Please note that your username has been sent to your email address. If you have any further questions, please contact the call centre team on the number below:</p>
              <div className="auth-modal-info">
                <p>24-hour phone support:</p>
                <p>+44-203-151-7000 / +800-800-00-700</p>
              </div>
            </div>
          }
          buttons={[
            {onClick: () => {}, text: 'Ok', buttonStyles: {width: '159px'}, textStyles: {}},
          ]}
        />
        <Footer />
      </div>
    )
  }
}

export default Forgot

/*
ошибка с бэка
Your details do not match
The information provided does not match the details in our system. Please contact customer services for assistance. 
*/