import { put, takeLatest, call } from 'redux-saga/effects';
import {
  getProfile,
  postProfile,
  changeProfilePassword,
} from '../../api'

import { CHANGE_PASSWORD, GET_PROFILE, POST_PROFILE } from '../constants';

function* getProfileSaga() {
  try {
    const response = yield call(getProfile);
    yield put({ type: `${GET_PROFILE}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_PROFILE}_FAILED`, payload: error.response });
  }
}

function* postProfileSaga({payload}) {
  try {
    const response = yield call(postProfile, payload);
    yield put({ type: `${POST_PROFILE}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${POST_PROFILE}_FAILED`, payload: error.response });
  }
}

function* changePasswordSaga({payload}) {
  try {
    const response = yield call(changeProfilePassword, payload);
    yield put({ type: `${CHANGE_PASSWORD}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${CHANGE_PASSWORD}_FAILED`, payload: error.response });
  }
}

export function* profileActionWatcher() {
  yield takeLatest(`${GET_PROFILE}_REQUEST`, getProfileSaga)
  yield takeLatest(`${POST_PROFILE}_REQUEST`, postProfileSaga)
  yield takeLatest(`${CHANGE_PASSWORD}_REQUEST`, changePasswordSaga)
}