import { Children, useMemo } from 'react';

export const Wizard = ({step, children}) => {
  const content = useMemo(() => {
    const steps = Children.toArray(children);
    return steps.find(item => item.props.step === step) || null;
  }, [step, children]);
  return content;
};

export const Step = ({children}) => {
  return children;
};
