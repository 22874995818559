import { CHANGE_PASSWORD, GET_PROFILE, POST_PROFILE } from "../constants";

export const getProfileAction = (payload) => ({
  type: `${GET_PROFILE}_REQUEST`,
  payload
});

export const postProfileAction = (payload) => ({
  type: `${POST_PROFILE}_REQUEST`,
  payload
});

export const changePasswordAction = (payload) => ({
  type: `${CHANGE_PASSWORD}_REQUEST`,
  payload
});