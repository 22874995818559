import React, { createRef, useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { CardInfo } from '../CardInfo'
import { CardSlider } from '../CardSlider'

import { connect } from 'react-redux'
import { getCardsProducts } from '../../redux/actions/cardsActions'
import { baseURL } from '../../api/api'
import { withRouter } from 'react-router-dom'
import { isNil } from 'lodash'

let OnSwiperAll = null
let OnSwiperPersonal = null
let OnSwiperBusiness = null

const InsigniaCards = ({dispatch, productsCards, token, history}) => {
  const [selectedCard, setSelectedCard] = useState({})
  const [currentTab, setCurrentTab] = useState(1)
  const [shownCard, setShownCard] = useState(0)
  const [filteredCards, setFilteredCards] = useState([])

  // ICA
  const [icaCard, setIcaCard] = useState({});

  useEffect(() => {
    dispatch(
      getCardsProducts()
    )
    setSelectedCard(productsCards[0])
  }, [token])

  useEffect(() => {
    OnSwiperAll = null
    OnSwiperPersonal = null
    OnSwiperBusiness = null
  }, [history])

  useEffect(() => {
    setFilteredCards(productsCards.filter(i => i.benefits.length))
  }, [productsCards])

  useEffect(() => {
    if (!!currentTab) {
      switch (currentTab) {
        case 1:
          !!OnSwiperAll && OnSwiperAll.slideTo(0)
          break;
        case 2:
          !!OnSwiperPersonal && OnSwiperPersonal.slideTo(0)
          break;
        case 3:
          !!OnSwiperBusiness && OnSwiperBusiness.slideTo(0)
          break;

        default:
          break;
      }
    }
  }, [currentTab])

  const renderCards = (type) => <div className='tabContent'>
  {renderTabContent(type)}
</div>

const renderTabContent = type => {
  switch (type) {
    case 'all':
      return renderAll()
    case 'personal':
      return renderPersonal()
    case 'business':
      return renderBusiness()
    default: return null
  }
}

const handleCardSelect = (card, index) => {
  setSelectedCard(card)
  if (!isNil(index)) {
    setShownCard(index)
    switch (currentTab) {
      case 1:
        OnSwiperAll.slideTo(index)
        break;
      case 2:
        OnSwiperPersonal.slideTo(index)
        break;
      case 3:
        OnSwiperBusiness.slideTo(index)
        break;

      default:
        break;
    }
  }
}

const handleSlide = (item) => {
  setShownCard(item.activeIndex)
}

const onSwiperAllSetter = (sw) => {
  OnSwiperAll = sw
}

const onSwiperPersonalSetter = (sw) => {
  OnSwiperPersonal = sw
}

const onSwiperBusinessSetter = (sw) => {
  OnSwiperBusiness = sw
}

const handleICASelect = (card) =>{
  setIcaCard(card);
}

const renderAll = () => <div style={{width: '100%'}}>
  <CardSlider
    showNameOverCard
    visibleCount={7}
    cardClassName='smallCardsSliderCard'
    cardSliderClassName='smallCardsSlider'
    isNeedSeparator
    onSelectCard={handleCardSelect}
    cardsArr={filteredCards}
    toggleCards
    showCardId={shownCard}
    onSelectICA={handleICASelect}
    cardImage="second" />
  <div className='cardInfo-container'>
    {
      filteredCards &&
        <CardInfo
          cards={[...filteredCards]}
          shownCard={shownCard}
          onChange={handleSlide}
          onSwiper={onSwiperAllSetter} />
    }
  </div>
</div>

const renderPersonal = () => <div className="smallCardsSliderCard-padding">
  <CardSlider
    showNameOverCard
    visibleCount={7}
    cardClassName='smallCardsSliderCard'
    cardSliderClassName='smallCardsSlider'
    isNeedSeparator
    onSelectCard={handleCardSelect}
    cardsArr={filteredCards.filter(i => i.category === 'personal')}
    toggleCards
    showCardId={shownCard}
    onSelectICA={handleICASelect}
    cardImage="second" />
  <div className='cardInfo-container'>
    {
      filteredCards &&
        <CardInfo
          cards={[...filteredCards.filter(i => i.category === 'personal')]}
          shownCard={shownCard}
          onChange={handleSlide}
          onSwiper={onSwiperPersonalSetter} />
    }
  </div>
</div>

const renderBusiness = () => <div className="smallCardsSliderCard-padding">
  <CardSlider showNameOverCard visibleCount={7} cardClassName='smallCardsSliderCard' cardSliderClassName='smallCardsSlider' isNeedSeparator onSelectCard={handleCardSelect} cardsArr={filteredCards.filter(i => i.category === 'business')} toggleCards showCardId={shownCard} onSelectICA={handleICASelect} cardImage="second" />
  <div className='cardInfo-container'>
    {
      filteredCards &&
        <CardInfo
          cards={[...filteredCards.filter(i => i.category === 'business')]}
          shownCard={shownCard}
          onChange={handleSlide}
          onSwiper={onSwiperBusinessSetter} />
    }
  </div>
</div>

  return (
    <>
      <h2 className='cards-header'>Insignia Cards</h2>
      <p className='cards-subheader'>Explore some of our other super-premium, premium, and corporate payment card solutions.</p>
      <Tabs>
        <div className='tabList'>
          <TabList className='tabList-tabs' >
            <Tab className='tabList-tab' selectedClassName='tabList-tab-active' onClick={() => {setCurrentTab(1); setShownCard(0)}}>All</Tab>
            <Tab className='tabList-tab' selectedClassName='tabList-tab-active' onClick={() => {setCurrentTab(2); setShownCard(0)}}>Personal</Tab>
            <Tab className='tabList-tab' selectedClassName='tabList-tab-active' onClick={() => {setCurrentTab(3); setShownCard(0)}}>Business</Tab>
          </TabList>
        </div>

        <TabPanel className={'tabPanel'}>
          {renderCards('all')}
        </TabPanel>
        <TabPanel>
          {renderCards('personal')}
        </TabPanel>
        <TabPanel>
          {renderCards('business')}
        </TabPanel>
      </Tabs>
      {
        filteredCards?.[shownCard]?.benefits?.length
          ? <div className='benefits'>
              <div className='benefits-header'>
                <div className='line' />
                <h3>KEY BENEFITS</h3>
                <div className='line' />
              </div>

              <div className='benefits-content'>
                {
                  filteredCards?.[shownCard]?.benefits.map((item, key) => {
                    return <div key={key} className='benefits-content-card'>
                      <img
                        className="show-desktop"
                        src={`${baseURL}/${item.icon}`}
                        alt=''
                        style={{width: 40, height: 40, margin: '0 auto'}}
                      />
                      <img className="show-mobile benefits-img-mobile" src={`/assets/${item.icon}`} />
                      <div className='benefits-content-card-title'>{item.title}</div>
                      <div className='hr' />
                      <div className='benefits-content-card-text'>{item.description}</div>
                    </div>
                  })
                }
              </div>
            </div>
          : null
      }
    </>
  )
}

const mapStateToProps = state => ({
  productsCards: state.cards.cardsProducts || [],
  news: state.content.feed || [],
  token: state.stuff.token
})

export default connect(mapStateToProps)(withRouter(InsigniaCards))
