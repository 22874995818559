import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { CardSlider } from '../../components/CardSlider';
import { screenHOC } from '../HOC/screenHOC';
import CardOption from './components/CardOption';
import Input from './components/Input';
import ArrowIcon from '../../assets/images/arrow.svg';
import WriteIcon from '../../assets/images/ManageCards/write.png';
import AttentionIcon from '../../assets/images/ManageCards/attention.png';
import EyeIcon from '../../assets/images/ManageCards/eye.png';
import KeyIcon from '../../assets/images/ManageCards/key.png';
import LockIcon from '../../assets/images/ManageCards/lock.png';
import PasswordIcon from '../../assets/images/ManageCards/password.png';
import InsigniaCard from '../../assets/images/ManageCards/insignia-card.png';
import NewCard from '../../assets/images/ManageCards/group.png';
import './ManageCards.css';
import { Modal } from '../../components/Modal';
import Radio from './components/Radio';
import Card from '../../assets/images/royal-digital-card.png';
import { connect } from 'react-redux';
import classNamesBind from 'classnames/bind';
import { insertSpaces } from '../../redux/helpers';
import { baseURL } from '../../api/api';
// <<<<<<< HEAD
// import { cardManageBlockAction, changePinAction, getCardsAll, getCardsLatest, getCardSteatmentsViewAction, getInvoicesCardAction, lostCardAction, manageCardNameAction, resetCardsError, resetData, viewPinAction } from '../../redux/actions/cardsActions';
// =======
import { cardManageBlockAction, changePinAction, getCardInvoicesViewAction, getInvoicesStatemensDownloadAction, getInvoicesDownloadAction, getCardsAll, getCardsLatest, getCardSteatmentsViewAction, getInvoicesCardAction, lostCardAction, manageCardNameAction, resetCardsError, resetData, viewPinAction } from '../../redux/actions/cardsActions';
// >>>>>>> 5a66796e09a1443ba420266d1dd85d799fbcb16a
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Preloader } from '../../components/Preloader';
import { Button } from '../../components/Button';
import MessagePopUp from '../../components/MessagePopUp';
import Dialog from '../../components/Dialog';
import DialogWindow from '../../components/DialogWindow';
import CountryCodeSelect from '../../components/CountryCodeSelect';
import ReactNumberFormat from 'react-number-format';
import { GET_CARD_3DS, SET_CARD_3DS } from '../../redux/constants';

import { AddMoney } from '../../features/AddMoney';

const SecurityIcon = () => (
    <svg style={{'fontSize': '45px', 'marginTop': '-8px', 'marginLeft': '-4px', 'marginRight': '-5px',  'width': '1em', 'height': '1em','vertical-align': 'middle', 'fill': 'currentColor', 'overflow': 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M511.744 818.464l0.32 0.192 0.4-0.24c8.8-5.472 18.864-11.952 29.664-19.2a1035.936 1035.936 0 0 0 89.92-67.456c78.88-66.608 125.888-130.464 125.888-182.304V320.048c0-26.56 3.056-20.096-5.824-22-2.88-0.624-5.76-1.168-12.592-2.4-37.616-6.88-75.248-17.6-111.392-32.96a809.12 809.12 0 0 1-105.392-54.144 171.52 171.52 0 0 0-10.576-6.208c-1.92 0.96-4.352 2.4-10.56 6.208a797.6 797.6 0 0 1-105.776 54.16c-36.096 15.328-108.832 35.6-146.48 42.496-11.424 2.08-22.832-5.408-24.928-16.752-2.112-11.328 5.888-22.224 17.312-24.32 34.72-6.352 104.512-25.792 137.584-39.84a755.84 755.84 0 0 0 100.224-51.296c17.664-10.8 22.032-12.944 32.448-12.992 10.56-0.048 15.2 2.208 32.832 13.008a767.136 767.136 0 0 0 99.872 51.28c33.088 14.064 67.744 23.936 102.464 30.288 7.28 1.328 10.368 1.92 13.824 2.656 38.992 8.352 39.024 8.368 39.024 62.944v229.28c0 68.064-52.992 140.048-140.704 214.096C603.824 810.4 524 864 512 864c-11.984 0-90.976-53.664-146.304-100.688C278.56 689.28 225.392 617.472 224 549.888V285.04c0-11.52 9.424-16.32 21.04-16.32a20.96 20.96 0 0 1 21.024 20.88v259.856c1.072 51.424 48.432 115.376 126.992 182.144a1051.52 1051.52 0 0 0 89.28 67.6c10.72 7.28 20.688 13.76 29.408 19.264zM688.032 388.8l11.488 11.632a16 16 0 0 1 0 22.496L489.84 635.2a16 16 0 0 1-22.768 0l-110.592-111.952a16 16 0 0 1 0-22.496l11.488-11.632a16 16 0 0 1 22.752 0l87.728 88.8L665.28 388.8a16 16 0 0 1 22.752 0z"  /></svg>

)

// export const manageCardName = ({id, data}) => API.post({
    // data: {
    //     currentName: data.currentName,
    //     newName: data.newName,
    //     newNameConfirm: data.newNameConfirm
    //   },

// export const viewPin = (id) => API.post({
// export const changePin = ({id, data}) => API.post({
//     data: {
//     currentPin: data.currentPin,
//     newPin: data.newPin,
//     newPinConfirm: data.newPinConfirm,
//   },
// export const lostCard = ({id, data}) => API.post({
//     data: {
//     type: "Lost",
//     comment: data.comment
//   },
// export const blockCard = (id) => API.post({

const OnSwiper = null

const btnstyles = {
    borderRadius: '8px',
    border: '1px solid transparent',
    backgroundColor: '#272727',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '400px',
    marginBottom: '1rem',
    textTransform: 'unset',
    padding: 0
}

const ManageCards = ({
    dispatch,
    manageCardNameStatus,
    manageCardNameError,
    viewPinStatus,
    viewPinError,
    changePinStatus,
    changePinError,
    lostCardStatus,
    lostCardError,
    blockCardStatus,
    blockCardError,
    cardsArr,
    history,
    invoices,
    steatments,
    statementsData,
    invoicesData,
    productsCards,
    onSet3dsPhoneNumber,
    onReset3dsPhoneNumber,
    onGet3dsPhoneNumber,
    set3dsError,
    set3dsSuccess,
    posting3ds,
    loading3ds,     
    current3ds,
}) => {
    const CurrentNameRef = useRef()
    const NewNameRef = useRef()
    const RetypeNewNameRef = useRef()

    const CurrentPinRef = useRef()
    const NewPinRef = useRef()
    const RetypeNewPinRef = useRef()


    const [selectedCard, setSelectedCard] = useState({});
    const [shownCard, setShownCard] = useState(0);
    const [blockUnblock, setBlockUnblock] = useState('')
    const [lostType, setLostType] = useState('')
    const [LostComment, setLostComment] = useState('')
    const [error, setError] = useState('');
    const [padding, setPadding] = useState(null);
    const [update3dsVisible, setUpdate3dsVisible] = useState(false);
    const [update3dsError,  setUpdate3dsError] = useState('');
    const [update3dsSuccess, setUpdate3dsSuccess] = useState(false);
    const [addMoneyCard, setAddMoneyCard] = useState(null);

    // ICA
    const [icaCard, setIcaCard] = useState({});

    // Errors
    const [newNameError, setNewNameError] = useState(false)
    const [pinError, setPinError] = useState(false)
    const [blockUnblockError, setBlockUnblockError] = useState(false)
    const [lostStollenError, setLostStollenError] = useState(false);

    // End of errors
    const [showNameYourCardModal, setShowNameYourCardModal] = useState(false);
    const [showSuccessfullyChangeNameModal, setShowSuccessfullyChangeNameModal] = useState(false);
    const [showViewYourPINModal, setShowViewYourPINModal] = useState(false);
    const [showViewYourPINModalError, setShowViewYourPINModalError] = useState(false);
    const [showChangeYourPINModal, setShowChangeYourPINModal] = useState(false);
    const [showSuccessfullyChangeYourPINModal, setShowSuccessfullyShowChangeYourPINModal] = useState(false);
    const [showUnblockModal, setShowUnblockModal] = useState(false);
    const [showSuccessfullyUnblockModal, setShowSuccessfullyUnblockModal] = useState(false);
    const [showLostOrStolen, setShowLostOrStolen] = useState(false);
    const [showSuccessfullyLostOrStolenModal, setShowSuccessfullyLostOrStolenModal] = useState(false);
    const [cardOptions, setCardOptions] = useState(false);

    const [update3dsNumber, setUpdate3dsNumber] = useState({});    

    useEffect(() => {
        if (current3ds) {
            setUpdate3dsNumber({...current3ds});
        }
    }, [current3ds, setUpdate3dsNumber]);

    useEffect(() => {
        if (update3dsVisible && onGet3dsPhoneNumber) {
            onGet3dsPhoneNumber(selectedCard?.iclCardId);
        }
    }, [update3dsVisible, onGet3dsPhoneNumber, selectedCard?.iclCardId]);

    const handleChangeUpdate3dsNumber = useCallback((e) => {
        const {name, value} = e.currentTarget;
        setUpdate3dsNumber(state => ({
            ...state,
            [name]: value,
        }));
    }, [setUpdate3dsNumber]);

    const handle3dsNumberSubmit = useCallback((e) => {
        e.preventDefault();
        const {countryCode, phone} = update3dsNumber;
        if (!phone) setUpdate3dsError('Phone is required');
        else if (phone.length < 7) setUpdate3dsError('The phone number format is incorrect');
        else {
            setUpdate3dsError('');            
            onSet3dsPhoneNumber && onSet3dsPhoneNumber({iclCardId: selectedCard.iclCardId, countryCode, phone});
        }
    }, [update3dsNumber, setUpdate3dsError]);

    const handleCardSelect = (card, index, e) => {
        localStorage.setItem('selectedCardIndex', index)
        localStorage.setItem('selectedCard', JSON.stringify(card))
        setSelectedCard(card);
        setShownCard(index);
        /* @todo: remove?
        if (card){
            dispatch(
                getInvoicesCardAction(card.productId || 1)
            )
        }*/
    };
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        if (update3dsVisible) {
            onReset3dsPhoneNumber && onReset3dsPhoneNumber();
            setUpdate3dsNumber({})
        }
    }, [update3dsVisible, onReset3dsPhoneNumber, setUpdate3dsNumber]);

    useEffect(() => {
        if (set3dsSuccess) {
            setUpdate3dsVisible(false);
            setUpdate3dsSuccess(true);
        }
    }, [set3dsSuccess, setUpdate3dsVisible, setUpdate3dsSuccess]);

    useEffect(() =>{
        const index = localStorage.getItem('selectedCardIndex');
        const card = localStorage.getItem('selectedCard');

        if (index) {
            setShownCard(index);
        }

        if (card) {
            setSelectedCard(card);
        }

        if(selectedCard){
            dispatch(
                getInvoicesCardAction(selectedCard.productId || 1)
            )
        }
    }, [])

    const handleICASelect = (card) =>{
        setIcaCard(card);
    }

    useEffect(() => {
        if (cardsArr && cardsArr.length) {
            if (selectedCard) {
                const found = cardsArr.filter(item => item.id === selectedCard.id)[0];
                if (!found) setSelectedCard(cardsArr[0])
            } else {
                setSelectedCard(cardsArr[0])
            }
        }
        //cardsArr && cardsArr.length && setSelectedCard(cardsArr[0])
    }, [cardsArr])

    useEffect(() => {
        if (CurrentNameRef.current) {
            CurrentNameRef.current.value = selectedCard.cardName
        }
    }, [selectedCard])

    useEffect(() => {
        // console.log('[ManageCards] [useEffect location]');
        dispatch(
            resetData()
        )
        dispatch(
            getCardsAll()
        )

        if (history.location?.state?.item) {
            setSelectedCard(history.location.state.item)
            setShownCard(history.location.state.index)
            // refs[history.location.state.item.id]?.current?.scrollIntoView({
            //     behavior: 'smooth',
            //     block: 'start',
            // });
        } else {
            if (isMobile) {
                history.push('/cards');
            }
        }

        if (history.location?.state?.cardOptions) {
            setCardOptions(true)
        }
    }, [])

    useEffect(() => {
        if (window.location.pathname === '/') {
            setCardOptions(false)
        }
    }, [window.location.pathname])

    // card actions
    useEffect(() => {
        if (!isNil(manageCardNameStatus)) {
            setShowNameYourCardModal(false)
            setShowSuccessfullyChangeNameModal(true)
            dispatch(
                getCardsAll()
            )
        }
    }, [manageCardNameStatus])

    useEffect(() => {
        if (!isNil(viewPinStatus)) {
            setShowViewYourPINModal(true)
        }
    }, [viewPinStatus])

    useEffect(() => {
        if (!isNil(viewPinError) || viewPinError === '') {
            setShowViewYourPINModalError(true)
        }
    }, [viewPinError])

    useEffect(() => {
        if (!isNil(changePinStatus)) {
            setShowChangeYourPINModal(false)
            setShowSuccessfullyShowChangeYourPINModal(true)
        }
    }, [changePinStatus])

    useEffect(() => {
        if (!isNil(blockCardStatus)) {
            setShowUnblockModal(false)
            setShowSuccessfullyUnblockModal(true)
        }
    }, [blockCardStatus])

    useEffect(() => {
        if (!isNil(lostCardStatus)) {
            setShowLostOrStolen(false)
            setShowSuccessfullyLostOrStolenModal(true)
        }
    }, [lostCardStatus])

    const handleManageCardName = () => {
        const data = {
            currentName: CurrentNameRef.current.value,
            newName: NewNameRef.current.value,
            newNameConfirm: RetypeNewNameRef.current.value
        }
        if (data.currentName === data.newName) {
            setNewNameError('You provided the same card name that this card has now.')
        } else if (data.newName !== data.newNameConfirm) {
            setNewNameError('New name doesn`t match the retyped one.')
        } else if (data.currentName && !data.newName) {
            setNewNameError('Please provide new name. ')
        } else if (data.currentName && data.newName && ! data.newNameConfirm) {
            setNewNameError('Please retype new name.')
        } else {
            dispatch(
                manageCardNameAction({
                    id: selectedCard.id,
                    data: {
                        currentName: CurrentNameRef.current.value,
                        newName: NewNameRef.current.value,
                        newNameConfirm: RetypeNewNameRef.current.value
                    }
                })
            );
            
            selectedCard.cardName = NewNameRef.current.value;
        }
    }

    const handleViewPin = () => {
        setShowViewYourPINModal(!showViewYourPINModal)
    }

    const handleChangePin = () => {
        const data = {
            currentPin: CurrentPinRef.current.value,
            newPin: NewPinRef.current.value,
            newPinConfirm: RetypeNewPinRef.current.value,
        }
        if (!data.currentPin) {
            setPinError('Pin must have 4 digits.')
        } else if (data.currentPin === data.newPin) {
            setPinError('New pin and current pin must be different.')
        } else if (data.newPin.length === 0 ||  data.newPinConfirm.length === 0) {
            setPinError('Pin must have 4 digits.')
        } else if (data.newPin !== data.newPinConfirm) {
            setPinError('New pin doesn`t match retyped one.')
        } else if (
            data.currentPin.length !== 4 ||
            data.newPin.length !== 4 ||
            data.newPinConfirm.length !== 4
        ) {
            setPinError('Pin must have 4 digits.')
        } else if (
            data.currentPin &&
            data.currentPin.length === 4 &&
            data.currentPin !== data.newPin &&
            data.newPin === data.newPinConfirm &&
            data.newPin.length === 4 &&
            data.newPinConfirm.length === 4
        ) {
            dispatch(
                changePinAction({
                    id: selectedCard.id,
                    data
                })
            )
            setPinError(false)
        }

        // setShowSuccessfullyShowChangeYourPINModal(!showSuccessfullyChangeYourPINModal)
    }

    const handleBlockUnblock = () => {
        if (!blockUnblock) {
            setBlockUnblockError(true)
        } else {
            setBlockUnblockError(false)
            dispatch(
                cardManageBlockAction({
                    id: selectedCard.id,
                    data: {
                        type: blockUnblock
                    }
                })
            )
        }
    }

    const handleLostOrStollen = () => {
        if (!lostType) {
            setLostStollenError(true)
        } else {
            dispatch(
                lostCardAction({
                    id: selectedCard.id,
                    data: {
                        type: lostType,
                        comment: LostComment
                    }
                })
            )
            setLostStollenError(false)
        }
    }

    const createError = (text) => (
        <div
            className={'auth-error auth-input-error' + (text != '' ? ' auth-has-error' : '')}
            style={{marginTop: ''}}
        >
            <div className="auth-error-i">!</div>
            <div className="auth-error-t">{text}</div>
        </div>
    )

    const renderMultipleErrors = (errors) => {
        return Object.values(errors).map(i => createError(i))
    }

    const handleViewStatement = () => {
        dispatch(
            getInvoicesStatemensDownloadAction(steatments[0].id)
        )
        setSelectedCard(selectedCard)
        setShownCard(shownCard)
    }
    const handleViewInvoice = () => {
        dispatch(
            getInvoicesDownloadAction(invoices[0].id)
        )
        setSelectedCard(selectedCard)
        setShownCard(shownCard)
    }

    const handleManageCards = () => {
        history.push('/manage-cards', { item: selectedCard, index: shownCard, cardOptions: true });
        setCardOptions(true);
        // history.push('/cards', {selectedCard, shownCard})
    }

    const renderBackgroundImg = (item) => {        
      let bg = '';
  
      for (let i = 0; i < productsCards.length; i++) {
        if (productsCards[i].id === item.productId) {
          bg = productsCards[i].images?.front
        }
      }
  
      return `${baseURL}/${bg}`;
    }

    const getColor = (item) => {
      let color = '#ffffff';
  
      for (let i = 0; i < productsCards.length; i++) {
        if (productsCards[i].id === item.productId) {
          color = productsCards[i].textColor
        }
      }
      return color
    }

    const renderCardNumber = number => {
      const result = []
      if (number) {
        for (let i = 0; i < number.length; i++) {
          if (i % 4 === 0) {
            result.push(' ')
            result.push(number[i])
          } else {
            result.push(number[i])
          }
        }
        return result.join('')
      } else {
        return ''
      }
    }

    const renderOneCard = (item) => {
        return (
            <div
                className={classNamesBind('card-item')}
                style={{ backgroundImage: `url(${renderBackgroundImg(item)})` }}
            >
                <div className='currency due-space'>
                    <div style={{ color: getColor(item) }}>{item.currency}</div>
                </div>

                <p
                    className='card-item-number'
                    style={{ color: getColor(item) }}
                >
                    {renderCardNumber(item.cardNumber)}
                </p>

                <p
                    className='card-item-number'
                    style={{ color: getColor(item) }}
                >
                    {item.cardName}
                </p>
            </div>
        );
    };

    const renderCurrentCard = () => {
        const index = history?.location?.state?.index || 0;

        return (
            <div className="manage-cards-container">
                {selectedCard && renderOneCard(selectedCard)}

                <div className="cards-list-mobile--card-actions">
                    <div className="cards-button cards-button-name">
                        <Button
                            text={'Back'}
                            onClick={() => history.push('/')}
                        />
                    </div>
                    <div className="cards-button cards-button-name">
                        <Button
                            text={'Name Your Card'}
                            onClick={() => setShowNameYourCardModal(!showNameYourCardModal)}
                        />
                    </div>

                    <div className="cards-button cards-button-pin">
                        <Button
                            text={'Change Your PIN'}
                            onClick={() => setShowChangeYourPINModal(!showChangeYourPINModal)}
                        />
                    </div>

                    <div className="cards-button cards-button-block">
                        <Button
                            text={'Block / Unblock'}
                            onClick={() => setShowUnblockModal(!showUnblockModal)}
                        />
                    </div>

                    <div className="cards-button cards-button-stolen">
                        <Button
                            text={'Lost or Stolen Card'}
                            onClick={() => setShowLostOrStolen(!showLostOrStolen)}
                        />
                    </div>

                    <div className="cards-button cards-button-3ds">
                        <Button
                            text={'Update 3D Secure Number'}
                            onClick={() => setUpdate3dsVisible(true)}
                            textStyles={{'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis'}}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const buttonStyleProps = {
        buttonStyles: (statementsData || invoicesData) ? {...btnstyles} : {width: '400px', marginBottom: '1rem', borderRadius: '8px'},
        textStyles: {textTransform: 'unset'},
    }

    const renderButtons = () => {
        if (selectedCard) {
            if (cardOptions) {
                return (
                    <div>
                        <CardOption 
                          icon={ArrowIcon}
                          title="Back"
                          turned
                          description="Return to previous menu"
                          onClick={() => history.push('/')}
                        />
                        <CardOption
                            icon={WriteIcon}
                            title="Name Your Card"
                            description="Create custom name"
                            onClick={() => setShowNameYourCardModal(!showNameYourCardModal)}
                        />
                        {/*<CardOption
                            icon={EyeIcon}
                            title="View Your PIN"
                            description="Receive a PIN reminder by SMS"
                            onClick={() => {
                                dispatch(
                                    viewPinAction(selectedCard.id)
                                )
                            }}
                        />*/}
                        <CardOption
                            icon={KeyIcon}
                            title="Change Your PIN"
                            description="For security you will be issued a new card"
                            onClick={() => setShowChangeYourPINModal(!showChangeYourPINModal)}
                        />
                        <CardOption
                            icon={LockIcon}
                            title="Block / Unblock"
                            description="Temporarily or permanently block your card"
                            onClick={() => setShowUnblockModal(!showUnblockModal)}
                        />
                        <CardOption
                            icon={AttentionIcon}
                            title="Lost or Stolen Card"
                            description="Report your card lost or stolen"
                            onClick={() => setShowLostOrStolen(!showLostOrStolen)}
                        />
                        <CardOption
                            icon={<SecurityIcon />}
                            title="Update 3D Secure Number"
                            description="Update 3D Secure Number"
                            onClick={() => setUpdate3dsVisible(true)}
                        />
                    </div>
                )
            } else {
                if (selectedCard && icaCard.cardNumber !== "ICA" ) {
                    return (
                        <div className="content_buttons">                            
                            {invoices && invoices[0] ? <Button text="View Invoice" buttonStyles={(statementsData || invoicesData) ? {...btnstyles} : {width: '400px', marginBottom: '1rem', borderRadius: '8px'}} textStyles={{textTransform: 'unset'}} onClick={handleViewInvoice}/> : null}
                            {steatments && steatments[0] ? <Button text="View Statement" buttonStyles={(statementsData || invoicesData) ? {...btnstyles} : {width: '400px', marginBottom: '1rem', borderRadius: '8px'}} textStyles={{textTransform: 'unset'}} onClick={handleViewStatement}/> : null}
                            <Button text="Manage Card" {...buttonStyleProps} onClick={handleManageCards} /> 
                            {selectedCard.currency === 'EUR' ? <Button text="Add Money" {...buttonStyleProps} onClick={() => setAddMoneyCard(selectedCard)} /> : null}
                        </div>
                    )
                }
            }
        }
    }

    if (cardsArr) {
        return (
            <div style={{ width: '100%' }}>
                <div className="Content show-desktop">
                    <div style={{ marginBottom: '50px', width: '100%', }}>
                        <CardSlider
                            visibleCount={7}
                            cardClassName='smallCardsSliderCard-manageCards'
                            cardSliderClassName='smallCardsSlider-manageCards'
                            onSelectCard={handleCardSelect}
                            onSelectICA={handleICASelect}
                            cardsArr={cardsArr}
                            toggleCards
                            showCardId={shownCard}
                            dontRotate
                            goToSlideNumber={history?.location?.state?.index && Math.ceil((history.location.state.index + 1 )/ 3)}
                            renderButtons={renderButtons}
                            showICA
                        />
                    </div>
                </div>

                <div className="Content-mobile show-mobile">
                    <h2 className="cards-header">Manage the Card</h2>

                    {renderCurrentCard()}
                </div>

                {/* {  Name your card modal } */}
                <Modal
                    title="Name Your Card"
                    hideButton={true}
                    modalOpen={showNameYourCardModal}
                    content={
                        <div>
                            <Input
                                label="Current Name"
                                ref={CurrentNameRef}
                                value={selectedCard?.cardName || ''}
                            />
                            <Input
                                label="New Name"
                                ref={NewNameRef}
                                maxLength={21}
                            />
                            <Input
                                label="Retype New Name"
                                ref={RetypeNewNameRef}
                                maxLength={21}
                            />
                        </div>
                    }
                    onClose={() => {
                        setShowNameYourCardModal(false)
                        dispatch(resetCardsError())
                        setNewNameError(false)
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowNameYourCardModal(false)
                                dispatch(resetCardsError())
                            },
                            text: 'Cancel',
                            buttonStyles: {
                                width: '159px',
                                backgroundColor: 'rgb(255 255 255)',
                                border: 'solid 1px #000000',
                                // marginBottom: 45,
                            },
                            textStyles: { color: 'black' },
                            whiteButton: true,
                        },
                        {
                            onClick: handleManageCardName,
                            text: 'Save',
                            buttonStyles: { width: '159px', marginBottom: 0},
                            textStyles: {},

                        },
                    ]}
                    // customButtonsBlockStyle={{marginBottom: 45}}
                    errorAfterContent={
                        <>
                        {
                            manageCardNameError && createError('Something went wrong. Please try again or contact customer service.')
                        }
                        {
                            !manageCardNameError && newNameError && createError(newNameError)
                        }
                        </>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully changed the name of your card Modal} */}
                <Modal
                    hideButton={true}
                    modalOpen={showSuccessfullyChangeNameModal}
                    content={
                        <div className="modal-unblock">
                            You have successfully changed the name of your card
                        </div>
                    }
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    onClose={() => {
                        setShowSuccessfullyChangeNameModal(!showSuccessfullyChangeNameModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyChangeNameModal(!showSuccessfullyChangeNameModal)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            // buttonStyles: {
                            //     marginBottom: 76
                            // }
                        },
                    ]}
                />
                <AddMoney card={addMoneyCard} onClose={() => setAddMoneyCard(null)} />

                {/* {View Your PIN Modal} */}
                {/*<Modal
                    title="View Your PIN"
                    hideButton={true}
                    modalOpen={showViewYourPINModal}
                    content={
                        <div style={{ marginTop: '-30px', width: '518px' }}>
                            <div style={{ fontSize: '16px' }}>XXXX XXXX XXXX {selectedCard?.cardNumber?.slice(selectedCard?.cardNumber.length - 4, selectedCard?.cardNumber.length)}</div>
                            <img style={{ marginTop: '50px' }} src={PasswordIcon} alt="password icon" />
                            <div style={{ marginTop: '15px' }}>
                                A PIN reminder has been sent to you by SMS
                            </div>
                        </div>
                    }
                    onClose={() => setShowViewYourPINModal(!showViewYourPINModal)}
                    buttons={[
                        {
                            onClick: handleViewPin,
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: 150
                            }
                        },
                    ]}
                />*/}

                {/* {View Your PIN Modal Error} */}
                {/*<Modal
                    title="View Your PIN"
                    hideButton={true}
                    modalOpen={showViewYourPINModalError}
                    content={
                        <div style={{ marginTop: '30px', width: '518px' }}>
                            <div style={{ marginTop: '15px' }}>
                                <div className={'auth-error auth-input-error' + ' auth-has-error'}>
                                    <div className="auth-error-i">!</div>
                                    <div className="auth-error-t" style={{textAlign: 'left'}}>Something went wrong. Please try again or contact customer service.</div>
                                </div>
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowViewYourPINModalError(false)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowViewYourPINModalError(false)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: 150
                            }
                        },
                    ]}
                />*/}

                {/* {Change Your PIN Modal} */}
                <Modal
                    title="Change Your PIN"
                    hideButton={true}
                    modalOpen={showChangeYourPINModal}
                    content={
                        <div>
                            <Input label="Current Pin" ref={CurrentPinRef} maxLength={4} minLength={4} type='number' />
                            <Input label="New Pin" ref={NewPinRef} maxLength={4} minLength={4} type='number' />
                            <Input label="Retype New Pin" ref={RetypeNewPinRef} maxLength={4} minLength={4} type='number' />
                            <div className="manage-cards__form-description">
                                Once you submit your request for a new PIN, you will be issued a new card. If you want to change the PIN on your existing card, please visit a local ATM.
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowChangeYourPINModal(!showChangeYourPINModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                dispatch(resetCardsError())
                                setShowChangeYourPINModal(!showChangeYourPINModal)
                            },
                            text: 'Cancel',
                            buttonStyles: {
                                width: '159px',
                                backgroundColor: 'rgb(255 255 255)',
                                border: 'solid 1px #000000',
                                // marginBottom: 32
                            },
                            whiteButton: true,
                            textStyles: { color: 'black' },
                        },
                        {
                            onClick: handleChangePin,
                            text: 'Save',
                            buttonStyles: { width: '159px' },
                            textStyles: {},
                        },
                    ]}
                    // customButtonsBlockStyle={{marginBottom: 32}}
                    errorAfterContent={
                        <>
                        {changePinError && createError('Something went wrong. Please try again or contact customer service.')}
                        {
                            !changePinError && pinError && createError(pinError)
                        }
                        </>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully changed the PIN Modal} */}
                <Modal
                    hideButton={true}
                    modalOpen={showSuccessfullyChangeYourPINModal}
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    content={
                        <div className="modal-unblock">
                            You have successfully changed the PIN of your card
                        </div>
                    }
                    onClose={() => {
                        setShowSuccessfullyShowChangeYourPINModal(!showSuccessfullyChangeYourPINModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyShowChangeYourPINModal(!showSuccessfullyChangeYourPINModal)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            // buttonStyles: {
                            //     marginBottom: 76
                            // }
                        },
                    ]}
                />

                {/* {Block/Unblock Modal} */}
                <Modal
                    title="Block/Unblock"
                    hideButton={true}
                    modalOpen={showUnblockModal}
                    content={
                        <div className="modal-unblock">
                            <p className="show-mobile modal-unblock-description">
                                Here you may temporarily or permanently block your card
                            </p>

                            <div className="modal-unblock-inner">
                                <span className="show-mobile">Your Card</span>
                                XXXX XXXX XXXX {selectedCard?.cardNumber?.slice(selectedCard?.cardNumber.length - 4, selectedCard?.cardNumber.length)}
                            </div>
                            
                            <div className="modal-unblock-radios">
                                <Radio name='block-unblock' title="Block" onClick={setBlockUnblock} />
                                <Radio name='block-unblock' title="Unblock" onClick={setBlockUnblock} />
                                {/*<Radio name='block-unblock' title="Block Temporarily" onClick={setBlockUnblock} />*/}
                            </div>
                        </div>
                    }
                    onClose={() => {
                        dispatch(resetCardsError())
                        setShowUnblockModal(!showUnblockModal)
                        setBlockUnblockError(false)
                    }}
                    customButtonsBlockClass='modal-unblock-buttons'
                    buttons={[
                        {
                            onClick: handleBlockUnblock,
                            text: 'Submit',
                        },
                    ]}
                    errorAfterContent={
                        <div style={{marginTop: 25, marginBottom: -15}}>
                        {blockCardError  &&   createError('Something went wrong. Please try again or contact customer service.')}
                        {!blockCardError && blockUnblockError && createError('Please select an action.')}
                        </div>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully Block/Unblock Modal} */}
                <Modal
                    title="Request Successful"
                    hideButton={true}
                    modalOpen={showSuccessfullyUnblockModal}
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    content={
                        <div className="modal-unblock">
                            <div className="modal-unblock-inner">
                                Your changes have been successfully implemented. If you have any further
                                queries, please contact customer service for assistance. 24-hour phone
                                support: +44-203-151-7000 / +800-800-00-700
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowSuccessfullyUnblockModal(!showSuccessfullyUnblockModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyUnblockModal(!showSuccessfullyUnblockModal)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: isMobile ? 0 : 121
                            }
                        },
                    ]}
                />

                {/* {Lost or Stolen Modal} */}
                <Modal
                    title="Lost or Stolen"
                    hideButton={true}
                    modalOpen={showLostOrStolen}
                    content={
                        <div className="modal-unblock">
                            <div className="modal-unblock-inner">
                                <span className="show-mobile">Your Card</span>
                                XXXX XXXX XXXX {selectedCard?.cardNumber?.slice(selectedCard?.cardNumber.length - 4, selectedCard?.cardNumber.length)}
                            </div>

                            <div className="modal-unblock-radios">
                                <Radio name='lost-or-stollen' title="Lost" onClick={setLostType} />
                                <Radio name='lost-or-stollen' title="Stolen" onClick={setLostType} />
                            </div>

                            <div className="modal-stolen-comment">
                                <label
                                    style={{
                                        float: 'left',
                                        marginTop: '12px',
                                        color: '#88888b',
                                        fontFamily: 'ProximaNova',
                                    }}
                                    htmlFor="textarea">
                                    Leave a comment
                                </label>
                                <textarea
                                    id="textarea"
                                    cols="40"
                                    value={LostComment}
                                    onChange={e => setLostComment(e.target.value)}
                                    rows="10"></textarea>
                            </div>
                        </div>
                    }
                    onClose={() => {
                        dispatch(resetCardsError())
                        setShowLostOrStolen(!showLostOrStolen)
                        setLostComment('')
                        setLostStollenError(false)
                    }}
                    customButtonsBlockClass='modal-stolen-buttons'
                    buttons={[
                        {
                            onClick: handleLostOrStollen,
                            text: 'Submit',
                        },
                    ]}
                    errorAfterContent={
                        <div style={{marginTop: '20px', marginBottom: '-15px'}}>
                            {lostCardError && createError('Something went wrong. Please try again or contact customer service.')}
                            {!lostCardError && lostStollenError && createError('Please select the cause.')}
                        </div>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully Lost or Stolen Modal} */}
                <Modal
                    title="Your message has been sent"
                    hideButton={true}
                    modalOpen={showSuccessfullyLostOrStolenModal}
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    content={
                        <div className="modal-unblock">
                            <div className="modal-unblock-inner">
                                One of our representatives will respond to you shortly.
                                <br />
                                <br />
                                24-hour phone support: +44-203-151-7000 / +800-800-00-700
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowSuccessfullyLostOrStolenModal(!showSuccessfullyLostOrStolenModal)
                        dispatch(resetCardsError())
                        setLostComment('')
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyLostOrStolenModal(
                                    !showSuccessfullyLostOrStolenModal,
                                );
                                dispatch(resetCardsError())
                                setLostComment('')
                            },
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: isMobile ? 0 : 150
                            }
                        },
                    ]}
                />
                {/* update 3ds */ }  
                <Dialog open={update3dsVisible} openSetter={setUpdate3dsVisible}>
                    <DialogWindow caption="Update 3D Secure Number" openSetter={setUpdate3dsVisible}>
                        <form onSubmit={handle3dsNumberSubmit}>
                            <CountryCodeSelect caption="Code" name="countryCode" value={update3dsNumber['countryCode'] || ''} onChange={handleChangeUpdate3dsNumber} />
                            <label className="disputed-charges__control" >
                                Phone number
                                <ReactNumberFormat className="disputed-charges__input" name="phone" value={update3dsNumber['phone'] || ''} onChange={handleChangeUpdate3dsNumber}  />
                            </label>
                            {update3dsError || set3dsError ? <div className="manage-cards__form-error">
                                <div className="manage-cards__form-error-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.99966 0.666016C13.6028 0.666016 17.3343 4.39755 17.3343 9.00064C17.3343 13.6037 13.6028 17.3353 8.99966 17.3353C4.39658 17.3353 0.665039 13.6037 0.665039 9.00064C0.665039 4.39755 4.39658 0.666016 8.99966 0.666016ZM8.99649 7.5415C8.56913 7.54178 8.21711 7.86371 8.16925 8.27819L8.1637 8.37538L8.1667 12.96L8.17237 13.0572C8.22078 13.4716 8.57322 13.7931 9.00058 13.7928C9.42795 13.7926 9.77996 13.4706 9.82783 13.0561L9.83337 12.959L9.83037 8.37429L9.8247 8.27711C9.77629 7.86269 9.42385 7.54122 8.99649 7.5415ZM9.00004 4.41688C8.42404 4.41688 7.9571 4.88381 7.9571 5.45981C7.9571 6.03581 8.42404 6.50274 9.00004 6.50274C9.57603 6.50274 10.043 6.03581 10.043 5.45981C10.043 4.88381 9.57603 4.41688 9.00004 4.41688Z" fill="#E54338"/>
                                </svg>
                                </div>
                                {update3dsError || set3dsError}
                            </div> : null}
                            <div className="manage-cards__form-actions">
                                <button type="button" className="manage-cards__form-action" onClick={() => setUpdate3dsVisible(false)}>Cancel</button>
                                <button type="submit" className="manage-cards__form-action manage-cards__form-action_accent">Update</button>
                            </div>                            
                        </form>
                        {loading3ds || posting3ds ? <Preloader preloaderOpacity={.8} /> : null}
                    </DialogWindow>
                </Dialog>
                <Dialog open={update3dsSuccess} openSetter={setUpdate3dsSuccess}>
                    <DialogWindow caption="Update 3D Secure Number" openSetter={setUpdate3dsSuccess}>
                        <div>
                            <div>Your 3D Secure Number has been successfully updated</div>
                            <div className="manage-cards__form-actions">                                
                                <button className="manage-cards__form-action manage-cards__form-action_accent" onClick={() => setUpdate3dsSuccess(false)}>Close</button>
                            </div>                            
                        </div>
                    </DialogWindow>
                </Dialog>
                {
                    window.location.pathname !== '/cards' &&
                        <MessagePopUp
                            pdfDoc={statementsData || invoicesData}
                            pdfType={statementsData ? 'Statement' : 'Invoice'}
                            onClose={() => dispatch(resetData())}
                        >
                            <div/>
                        </MessagePopUp>
                }
            </div>
        );
    } else {
        return <Preloader preloaderOpacity={1} />
    }
};

const mapStateToProps = state => ({
    manageCardNameStatus: state.cards.manageCardNameStatus,
    manageCardNameError: state.cards.manageCardNameError,
    viewPinStatus: state.cards.viewPinStatus,
    viewPinError: state.cards.viewPinError,
    changePinStatus: state.cards.changePinStatus,
    changePinError: state.cards.changePinError,
    lostCardStatus: state.cards.lostCardStatus,
    lostCardError: state.cards.lostCardError,
    blockCardStatus: state.cards.blockCardStatus,
    blockCardError: state.cards.blockCardError,
    cardsArr: state.cards.cardsAll,
    invoices: state.cards.invoices || [],
    steatments: state.cards.steatments || [],
    statementsData: state.cards.statementsData,
    invoicesData: state.cards.invoicesData,
    productsCards: state.cards.cardsProducts || [],
    set3dsError: state.cards.posting3dsError,
    set3dsSuccess: state.cards.posted3ds,
    posting3ds: state.cards.posting3ds,
    loading3ds: state.cards.loading3ds,
    current3ds: state.cards['3ds'],
})

export default connect(mapStateToProps,
    dispatch => ({
        onGet3dsPhoneNumber: (cardId) => dispatch({type: GET_CARD_3DS + '/get', payload: cardId}),
        onSet3dsPhoneNumber: (value) => dispatch({type: SET_CARD_3DS+'/post', payload: value}),
        onReset3dsPhoneNumber: () => dispatch({type: SET_CARD_3DS+'/reset'}),
    })
)(screenHOC(withRouter(ManageCards)));
