import React, {useCallback} from 'react';
import classNames from 'classnames';

import './Attachments.css';
import AttachIcon from '../AttachIcon';

export default function Attachments({className, files, onDownload}) {
  return (    
    <ul className={classNames('attachments', className)}>
      {files ? files.map((item, i) => (
        <li className="attachments__item" key={i} onClick={() => onDownload(item)}>
          <AttachIcon />
          {item.fileName}          
        </li>
      )) : null}
    </ul>    
  );
};
