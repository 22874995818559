import { put, takeLatest, call } from 'redux-saga/effects';
import {saveAs} from 'file-saver';

import {
  GET_LETTERS,
  POST_LETTERS,
  GET_LATTER_CONTENT,
  GET_LATTER_DOWNLOAD,
  DELETE_LETTER,
  LETTER_TO_ARCHIVE,
  GET_LATTER_PRINT,
  GET_LETTERS_COUNT,
  SET_LETTERS_COUNT,
  DOWNLOAD_ATTACHMENT,
} from "../constants";

import {
  getLetters,
  postLetters,
  getLatterContent,
  getLatterDownload,
  deleteLetter,
  letterToArchive,
  getLatterPrint,
  getLettersCount,
  downloadAttachment,
} from '../../api'

function* getLettersSaga({payload}) {
  try {
    const response = yield call(getLetters, payload);
    yield put({ type: `${GET_LETTERS}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${GET_LETTERS}_FAILED`, payload: error.response });
  }
}
function* postLettersSaga({payload}) {
  try {
    const response = yield call(postLetters, payload);
    yield put({ type: `${POST_LETTERS}_SUCCESS`, payload: response });
  } catch (error) {
    yield put({ type: `${POST_LETTERS}_FAILED`, payload: error.response });
  }
}
function* getLatterContentSaga({payload}) {
  try {
    const response = yield call(getLatterContent, payload);
    yield put({ type: `${GET_LATTER_CONTENT}_SUCCESS`, payload: response });
    yield getLettersCountSaga();
  } catch (error) {
    yield put({ type: `${GET_LATTER_CONTENT}_FAILED`, payload: error.response });
  }
}
function* getLatterDownloadSaga({payload}) {
  try {
    const response = yield call(getLatterDownload, payload);
    yield put({ type: `${GET_LATTER_DOWNLOAD}_SUCCESS`, payload: response });
    yield getLettersCountSaga();
  } catch (error) {
    yield put({ type: `${GET_LATTER_DOWNLOAD}_FAILED`, payload: error.response });
  }
}
function* getLatterPrintSaga({payload}) {
  try {
    const response = yield call(getLatterPrint, payload);
    yield put({ type: `${GET_LATTER_PRINT}_SUCCESS`, payload: response });
    yield getLettersCountSaga();
  } catch (error) {
    yield put({ type: `${GET_LATTER_PRINT}_FAILED`, payload: error.response });
  }
}
function* deleteLetterSaga({payload}) {
  try {
    const response = yield call(deleteLetter, payload);
    yield put({ type: `${DELETE_LETTER}_SUCCESS`, payload: response });
    yield getLettersCountSaga();
  } catch (error) {
    yield put({ type: `${DELETE_LETTER}_FAILED`, payload: error.response });
  }
}
function* letterToArchiveSaga({payload}) {
  try {
    const response = yield call(letterToArchive, payload);
    yield put({ type: `${LETTER_TO_ARCHIVE}_SUCCESS`, payload: response });
    yield getLettersCountSaga();
  } catch (error) {
    yield put({ type: `${LETTER_TO_ARCHIVE}_FAILED`, payload: {e: error.response, ee: error} });
  }
}
function* getLettersCountSaga() {
  try {
    const result = yield call(getLettersCount);
    yield put({ type: `${SET_LETTERS_COUNT}`, payload: result});
  } catch (error) {

  }
}

function* handleDownloadAttachment({payload}) {
  try { 
    const result = yield call(downloadAttachment, payload.url);
    saveAs(result.data, payload.fileName);
  } catch (error) {
    console.log('error', error);
  }
}

export function* lettersActionWatcher() {
  yield takeLatest(`${GET_LETTERS}_REQUEST`, getLettersSaga)
  yield takeLatest(`${POST_LETTERS}_REQUEST`, postLettersSaga)
  yield takeLatest(`${GET_LATTER_CONTENT}_REQUEST`, getLatterContentSaga)
  yield takeLatest(`${GET_LATTER_DOWNLOAD}_REQUEST`, getLatterDownloadSaga)
  yield takeLatest(`${GET_LATTER_PRINT}_REQUEST`, getLatterPrintSaga)
  yield takeLatest(`${DELETE_LETTER}_REQUEST`, deleteLetterSaga)
  yield takeLatest(`${LETTER_TO_ARCHIVE}_REQUEST`, letterToArchiveSaga)
  yield takeLatest(`${GET_LETTERS_COUNT}`, getLettersCountSaga)
  yield takeLatest(`${DOWNLOAD_ATTACHMENT}`, handleDownloadAttachment)
}