import React from 'react';
import './TitlePage.css'

const TitlePage = ({title, subtitle}) => {
  return(
    <div>
      <div className='Title'>
        <div className='Path-2'/>
        <p className='title-Text'>{title}</p>
      </div>
      <p className='Are-you-ready'>{subtitle}</p>
    </div>
  )
}

export default TitlePage;
