import { API, apiRoutes } from "./api";

// AUTH
export const login = (data) => API.plainPost({
  url: apiRoutes.login,
  data: {
    UserName: data.userName,
    password: data.password
  },
})
export const verifyCode = (data) => API.plainPost({
  url: apiRoutes.verifyCode,
  data: {
    code: data.code
  },
})
export const refresh = (data) => API.plainPost({
  url: apiRoutes.refresh,
  headers: {
    Accept: "application/json, text/plain, */*",
    Authorization: data.token
  },
  ...data
})
export const register = (data) => {
  const resp = API.post({
    url: apiRoutes.register,
    data: {
      cardNumber: data.cardNumber,
      phoneCountryCode: data.phoneCountryCode,
      phoneNumber: data.phoneNumber
    },
  })

  return resp
}
export const recoverPassword = (data) => API.post({
  url: apiRoutes.recoverPassword,
  data: {
    userName: data.userName,
    cardNumber: data.cardNumber
  },
})

// CARDS
export const getAllInvoicesCard = (cardId) => API.get({
  url: `${apiRoutes.allInvoices}${cardId}`,
})

export const getAllStatementsCard = (cardId) => API.get({
  url: `${apiRoutes.allStatements}${cardId}`,
})

export const getProducts = () => API.get({
  url: apiRoutes.cardsProducts,
})

export const getCardsAll = () => API.get({
  url: apiRoutes.cardsAll,
})

export const getCardsLatest = () => API.get({
  url: apiRoutes.cardsLatest,
})

export const getInvoicesCard = (productId) => {
  return API.get({
    url: `${apiRoutes.invoices}${productId}`,
  })
}
export const getInvoicesDownload = (id) => API.get({
  url: `${apiRoutes.invoicesDownload}${id}`,
  responseType: 'blob'
})
export const getInvoicesPrint = (id) => API.get({
  url: `${apiRoutes.invoicesPrint}${id}`,
  responseType: 'blob'
})
export const getInvoicesStatemens = (productId) => API.get({
  url: `${apiRoutes.statements}${productId}`,
})
export const getInvoicesStatemensDownload = (id) => API.get({
  url: `${apiRoutes.download}${id}`,
  responseType: 'blob'
})
export const getInvoicesStatemensPrint = (id) => API.get({
  url: `${apiRoutes.print}${id}`,
  responseType: 'blob'
})

export const manageCardName = ({ id, data }) => API.post({
  url: `${apiRoutes.name}${id}`,
  data: {
    currentName: data.currentName,
    newName: data.newName,
    newNameConfirm: data.newNameConfirm
  },
})
export const viewPin = (id) => API.post({
  url: `${apiRoutes.viewPin}${id}`,
})
export const changePin = ({ id, data }) => API.post({
  url: `${apiRoutes.changePin}${id}`,
  data: {
    currentPin: data.currentPin,
    newPin: data.newPin,
    newPinConfirm: data.newPinConfirm,
  },
})
export const lostCard = ({ id, data }) => API.post({
  url: `${apiRoutes.lost}${id}`,
  data: {
    type: data.type,
    comment: data.comment
  },
})
export const blockCard = ({ id, data }) => API.post({
  url: `${apiRoutes.block}${id}`,
  data: {
    type: data.type
  },
})


export const applyCard = (data) => API.post({
  url: apiRoutes.apply,
  data: {
    firstName: data.firstName,
    lastName: data.lastName,
    birthDate: data.birthDate,
    address: data.address,
    nationality: data.nationality,
    passportIssued: data.passportIssued,
    employmentType: data.employmentType,
    companyName: data.companyName,
    annualIncome: data.annualIncome,
    email: data.email,
    mobileNumber: data.mobileNumber,
    desiredProduct: data.desiredProduct,
    jewelleryCardName: data.jewelleryCardName,
  },
})

export const postDisputeTransactions = data => API.post({
  url: apiRoutes.postDisputeTransactions,
  data,
});

export const getCard3ds = (iclCardId) => API.get({
  url: apiRoutes.set3ds + iclCardId,
});

export const setCard3ds = ({iclCardId, ...data}) => API.post({
  url: apiRoutes.set3ds + iclCardId,
  data,
});


// CONTENT
export const getFeed = (top) => API.get({
  url: `${apiRoutes.feed}?top=${top}`,
})
export const getServices = () => API.get({
  url: `${apiRoutes.services}`,
})
export const getContact = (data) => API.get({
  url: apiRoutes.contact,
})
export const getTariff = (data) => API.get({
  url: apiRoutes.tariff,
})
export const getSiteTC = (data) => API.get({
  url: apiRoutes.siteTC,
})
export const getCardTC = (data) => API.get({
  url: apiRoutes.cardsTC,
})
export const getCookies = (data) => API.get({
  url: apiRoutes.cookies,
})
export const getPrivacy = (data) => API.get({
  url: apiRoutes.privacy,
})

// LETTERS
export const getLetters = (data) => API.get({
  url: apiRoutes.letters,
  data,
})
export const postLetters = (data) => {
  if (data.files && data.files.length) {
    const form = new FormData();
    form.append('subject', data.subject);
    form.append('body', data.body);
    if (data.groupId) form.append('groupId', data.groupId);
    data.files.forEach(file => {
      form.append('сы', file);
    });
    console.log('posting files', form);
    return API.post({
      url: apiRoutes.lettersWithAttachments,
      data: form,      
      headers: { "Content-Type": "multipart/form-data" },
    });
 } else return API.post({
    url: apiRoutes.letters,
    data:
      data.groupId
        ? {
          groupId: data.groupId,
          subject: data.subject,
          body: data.body
        }
        : {
          subject: data.subject,
          body: data.body
        }
  });
}
export const getLatterContent = (id) => API.get({
  url: `${apiRoutes.lettersContent}${id}`,
})
export const getLatterDownload = (id) => API.get({
  url: `${apiRoutes.lettersDownload}/${id}`,
  responseType: 'blob',
})

export const getLatterPrint = (id) => API.get({
  url: `${apiRoutes.lettersPrint}/${id}`,
  responseType: 'blob',
})

export const deleteLetter = (id) => API.delete({
  url: `${apiRoutes.letters}${id}`,
  params: {
    id
  },
})
export const letterToArchive = (id) => API.post({
  url: `${apiRoutes.lettersArchive}${id}`,
})

export const getLettersCount = async () => {
  const res = await API.get({
    url: `${apiRoutes.lettersCount}`
  })
  return res.data;
}

function normUrl(url) {
  if (!url) return;
  return url.slice(0, 9) + url.slice(9).replace('//', '/');
}

export const downloadAttachment = (url) => API.get({
  url: normUrl(url),
  responseType: 'blob'
});

// PROFILE
export const getProfile = () => API.get({
  url: apiRoutes.profile,
})

export const postProfile = (data) => API.post({
  url: apiRoutes.profile,
  data: {
    userName: data.userName,
    name: data.name,
    surname: data.surname,
    email: data.email,
    phoneNumber: data.phoneNumber,
  }
})

export const changeProfilePassword = (data) => API.post({
  url: apiRoutes.changePassword,
  data: {
    currentPassword: data.currentPassword,
    newPassword: data.newPassword,
    newPassswordConfirm: data.newPassswordConfirm,
  },
})

// VIEW_DOC
export const getCardInvoicesView = (id) => API.get({
  url: `${apiRoutes.cardStatementView}${id}`,
  // url: `${apiRoutes.invoicesPrint}${id}`,
  // responseType: 'blob',
})

export const getCardSteatmentsView = (id) => API.get({
  url: `${apiRoutes.cardDocView}${id}`,
  // url: `${apiRoutes.print}${id}` ,
  // responseType: 'blob',
})
